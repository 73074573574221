// Component Dependencies
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent implements OnInit {

  // Data Inputs
  @Input() contentTemp: any;
  @Input() contentFooter: any;
  @Input() title: string;
  @Input() optClose: boolean;
  @Input() scrollable: boolean;
  @Input() align: string;

  // Events Outputs
  @Output() close: EventEmitter<any> = new EventEmitter();

  // Constructor
  constructor(
    private translate: TranslateService
  ) {}

  // OnInit
  ngOnInit() {
  }

  // CLOSE MODAL FUNCTION
  closeModal() {
    this.close.emit();
  }

}
