<div class="modal_overlay animated fadeIn fast">
  <div class="modal_wrap" (click)="$event.stopPropagation();" [ngStyle]="{'overflow-y': scrollable ? 'auto' : 'inherit'}">
    <div class="hidden-modal"></div>
    <div class="modal_header" *ngIf="title">
      <div class="row">
        <div class="col-12">
          <div class="title-container d-flex align-items-start justify-content-between">
            <h2>{{ title }}</h2>
            <img *ngIf="!optClose" class="icon close-icon pointer" style="width: 24px;" [src]="'./assets/icons/remove_icon.svg'" [alt]="'Close icon'" (click)="closeModal()"/>
          </div>
        </div>
      </div>
    </div>
    <div class="modal_content {{ align }}" >
      <ng-container
         *ngTemplateOutlet="contentTemp">
      </ng-container>
    </div>
    <div class="modal_footer" *ngIf="contentFooter">
      <ng-container
         *ngTemplateOutlet="contentFooter">
      </ng-container>
    </div>
  </div>
</div>
