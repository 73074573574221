<div class="nav_bar" [ngClass]="{'responsive': !showNavbar}" (clickOutside)="openSubjectMetadataSubmenu = false;">
  <!-- RESPONSIVE BUTTON -->
  <button id="showNavbar" [hidden]="showNavbar" (click)="openSubjectMetadataSubmenu = !openSubjectMetadataSubmenu;">
    <i class="fas fa-school"></i>
  </button>

  <!-- LOGO -->
  <div class="logo d-flex" [hidden]="!showNavbar">
    <img class="pointer logo_md d-inline-block" [routerLink]="['/home']" src="./assets/icons/navbar/logo-blanco.png" alt="GES LOGO" />
  </div>

  <!-- NAVBAR BUTTONS -->
  <div class="navbar_block" [ngClass]="{'close': !showNavbar && !openSubjectMetadataSubmenu}">
    <!-- TEACHER LOGGED -->
    <div *ngIf="windowSize >= 1200" class="dropdown teacherDrop">
      <button class="dropdown_btn navbar_btn">
        <img class="icon" [src]="'./assets/icons/navbar/user.png'" [alt]="'Teacher selector icon'" />
        <label *ngIf="config.user">
          {{ config.user.name }} {{ config.user.lastName }}
        </label>
      </button>
    </div>
    <!-- SUBJECT SELECTOR -->
    <div class="dropdown subjectDrop" [ngClass]="{'disabled': !checkUrl(router.url),'resize': !sizeNavbar}">
      <div (clickOutside)="subjectDropdown = false;">
        <button class="dropdown_btn navbar_btn" (click)="subjectDropdown = config.checkClickWizard(subjectDropdown)">
          <img class="icon" [src]="'./assets/icons/navbar/book.png'" [alt]="'Subject selector icon'" />
          <label *ngIf="config.selectedSubject && config.selectedSubject.length <= 0 && sizeNavbar"><span>{{ 'OPTIONS.SELECT' | translate }} {{ 'OPTIONS.SUBJECT' | translate }}</span></label>
          <label *ngIf="config.selectedSubject && config.selectedSubject.length <= 0 && !sizeNavbar">{{ 'OPTIONS.SUBJECT' | translate }}</label>
          <label *ngIf="config.selectedSubject && config.selectedSubject.id">{{ config.selectedSubject.title }}</label>
        </button>
        <div *ngIf="checkUrl(router.url)" class="btn_icon" (click)="subjectDropdown = !subjectDropdown; $event.stopPropagation();">
          <img class="icon" *ngIf="!subjectDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
          <img class="icon" *ngIf="subjectDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
        </div>
        <div class="dropdown_menu" *ngIf="subjectDropdown" [ngStyle]="subjects.length <=0 ? 'padding: 20px' : ''">
          <ul class="step-subjects" *ngIf="!loading && subjects.length > 0">
            <li class="filter-item pointer" *ngFor="let subject of subjects; let i = index;" (click)="config.selectedSubject = subject; selectSubject('subject'); subjectDropdown = false;" [ngClass]="{'active': config.selectedSubject && config.selectedSubject.id === subject.id }">
              <span class="icon_list">
                <img class="icon" style="width: 24px;" [src]="'./assets/icons/navbar/group_icon.svg'" [alt]="'Subject selector icon'" />
              </span>
              <label>{{ subject.title }}</label>
            </li>
          </ul>
          <strong *ngIf="subjects.length <= 0" class="text-center">{{ 'OPTIONS.NON_RESULTS' | translate }}</strong>
        </div>
      </div>
    </div>
    <!-- GROUP SELECTOR -->
    <div class="dropdown groupDrop" [ngClass]="{'disabled': !(config.selectedSubject && checkUrl(router.url)), 'resize': !sizeNavbar}">
      <div (clickOutside)="groupDropdown = false;">
        <button class="dropdown_btn navbar_btn" (click)="groupDropdown = config.checkClickWizard(groupDropdown)">
          <img class="icon" [src]="'./assets/icons/navbar/birrete.png'" [alt]="'Group selector icon '" />
          <label *ngIf="(!config.selectedGroup || config.selectedGroup && !config.selectedGroup.id) && sizeNavbar">{{ 'OPTIONS.SELECT' | translate }} {{ 'OPTIONS.GROUP' | translate
            }}</label>
          <label *ngIf="(!config.selectedGroup || config.selectedGroup && !config.selectedGroup.id) && !sizeNavbar">{{ 'OPTIONS.GROUP' | translate }}</label>
          <label *ngIf="config.selectedGroup" tooltip="{{ config.selectedGroup.title }}" [placement]="'bottom'" [delay]="2000">{{ config.selectedGroup.title }}</label>
        </button>
        <div *ngIf="checkUrl(router.url)" class="btn_icon" (click)="groupDropdown = !groupDropdown; $event.stopPropagation();">
            <img class="icon" *ngIf="!groupDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
            <img class="icon" *ngIf="groupDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
        </div>
        <div class="dropdown_menu" *ngIf="groupDropdown" [ngStyle]="subjects.length <=0 ? 'padding: 20px' : ''">
          <ul class="step-groups" *ngIf="!loading && groupsSubject.length > 0">
            <li class="filter-item pointer" *ngFor="let group of groupsSubject; let i = index;" (click)="config.selectedGroup = group;groupDropdown = false; selectSubject('group');" [ngClass]="{'active': config.selectedGroup && config.selectedGroup.id === group.id }">
              <span class="icon_list">
                <img class="icon" style="width: 24px;" [src]="'./assets/icons/navbar/subject_icon.svg'" [alt]="'Group selector icon'" />
              </span>
              <label>{{ group.title }}</label>
            </li>
          </ul>
          <strong *ngIf="groupsSubject.length <= 0" class="text-center">{{ 'OPTIONS.NON_RESULTS' | translate }}</strong>
        </div>
      </div>
    </div>
  </div>

  <!-- MENU BUTTON -->
  <!-- <button id="menu" [routerLink]="['/home']">
    <img class="icon" [src]="'./assets/icons/navbar/menu.png'" [alt]="'Turn off icon'" />
  </button> -->

  <!-- LOGOUT BUTTON -->
  <button id="logout" (click)="logout()">
    <img class="icon" [src]="'./assets/icons/navbar/salir.png'" [alt]="'Turn off icon'" />
  </button>
</div>