// Directive Dependencies
import { Directive, ElementRef, Input, Renderer2, OnChanges, HostBinding, OnInit } from '@angular/core';

@Directive({
    selector: '[addClassHeight]'
})
export class AddClassHeightDirective {


  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
      if(this.elRef.nativeElement.offsetHeight < 52) {
        this.renderer.addClass(this.elRef.nativeElement, 'minified');
      } else {
        this.renderer.addClass(this.elRef.nativeElement, 'large');
      }
  }

}