import { ExamInterface } from '../interfaces/exam.interface';
import { Period } from './period.model';
import { Question } from './question.model';

export class Exam implements ExamInterface {
  public id: string;
  public title: string;
  public units: any[];
  public examQuestions: Question[];
  public logo: any;
  public classGroup: string;
  constructor() {}
}
