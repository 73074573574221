// Service Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


// Services
import { CommonsService } from './commons.service';

// App Config data
import { AppSettings } from '../../app.config';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ResourceService {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) {this.apiUrl = AppSettings.API_PROD_ENDPOINT; }


  getBooks(
    filter: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/book` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getBooksByGedi(query: string = '') {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/books?query={"gedi":"${ query }"}&filters=&order=&limit=99&offset=0`;
    return this.http.get(requesttUrl, { headers: headers }).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getBooksBySubjectmetadata(subjectmetadata: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/book?subjectMetadata=` + subjectmetadata;

    return this.http.get(requesttUrl, { headers: headers }).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getBooksByGradeSubject(grade, subject): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `?grade=${grade}&subject=${subject}`;
    const requesttUrl = this.apiUrl + `mcs/book/gradeSubject` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers }).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getRegionsByBook(book): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `?book=${book}`;
    const requesttUrl = this.apiUrl + `mcs/region/book` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers })
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getLanguages(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/language` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getTeacherLanguages(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/language/teacher` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getRegions(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/region` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getGrades(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/grade` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getPeriodTypes(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/periodType` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getPeriodClass(idClass) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `?classGroup=${idClass}`;
    const requesttUrl = this.apiUrl + `mcs/period` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers }).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

}
