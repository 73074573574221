// Component Dependencies
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

// Services
import { ExamService } from '../../data/services/exam.service';
import { QuestionService } from '../../data/services/question.service';
import { CurriculumService } from '../../data/services/curriculum.service';
import { ConfigService } from '../../data/services/config.service';
import { CommonsService } from '../../data/services/commons.service';
import { UnitService } from '../../data/services/unit.service';
import { TranslateService } from '@ngx-translate/core';

// App Config data
import { AppSettings } from '../../app.config';

// Models
import { Exam } from '../../data/models/exam.model';
import { Question } from '../../data/models/question.model';
import { Unit } from '../../data/models/unit.model';
import { NotificationService } from '../../data/services/notification.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'exam-item',
  templateUrl: './exam-item.component.html',
  styleUrls: ['./exam-item.component.css']
})
export class ExamItemComponent implements OnInit, OnDestroy {
  // Routes
  routes = [
    { label: "Inicio", url: "/home"},
    { label: "Listado de pruebas de evaluación", url: "/exam"}
  ];

  // Main Data
  userRegion: any;
  exam: any;
  examDate: any = null;
  questions: any[] = [];
  total = 0;
  subjectMetadata;
  isNewExam: boolean = true;

  // Selected Question
  questionsAdded: any[] = [];

  // Metadata Exam
  examType: number = 1;

  // Question params
  questionType = 'auto';
  searchQuestion = '';

  units: Unit[] = [];
  filterUnits: Unit[] = [];
  selectedUnit: Unit = null;
  deleteUnit: Unit = null;
  adviceUnit: boolean = false;
  adviceCantDelete: boolean = false;
  unitDropdown: boolean;
  unitConfig: any = {
    param: 'unit',
    search: true,
    prelabel: 'Unidad ',
    field: 'number'
  };

  // Curriculum
  curriculumModal = false;
  selectedCurriculums: any = [];
  uncoveredCurriculums: any = [];
  competences: any = [];
  selectedCompetence: any = null;
  competenceDropdown: boolean;
  competenceConfig: any = {
    param: 'competence',
    search: true,
    pagination: false,
    total: 0
  };
  competenceOffset = 0;

  criterias: any = [];
  selectedCriteria: any = null;
  criteriaDropdown: boolean;
  criteriaConfig: any = {
    param: 'criteria',
    search: true,
    pagination: false,
    total: 0
  };
  criteriaOffset = 0;

  knowledges: any = [];
  selectedKnowledge: any = null;
  knowledgeDropdown: boolean;
  knowledgeConfig: any = {    
    param: 'knowledge',
    field: 'description',
    search: true,
    pagination: false,
    total: 0
  };
  knowledgeOffset = 0;

  isInParam: string = '';

  criteriaAddShown: any;

  // Modal Preview
  questionPreview: any = null;
  previewShown = false;
  criteriaPreview: any = null;

  // Modal Add Question
  questionAddModal: any = null;
  questionAddShown: boolean = false;

  // QUERY Question Params
  limit = 10;
  offset = 0;
  sort = 'id';
  dirSort = 'asc';
  page = 1;

  selectedGroup: any;

  // View Variables
  loading = false;
  loadingAll = false;
  isPrimary: boolean = false;

  imgUrl: string;
  imgPerfil: any = null;

  configSubscribe;
  unitsSubscribe;

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private examService: ExamService,
    private questionService: QuestionService,
    private curriculumService: CurriculumService,
    private unitService: UnitService,
    private notification: NotificationService,
    public commons: CommonsService,
    public translate: TranslateService,
    private config: ConfigService,
    private sanitizer: DomSanitizer
  ) {
    this.imgUrl = AppSettings.API_PROD_IMG;
    this.userRegion = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).region : null;
  }

  ngOnInit () {
    this.selectedGroup = localStorage.getItem('groupSelected') ?
      JSON.parse(localStorage.getItem('groupSelected')) : this.config.selectedGroup;
    this.subjectMetadata = localStorage.getItem('subjectMetadata') ?
      JSON.parse(localStorage.getItem('subjectMetadata')) : this.config.subjectMetadata;
    this.detectTypeExam();
    this.checkIfIsPrimary();
  }
  
  ngAfterViewChecked() {
    if ((localStorage.getItem('previusPage').includes('generator')) && (localStorage.getItem('nextStep') === 'true')) {
      this.newEnjoy();
    }
  }

  newEnjoy() {
    this.config.enjoyhintSteps = [
      this.config.completeStep('next .hidden-wizard', 'Aquí podrás completar la prueba de evaluación.', true, true, '', 1000),
      this.config.completeStep('next .step-exam7', 'La cabecera te permite personalizar la parte superior de la prueba de evaluación; de esta manera, cuando las imprimas para tu alumnado, aparecerán con el nombre y el logotipo del centro, así como los datos de la materia y el curso.', true, false, '', 0),
      this.config.completeStep('next .step-exam8', 'Asimismo, puedes seleccionar de tu ordenador, si lo deseas, el logotipo de tu centro educativo en formato de imagen.', true, false, '', 0),
      this.config.completeStep('next .step-exam9', 'Inserta el nombre del centro educativo.', true, false, '', 0),
      this.config.completeStep('next .step-exam10', 'Comprobarás que la herramienta ha rellenado automáticamente el nombre de la materia.', true, false, '', 0),
      this.config.completeStep('next .datepicker', 'También podrás rellenar la fecha de la prueba, bien escribiendo directamente la fecha o eligiendo en el calendario que se despliega en la aplicación en este campo.', true, false, '', 0),      
      this.config.completeStep('next .step-exam11', 'Si deseas que la impresión de la prueba adjunte la información de los elementos curriculares que has cubierto al generarla, deberás pulsar «Sí» sobre la pregunta.', true, false, '', 0),
      this.config.completeStep('next .step-exam12', 'En «Prueba de evaluación» se añaden automáticamente las actividades en función de los parámetros que seleccionaste previamente. Esta es la propuesta que te genera la plataforma.', true, false, '', 0, {class: '.exam_content li'}),
      this.config.completeStep('next .hidden-wizard', 'Si hubieras elegido la opción de «Crear actividades de forma manual», este combo estaría vacío. Y tendrías que ir completando tú la selección añadiendo actividades que puedes ir acotando también con los filtros de unidad o con cada uno de los elementos curriculares que quieras cubrir. <br/> Recuerda que en esta opción de creación manual, en el paso anterior, también podrás seleccionar de qué unidades quieres que te traiga las actividades para generar la prueba.', true, true, '', 0, {class: '.hidden-wizard'}),
      this.config.completeStep('next .step-exam14', 'Esta funcionalidad de completar la selección de actividades mediante filtrados de información aplica a las dos opciones automática y manual, y se puede nutrir de Actividades Santillana o Mis actividades según tu elección:', true, false, '', 0, {class: '.step-exam14'}),


      this.config.completeStep('next .step-exam14-b', 'Ten en cuenta que esas unidades quedarán seleccionadas, según tu elección, y si quisieras ampliar o modificar la selección deberías volver al paso anterior para modificarlo', true, false, '', 0),



      this.config.completeStep('click .btn_view', 'Puedes visualizar el contenido de cualquier actividad pulsando en «ver».', false, false, '', 0, {class: '.step-exam12', type: true}),
      this.config.completeStep('next .hidden-modal', 'La plataforma te ofrecerá una previsualización de la actividad que luego va a mostrar la prueba. En ella se visualizan todos los elementos curriculares asociados a la actividad.', true, true, '', 500, {}),
      this.config.completeStep('next .step-exam12 .btn_purple', 'Si consideras que la actividad cumple los requisitos que necesitas, la dejarás en el combo de la prueba propuesta; y, si no fuese así, puedes descartar una actividad de la prueba pulsando en «borrar». ', true, false, '', 0, {class: '.step-exam12', type: true}, '', true),
      this.config.completeStep('next .step-exam12 .move-icon', 'También puedes ordenar las actividades según tus criterios, arrastrando a la posición deseada.', true, false, '', 0),
      this.config.completeStep('next .step-exam13 .questions', 'En el caso de que quieras añadir más actividades utiliza el repositorio de Actividades Santillana o las tuyas propias en «Mis actividades». <br/> Para añadir actividades utilizaremos la información que aparece en este bloque.', true, false, '', 0, {class: '.filters-container', type: true}),
      this.config.completeStep('next .step-exam13 .btn_pink', 'Antes de añadir la actividad puedes revisarla pulsando en «ver».', true, false, '', 0),
      this.config.completeStep('next .step-exam13 .btn_orange', 'Después pulsa en «añadir».', true, false, '', 0),
      this.config.completeStep('next .exam_content li', 'Podrás ver cómo la actividad añadida aparece ya situada en el bloque de propuestas de la «Prueba de evaluación».', true, false, '', 0, {class: '.step-exam12', type: true}),
      this.config.completeStep('click .color-white', 'Para consultar los saberes básicos que estás cubriendo con la selección de actividades, establecida, debes pulsar en «Saberes cubiertos».', false, false, '', 0, {class: '.footer-container'}),
      this.config.completeStep('next .hidden-modal', 'Se muestran los elementos curriculares que se cubren con dichas actividades. Estos últimos no estarán incluidos en esta prueba de evaluación a no ser que añadas alguna actividad que los cubra. <br/> <br/> Si consideras que hay algún otro criterio o saber básico que es necesario cubrir deberás crear tú mismo una actividad a la que asociarlo.', true, true, '', 500, {}),


      this.config.completeStep('click .step-exam12 .btn-create', 'Si has añadido las actividades deseadas, las has ordenado y consideras que la prueba de evaluación está preparada, pulsa en «Guardar».', false, false, '', 0, {class: '.footer-container'}, '', true)
    ];
    this.config.newEnjoy();
  }

  getSubjectMetadata(): Promise<any> {
    const promises_array: Array<any> = [];
    const self = this;

    try {
      promises_array.push(new Promise(function(resolve) {
        self.loadData();
        resolve('done');
        if (self.exam && !self.exam.title) {
          self.exam.title = this.translate.instant('EXAMS.WITHOUT_TITLE');
          resolve('done');
        }
        if (self.subjectMetadata) {
          resolve('done');
        }
      }));
    } catch (error) {
      console.log('error');
    }

    return Promise.all(promises_array);
  }

  detectTypeExam () {
    this.activatedRoute.params.subscribe( params => {
      if (params.id) {
        if (params.id === 'create') {
          this.routes.push({label: "Creación de prueba de evaluación", url: "/exam/create"});
          this.exam = this.examService.examGenerated && this.examService.examGenerated.title ?
          this.examService.examGenerated :
          localStorage.getItem('examGenerated') ?
          JSON.parse(localStorage.getItem('examGenerated')) :
          new Exam();
          this.exam.logo = null;
          this.exam.schoolName = '';
          this.exam.isEvaluable = false;
          this.loadChargedQuestions();
          this.loadData();
          this.isNewExam = true;
        } else {
          this.routes.push({label: "Edición de prueba de evaluación", url: "/exam/" + params.id});
          this.exam = this.examService.examGenerated && this.examService.examGenerated.title ?
          this.examService.examGenerated : localStorage.getItem('examGenerated');
          this.loadData();
          this.getSubjectMetadata().then(() => { this.getExamById(params.id); });
          this.isNewExam = false;
        }
      }
    });
    this.checkIfSessionCache();
  }

  checkIfIsPrimary() {
    const group = localStorage.getItem('groupSelected') ? JSON.parse(localStorage.getItem('groupSelected')) : this.config.selectedGroup;
    if (group && group.grade === 1) {
      this.isPrimary = true;
      this.examType = 2;
    } else {
      this.isPrimary = false;
      this.examType = 1;
    }
  }

  loadData() {
    this.getQuestions();
    if ( this.selectedGroup ) {
      this.getUnits();
    }
  }

  getExamById(id) {
    this.loadingAll = true;
    this.examService.getExam(id)
      .subscribe(data => {
        this.exam = data;
        if(data.date) {
          const aux = data.date.split("/")[1] + "-" + data.date.split("/")[0] + "-" + data.date.split("/")[2];
          this.examDate = new Date(aux);
        }
        this.imgPerfil = this.exam.logo ? this.exam.logo : null;
        const examGenerated = {
          title: this.exam.title,
          units: this.exam.units
        };
        localStorage.setItem('examGenerated', JSON.stringify(examGenerated));
        this.loadChargedQuestions();
        this.checkIfIsPrimary();
        this.filterArrayUnits();
        this.loadingAll = false;
      },
      ()=> {this.loadingAll = false;});
  }

  // tslint:disable-next-line:cyclomatic-complexity
  getQuestions() {
    if (document.querySelector("body .enjoyhint")) {
      this.questions = [
        {
            "id": 0,
            "title": "Antiguamente los astrónomos observaban el cielo a simple vista. ¿Qué aparatos se utilizan en la actualidad para observar las estrellas y otros astros?",
            "content": "<p>Contenido</p>",
            "region": "Andalucía",
            "subject": "Biología y Geología",
            "grade": "1.º ESO",
            "userId": 204,
            "unitNumber": "1",
            "numActivity": 1,
            "curriculums": [ { "id": 3 }, { "id": 5 } ]
        },
        {
            "id": 1,
            "title": "Relaciona los elementos de las dos columnas.",
            "content": "<p>Contenido</p>",
            "region": "Andalucía",
            "subject": "Biología y Geología",
            "grade": "1.º ESO",
            "userId": 204,
            "unitNumber": "1",
            "numActivity": 1,
            "curriculums": [ { "id": 3 }, { "id": 5 } ]
        },
        {
            "id": 2,
            "title": "Completa el siguiente texto:",
            "content": "<p>Contenido</p>",
            "region": "Andalucía",
            "subject": "Biología y Geología",
            "grade": "1.º ESO",
            "userId": 204,
            "unitNumber": "1",
            "numActivity": 1,
            "curriculums": [ { "id": 3 }, { "id": 5 } ]
        },
        {
            "id": 3,
            "title": "En los ecosistemas de agua dulce, ¿de qué depende la cantidad de oxígeno disuelto en el agua?",
            "content": "<p>Contenido</p>",
            "region": "Andalucía",
            "subject": "Biología y Geología",
            "grade": "1.º ESO",
            "userId": 204,
            "unitNumber": "1",
            "numActivity": 1,
            "curriculums": [ { "id": 3 }, { "id": 5 } ]
        },
        {
            "id": 4,
            "title": "Prueba actividad",
            "content": "<p>Contenido</p>",
            "region": "Andalucía",
            "subject": "Biología y Geología",
            "grade": "1.º ESO",
            "userId": 204,
            "unitNumber": "1",
            "numActivity": 1,
            "curriculums": [ { "id": 3 }, { "id": 5 } ]
        },
      ];
      this.total = 5;
      return;
    }
    this.loading = true;
    const filters = [];
    const queryArray = [];
    if (!(this.config.selectedSubject &&
      this.config.selectedGroup && 
      this.config.selectedGroup.book &&
      this.config.selectedGroup.grade &&
      this.config.selectedRegion
    )) {
      this.loading = false;
      return;
    }
    filters.push({ param: 'own', value: this.questionType === 'self' ? 1 : 0 });
    filters.push({ param: 'book', value: this.config.selectedGroup.book });
    filters.push({ param: 'subject', value: this.config.selectedSubject.id });
    filters.push({ param: 'grade', value: this.config.selectedGroup.grade });
    if (this.selectedUnit && this.selectedUnit.id) {
      filters.push({param: "unit", value: [this.selectedUnit.id]});
    } else if (this.filterUnits.length > 0) {
      const filter = this.filterUnits.map(unit => {
        return unit.id;
      });
      filters.push({param: "unit", value: filter});
    } else {
      return;
    }
    if(this.searchQuestion && this.searchQuestion != '') {
      queryArray.push({param: 'title', value: this.searchQuestion});
    }
    if (this.selectedKnowledge !== null) {
      filters.push({ param: 'basicKnowledge', value: this.selectedKnowledge.id})
    } else if (this.selectedCriteria !== null) {
      filters.push({ param: 'evaluationCriteria', value: this.selectedCriteria.id})
    } else if (this.selectedCompetence !== null) {
      filters.push({ param: 'specificCompetence', value: this.selectedCompetence.id})
    }
    this.questionService.getGesQuestions(queryArray, filters, this.sort, this.dirSort, this.limit, this.offset)
    .subscribe(data => {
      this.loading = false;
      this.questions = data.results;
      this.total = data.metadata.totalResults;
    },
    ()=> {this.loading = false;});
  }

  getQuestionById(id) {
    this.loadingAll = true;
    this.questionService.getQuestion(id)
    .subscribe(data => {
      this.questionPreview = data;
      this.loadingAll = false;
    },
    ()=> {this.loadingAll = false;});
  }

  getUnits(queryArray = [], filters = [], limit = 99, offset = 0 ) {
    this.units = [];
    filters = [
      { param: 'subjectMetadata', value: this.selectedGroup.subjectMetadata },
      { param: 'book', value: this.selectedGroup.book }
    ];
    this.unitService.getUnits(queryArray, filters, this.sort, this.dirSort, limit, offset)
    .subscribe(data => {
      this.units = data.results;
      this.filterArrayUnits();
    },
    ()=> {this.unitConfig.loading = false;});
  }

  changeUnit(unit) {
    if (!this.checkIfIsInArray(this.exam.units, unit, 'id')) {
      // se ha seleccionado una unidad
      const newUnit = Object.assign({}, unit);
      this.exam.units.push(newUnit);
      this.filterArrayUnits();
    } else if (this.exam.units.length <= 1) {
      // si solo queda una unidad seleccionada muestro un aviso que indica que no se puede deseleccionar
      this.adviceCantDelete = true;
    } else if (this.exam.units.length > 1) {
      // se ha deseleccionado una unidad (muestro el aviso)
      this.deleteUnit = unit;
      this.adviceUnit = true;
    }
  }

  removeUnit() {
    this.exam.units.splice(this.getIndexOf(this.exam.units, this.deleteUnit, 'id'), 1);
    this.adviceUnit = false;
    this.filterArrayUnits();
    this.filterActivitiesExam();
  }

  filterArrayUnits() {
    // filtro el desplegable de unidades respecto a las unidades seleccionadas para el examen
    this.unitConfig.loading = true;
    this.selectedUnit = null;
    this.filterUnits = this.exam && this.exam.units ? this.exam.units : [];
    this.unitConfig.loading = false;
    this.getQuestions();
  }

  filterActivitiesExam() {
    // busco las actividades seleccionadas que pertenezcan a la unidad seleccionada y las extraigo del array
    const aux = this.questionsAdded.filter(item => {
      return item.unitNumber == this.deleteUnit.number;
    });
    aux.forEach(question => {
      this.questionsAdded.splice(this.getIndexOf(this.questionsAdded, question, 'id'), 1)
    });
    this.deleteUnit = null;
  }
  
  getCompetences(text = null) {
    let filters = [];
    if (this.subjectMetadata) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.competenceConfig.loading = true;
    this.curriculumService.getCompetences(filters)
    .subscribe(data => {
      this.competences = data.results;
      this.competenceConfig.total = data.metadata.totalResults;
      this.competenceConfig.loading = false;
    },
    ()=> {this.competenceConfig.loading = false;});
  }

  getCriterias(text = null) {
    let filters = [];
    if (this.subjectMetadata) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedCompetence != null) {filters.push({param: 'specificCompetence', value: this.selectedCompetence.id});} 
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.criteriaConfig.loading = true;
    this.curriculumService.getCriterias(filters)
    .subscribe(data => {
      this.criterias = data.results;
      this.criteriaConfig.total = data.metadata.totalResults;
      this.criteriaConfig.loading = false;
    },
    ()=> {this.criteriaConfig.loading = false;});
  }

  getKnowledges(text = null) {
    let filters = [];
    if (this.subjectMetadata) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedCriteria != null) {filters.push({param: 'evaluationCriteria', value: this.selectedCriteria.id});}
    else if (this.selectedCompetence != null) {filters.push({param: 'specificCompetence', value: this.selectedCompetence.id});}
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.knowledgeConfig.loading = true;
    this.curriculumService.getKnowledges(filters)
    .subscribe(data => {
      this.knowledges = data.results;
      this.knowledgeConfig.total = data.metadata.totalResults;
      this.knowledgeConfig.loading = false;
    }),
    ()=> {this.knowledgeConfig.loading = false;};
  }

  searchUnits(event) {
    const offset = event.page && event.limit ? (event.page * event.limit) - event.limit : 0;
    this.getUnits(event.query, event.filter, event.limit, offset);
  }

  searchCriterias(event) {
    this.getCriterias(event.text);
  }

  searchCompetences(event) {
    this.getCompetences(event.text);
  }

  searchKnowledges(event) {
    this.getKnowledges(event.text);
  }

  competenceSelect(competence) {
    this.selectedCompetence = competence;
    this.selectedCriteria = null;
    this.selectedKnowledge = null;
  }

  criteriaSelect(criteria) {
    if (this.selectedCompetence == null) {
      // select competence by criteria
      const competence = this.competences.find(item => {
        return item.id === criteria.specificCompetence.id;
      });
      this.selectedCompetence = competence;
    }
    this.selectedCriteria = criteria;
    this.selectedKnowledge = null;
  }

  knowledgeSelect(knowledge) {
    this.selectedKnowledge = knowledge;
  }

  pageChanged(event: any): void {
    this.offset = (event.page * this.limit) - this.limit;
    this.getQuestions();
  }

  previewQuestion(question: Question) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    } else if (document.querySelector("body.on-enjoy") && question) {
      this.questionPreview = question;
      this.previewShown = true;
      return;
    }
    this.getQuestionById(question.id);
    this.previewShown = true;
  }

  loadChargedQuestions() {
    const wizard = JSON.parse(localStorage.getItem("wizardQuestions"));
    if (document.querySelector("body.on-enjoy") && wizard && wizard.length > 0) {
        for (const question of wizard) {
          this.addQuestion(question);
        }
        localStorage.removeItem('wizardQuestions');
    } else if ( this.isNewExam ) {
      const automatic = JSON.parse(localStorage.getItem("automaticQuestions"));
      if (this.examService.questionsPrecharged && this.examService.questionsPrecharged.length > 0) {
        for (const q of this.examService.questionsPrecharged) {
          this.addQuestion(q);
        }
      } else if (automatic != null && automatic != undefined) {
        this.questionService.getGesRandomQuestions(automatic.group, automatic.units, automatic.limit)
        .subscribe((data: any) => {
          for (const question of data) {
            this.addQuestion(question);
          }
          if (data.length >= automatic.quantity) {
            this.notification.setNotification(
              `${data.length} ${this.translate.instant('EXAMS.GENERATOR.ACTIVITIES_ADDED_CONFIRM')}`,
              'success', 8000);
          } else if (data.length < automatic.quantity && data && data.length > 0) {
            this.notification.setNotification(
              `${this.translate.instant('EXAMS.GENERATOR.ACTIVITIES_ADDED_LESS_1')}.
              ${data.length} ${this.translate.instant('EXAMS.GENERATOR.ACTIVITIES_ADDED_LESS_2')} ${automatic.quantity}
              ${this.translate.instant('EXAMS.GENERATOR.ACTIVITIES_ADDED_LESS_3')}`,
              'warning', 8000);
          } else if (!data || data.length === 0) {
            this.notification.setNotification(
              this.translate.instant('EXAMS.GENERATOR.ACTIVITIES_ADDED_ERROR'),
              'warning', 8000);
          }
        });
      }
      this.examService.questionsPrecharged = [];
      localStorage.removeItem("automaticQuestions");        
    } else {
      const questions = this.commons.sortArray(this.exam.examQuestions, 'questionOrder');
      for (const q of questions) {
        const question: any = {
          idExamQuestion: q.id,
          title: q.question.title,
          id: q.question.id,
          order: q.questionOrder,
          times: q.responseSize,
          unitNumber: q.question.unit.number,
          curriculums: q.question.curriculums
        };
        this.addQuestion(question);
      }
    }
  }

  addQuestionRandom(questions) {
    if (questions) {
      for (const question of questions) {
        if (this.commons.isInArray(this.questionsAdded, question, 'id')) {
          return;
        }
        this.addQuestion(question);
      }
      this.reorderQuestions();
    } else {
      this.notification.setNotification(
        this.translate.instant('EXAMS.ITEM.NOT_FOUND_ACTIVITIES_TO_CRITERIA'),
        'warning',
        3000
      );
    }
  }

  addQuestion(question) {
    if (this.questionsAdded.length >= 50) {
      this.notification.setNotification(
        this.translate.instant('EXAMS.ITEM.MAXIMUM_ACTIVITIES_EXCEEDED'),
        'error',
        3000
      );
      return;
    }
    const newQuestion = Object.assign({}, question);
    this.questionsAdded.push(newQuestion);
    const i = this.questionsAdded.length > 0 ? this.questionsAdded.length : 0;
    this.questionsAdded[(i - 1)].order = this.questionsAdded[(i - 1)].order || this.questionsAdded.length;
    this.questionsAdded[(i - 1)].times = this.questionsAdded[(i - 1)].times || 0;
  }

  checkQuestion(question) {
    return this.questionsAdded.find((item)=> {return item.id == question.id});
  }

  removeQuestion(question) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    if (!this.isNewExam) {
      this.examService.removeExamQuestion(question.idExamQuestion);
    }
    this.questionsAdded.splice(this.questionsAdded.indexOf(question), 1);
    this.reorderQuestions();
  }

  reorderQuestions() {
    for (let i = 0; i < this.questionsAdded.length; i ++) {
      this.questionsAdded[i].order = (i + 1);
    }
  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questionsAdded, event.previousIndex, event.currentIndex);
  }

  createNewQuestion() {
    this.router.navigate(['/question', 'create']);
  }

  generateExamQuestions(questions, updateType = false ) {
    const examQuestions = [];
    let aux = 1;
    for (const q of questions) {
      const newQuestion: any = {
        question: {
          id: q.id
        },
        questionOrder: aux,
        responseSize: q.times
      };
      aux++;
      if ( updateType ) { newQuestion.id = q.idExamQuestion; }
      examQuestions.push(newQuestion);
    }
    return examQuestions;
  }

  addNewActivity(questionsAdded) {
    this.addQuestion(questionsAdded);
    this.questionAddShown = false;
    this.notification.setNotification(
      this.translate.instant('EXAMS.ITEM.ACTIVITY_CREATE_EVOLUTION_ADD'),
      'success',
      3000
    );
    this.getQuestions();
  }

  checkIfSessionCache() {
    if (JSON.parse(sessionStorage.getItem('examSessionCache'))) {
      this.exam = JSON.parse(sessionStorage.getItem('examSessionCache')).exam;
      this.questionsAdded = JSON.parse(sessionStorage.getItem('examSessionCache')).questionsAdded;
      setTimeout(() => {
        sessionStorage.removeItem('examSessionCache');
      }, 0);
    }
  }

  createExam(exam) {
    if (document.querySelector('body .enjoyhint') != null) {
      this.localStorageExam(exam);
      return;
    }
    const newExam = this.completeObject(exam);
    newExam.units = exam.units && exam.units.length > 0 ? this.getIdsArray(exam.units, 'id') : [];

    if ((!exam.title || exam.title.length <= 0) || (!exam.units)) {
      this.notification.setNotification(
        this.translate.instant('EXAMS.ITEM.NO_DATA_GENERATE_EXAM_PLEASE'),
        'error',
        3000
      );
      this.router.navigate(['exam', 'generator']);
    } else if (!this.examType || !this.questionsAdded || this.questionsAdded.length <= 0) {
      this.notification.setNotification(this.translate.instant('EXAMS.ITEM.FIELDS_NOT_QUESTIONS_EXAMS'), 'error', 3000);
    } else {
      this.examService.addExam(newExam)
      .subscribe((data) => {
          this.notification.setNotification(this.translate.instant('EXAMS.EXAM_CREATE_CORRECT'), 'success', 3000);
          if (JSON.parse(sessionStorage.getItem('examSessionCache'))) {
            sessionStorage.removeItem('examSessionCache');
          }
          this.router.navigate(['exam', data.id, 'preview']);
        },
        (err) => {
          this.notification.setNotification(this.translate.instant('EXAMS.ITEM.EXAM_NOT_CREATED'), 'error', 3000);
        });
    }
  }

  updateExam(exam) {
    if (document.querySelector('body .enjoyhint') != null) {
      this.localStorageExam(exam);
      return;
    }
    const updateExam: any = this.completeObject(exam);
    updateExam.id = exam.id;
    updateExam.units =  exam.units && exam.units.length > 0 ? this.getIdsArray(exam.units, 'id') : [];

    if ((!exam.title || exam.title.length <= 0) || (!exam.units && !exam.period)) {
      this.notification.setNotification(
        this.translate.instant('EXAMS.ITEM.NO_DATA_GENERATE_EXAM_PLEASE'),
        'error',
        3000
      );
      this.router.navigate(['exam', 'generator']);
    } else if (!this.examType || !this.questionsAdded || this.questionsAdded.length <= 0) {
      this.notification.setNotification( this.translate.instant('EXAMS.ITEM.FIELDS_NOT_QUESTIONS_EXAMS'), 'error', 3000);
    } else {
      this.examService.updateExam(updateExam, exam.id)
        .subscribe((data) => {
          this.notification.setNotification(this.translate.instant('EXAMS.ITEM.EXAM_EDIT_CORRECT'), 'success', 3000);
          if (JSON.parse(sessionStorage.getItem('examSessionCache'))) {
            sessionStorage.removeItem('examSessionCache');
          }
          this.router.navigate(['exam', data.id, 'preview']);
        }, (err) => {
          this.notification.setNotification(this.translate.instant('EXAMS.ITEM.EXAM_NOT_UPDATED'), 'error', 3000);
        });
    }
  }

  localStorageExam (exam: any) {
    // No se puede previsualizar si no se ha creado el examen.
    exam.id = 'wizard';
    exam.examQuestions = [];
    this.questionsAdded.forEach((q, i = 0) => {
      i++;
      exam.examQuestions.push({
        id: q.id,
        question: q,
        questionOrder: i
      });
    });
    localStorage.setItem('exam', JSON.stringify(exam));
    this.router.navigate(['exam', exam.id, 'preview']);
  }

  completeObject(exam) {
    return {
      title: exam.title,
      period: exam.period && exam.period.id ? { id: exam.period.id } : null,
      pattern: {
        id: this.examType ? this.examType : 1
      },
      examQuestions: this.questionsAdded && this.questionsAdded.length > 0 ? this.generateExamQuestions(this.questionsAdded, true) : [],
      teacher: {
        id: JSON.parse(localStorage.getItem('user')).id
      },
      units: [],
      logo: exam.logo ? exam.logo : null,
      schoolName: exam.schoolName,
      area: exam.area,
      classGroup: exam.classGroup,
      isEvaluable: exam.isEvaluable,
      periodType: exam.periodType,
      date: this.examDate ? moment(this.examDate).format('DD/MM/YYYY') : null
    };
  }

  cancelExam() {
    if (JSON.parse(sessionStorage.getItem('examSessionCache'))) {
      sessionStorage.removeItem('examSessionCache');
    }
    this.router.navigate(['exam']);
  }

  unitSelect(unit) {
    this.selectedUnit = unit;
    this.isInParam = 'unit';
  }

  joinArray(array, param, type?) {
    const self = this;
    return array.map(item =>
      type === 'unit' ? self.translate.instant('EXAMS.UNIT') + ' ' +  + item[param] : item[param]
    ).join(', ');
  }

  onFileChange(event, exam) {
    exam.logo = null;
    const fileTypes = ['png', 'jpg', 'jpeg'];
    const file = event.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase(),
            isSuccess = fileTypes.indexOf(extension) > -1;

    if (isSuccess) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onloadstart = () => {
        exam.logo = 'loadStart';
      };
      reader.onload = () => {
        const body: FormData = new FormData();
        body.append('file', file);
        this.examService.postFile(body)
        .subscribe((res: any) => {
          this.imgPerfil = this.imgUrl + res.location;
          exam.logo = this.imgPerfil;
        }, () => {
          this.notification.setNotification(this.translate.instant('QUESTIONS.ERROR_IMAGE_IMPORT'), 'error', 6000);
        });
      };
      reader.onerror =  () => {
        exam.logo = 'error';
      };
    } else {
      this.notification.setNotification(this.translate.instant('EXAMS.FILE_INVALID'), 'error', 6000);
    }
  }

  removeFile(exam) {
    exam.logo = null;
    this.imgPerfil = null;
  }

  dateChange(event) {
    if (event == "Invalid Date") {
      this.examDate = null;
    } else {
      this.examDate = event;
    }
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.imgPerfil);  
  }

  setObjetiveColor(i) {
    let color = '#f3075f';
    let colorArr = ['#f3075f', '#91217f', '#ff653b'];
    let count = 0;
    for (let j = 0; j < i ; j ++) {
      count = count + 1;
      if (count >= colorArr.length) { count = 0; }
      color = colorArr[count];
    }
    return color;
  }

  checkIfIsInArray(array, item, param) {
    for (const i of array) {
      if (i[param] === item[param]) {
        return true;
      }
    }
    return false;
  }

  getIndexOf(array, item, param) {
    let index = 0;
    for (let i = 0; i < array.length; i ++) {
      if (array[i][param] === item[param]) {
        index = i;
      }
    }
    return index;
  }

  isInArray(array, item, param) {
    for (const i of array) {
      if (i[param] === item[param]) {
        return true;
      }
    }
    return false;
  }

  getIndexFilter(arr, param) {
    let index = -1;
    for (let i = 0; i < arr.length; i ++) {
      if (arr[i].param === param) {
        index =  i;
      }
    }
    return index;
  }

  getIdsArray(arr, param) {
    const newObj = [];
    for (const obj of arr) {
      newObj.push({[param]: obj[param]});
    }

    return newObj;
  }

  obtainCurriculum() {
    if (document.querySelector("body .enjoyhint")) {
      this.curriculumModal = true;
      return;
    }
    for (const question of this.questionsAdded) {
      for (const curriculum of question.curriculums) {
        if (!(this.selectedCurriculums.find(c => {
          return c.id === curriculum.id;
        }))) {
          this.selectedCurriculums.push (curriculum);
        }
      }
    }
    this.curriculumModal = true;
  }

  coverCurriculum() {
    // metodo para cubrir el curriculum
  }

  showAccordion(accordion, id) {
    const content = document.querySelector(accordion + " #collapse" + id);
    const header = document.querySelector(accordion + " #heading-" + id + " .btn-link");
    if (content && header) {
      content.classList.toggle("show");
      header.classList.toggle("collapsed");
    }
  }
  
  ngOnDestroy(): void {
    if(this.configSubscribe) {this.configSubscribe.unsubscribe()}
    if(this.unitsSubscribe) {this.unitsSubscribe.unsubscribe()}
  }
}
