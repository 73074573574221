// Component Dependencies
import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';

// App Config data
import { AppSettings } from '../app.config';

// Services
import { ConfigService } from '../data/services/config.service';

// Services
import { ExamService } from '../data/services/exam.service';
import { NotificationService } from '../data/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

// Models
import { Exam } from '../data/models/exam.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit, AfterViewChecked, OnDestroy {
  // Routes
  routes = [
    { label: "Inicio", url: "/home"},
    { label: "Listado de pruebas de evaluación", url: "/exam"}
  ];

  // Metadata Selected Data
  loading: boolean = false;
  confirmRemove = false;
  apiUrl: string;

  // Main Data
  exams: Exam[] = [];
  total = 0;

  // View Variables
  searchExam = '';
  removeExam = null;

  // Metadata Properties
  teacherId: any;
  groupId: any;

  // QUERY Exam Params
  filterArray: any[] = [];
  queryArray: any[] = [];
  limit = 7;
  offset = 0;
  sort = 'id';
  dirSort = 'asc';
  page = 1;

  // Subscriptions
  private configSubscription;

  constructor(
    private examService: ExamService,
    private notification: NotificationService,
    public translate: TranslateService,
    private config: ConfigService,
    private router: Router
  ) { 
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
  }

  ngOnInit() {
    localStorage.removeItem('wizardQuestions');
    localStorage.removeItem('automaticQuestions');
    localStorage.removeItem('examGenerated');
    this.configSubscription = this.config.subjectMetadataUpdated.subscribe((data) => {
      this.getExams();
    });
    this.getExams();
  }

  ngAfterViewChecked() {
    if ((localStorage.getItem('previusPage').includes('home')) && (localStorage.getItem('nextStep') === 'true')) {
      this.newEnjoy();
    }
  }

  newEnjoy() {
    //event, text, next, showArrow, hideArrow, shape, timeout, scroll, nextButton
    this.config.enjoyhintSteps = [
      this.config.completeStep('next .step-exam1', 'Este listado muestra las pruebas creadas con anterioridad. Inicialmente, si no has realizado ninguna carga, el listado estará vacío.', true, false, '', 600),
      this.config.completeStep('click .step-exam2', 'Para generar una nueva prueba de evaluación, pulsa en «Crear nueva prueba».',
        false, false, '', 0)
    ];
    this.config.newEnjoy();
  }

  getExams() {
    this.loading = true;
    this.examService.getExams(
      this.queryArray,
      this.filterArray,
      this.sort,
      this.dirSort,
      this.limit,
      this.offset,
      JSON.parse(localStorage.getItem('user')).id,
      JSON.parse(localStorage.getItem('groupSelected')).id
    )
    .subscribe(data => {
      this.loading = false;
      this.exams = data.results;
      if (localStorage.getItem('exam') != null && document.querySelector('body .enjoyhint') != null) {
        const wizardExam = JSON.parse(localStorage.getItem('exam'));
        if (wizardExam != null) {
          this.exams.push(wizardExam);
        }
        localStorage.removeItem('exam');
      }
      this.total = data.metadata.totalResults;
    });
  }

  searchExams() {
    this.queryArray = [{ param: 'title', value: this.searchExam }];
    this.getExams();
  }

  pageChanged(event: any): void {
    this.offset = (event.page * this.limit) - this.limit;
    this.getExams();
  }

  preview(exam) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.router.navigate(['/exam', exam.id, 'preview']);
  }
  update(exam) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.router.navigate(['/exam', exam.id]);
  }
  openModalDelete(exam) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.removeExam = exam;
    this.confirmRemove = true;
  }

  deleteExam(id) {
    this.examService.deleteExam(id)
      .subscribe(() => {
        this.notification.setNotification(this.translate.instant('EXAMS.EXAM_DELETE_CORRECT'), 'success', 6000);
        this.removeExam = null;
        this.getExams();
      }, (err) => {
        if (err.status === 403) {
          this.notification.setNotification(this.translate.instant('EXAMS.ERROR_DELETE_EXAM_TO_UNIT'), 'error', 6000);
        } else {
          this.notification.setNotification(this.translate.instant('EXAMS.ERROR_EXAM_DELETE'), 'error', 6000);
        }
      });
  }

  setObjetiveColor(i) {
    let color = '#f3075f';
    let colorArr = ['#f3075f', '#91217f', '#ff653b'];
    let count = 0;
    for (let j = 0; j < i ; j ++) {
      count = count + 1;
      if (count >= colorArr.length) { count = 0; }
      color = colorArr[count];
    }
    return color;
  }

  ngOnDestroy() {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }
}