// Component Dependencies
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

// Services
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../data/services/config.service';
import { AuthService } from '../data/services/auth.service';
import { NotificationService } from '../data/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewChecked {

  loginForm: UntypedFormGroup;
  checkUrl: any[];
  pdfIcon: string = "pdf.png";

  loading: boolean = false;

  singleSignon: boolean = false;

  credentials: any = {
    username: '',
    password: ''
  };

  constructor (
    public translate: TranslateService,
    private config: ConfigService,
    public notifService: NotificationService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.logout();
    this.loginForm = new UntypedFormGroup({
      'username': new UntypedFormControl(this.credentials.username, [
        Validators.required
      ]),
      'password': new UntypedFormControl(this.credentials.password, [
        Validators.required
      ]),
      'hash': new UntypedFormControl(''),
      'code': new UntypedFormControl(''),
      'app': new UntypedFormControl(3)
    });

    this.route.queryParams
    .subscribe(params => {
      if (params.username && params.hash && params.code) {
        this.singleSignon = true;
        this.loading = true;
        this.loginForm.value.username = params.username;
        this.loginForm.value.hash = params.hash;
        this.loginForm.value.code = params.code;
        this.loginForm.value.password = '';
        this.login();
      }
    });
  }
  
  ngAfterViewChecked() {
    if(document.querySelector(".wrs_modal_dialogContainer")) {
      document.querySelector(".wrs_modal_dialogContainer").remove();
    }
  }

  login() {
    this.loading = true;
    this.auth.login(this.loginForm.value)
    .subscribe(
      (data: any) => {
        if (data.user.rol[0] !== "ROLE_TEACHER") {
          this.notifService.setNotification(this.translate.instant('LOGIN.NOTIF.ERROR'), 'error', 6000);
          this.auth.logout();
          this.loading = false;
          return;
        }
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('token', JSON.stringify(data.token));
        if (this.singleSignon) {
          if (data.subject) {
            localStorage.setItem('subjectSelected', JSON.stringify(data.subject));
            this.config.selectedSubject = data.subject;
          }
          if (data.classGroup) {
            localStorage.setItem('groupSelected', JSON.stringify(data.classGroup));
            this.config.selectedGroup = data.classGroup;
          }
        }
        this.config.user = data.user;
        this.config.rol = data.user.rol[0];
        this.notifService.setNotification(this.translate.instant('LOGIN.NOTIF.SUCCESS'), 'success', 3000);
        this.loginForm.reset();

        this.loading = false;
        this.singleSignon = false;
        localStorage.setItem("previusPage", "/login");
        this.router.navigate(['/home']);
      },
      () => {
        if (this.loginForm.invalid) {
          this.notifService.setNotification(this.translate.instant('LOGIN.NOTIF.PENDING'), 'error', 6000);
        } else if (this.loginForm.valid) {
          this.notifService.setNotification(this.translate.instant('LOGIN.NOTIF.ERROR'), 'error', 6000);
        }
        this.loading = false;
        this.singleSignon = false;
      }
    );
  }
  openFile() {
    window.open("https://prod.santillana.es/eval/manuales/20/acceso_ges.pdf", "_blank");
  }
}
