// Component Dependencies
import { Component, OnInit, ViewEncapsulation, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

// Services
import { ExamService } from '../../data/services/exam.service';
import { ConfigService } from '../../data/services/config.service';
import { NotificationService } from '../../data/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

// App Config data
import { AppSettings } from '../../app.config';

// Models
import { Exam } from '../../data/models/exam.model';

@Component({
  selector: 'exam-preview',
  templateUrl: './exam-preview.component.html',
  styleUrls: ['./exam-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExamPreviewComponent implements OnInit, AfterViewChecked {
  // Main Data
  userRegion: any;
  exam: Exam;
  testId: number = 6994;

  gradeId: number;
  curriculumModal: any;
  selectedCurriculums: any = [];

  questionsAdded: any[] = [];

  // View Variables
  loading: boolean = false;
  imgPerfil: any = null;

  downloadModal: boolean = false;
  downloadType: any = null;

  apiUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notif: NotificationService,
    private examService: ExamService,
    public translate: TranslateService,
    private config: ConfigService,
    private sanitizer: DomSanitizer
  ) {
    this.userRegion = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).region : null;
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.getExamId(params.id);
        this.getGradeByExam(params.id);
      }
    });
  }

  ngAfterViewChecked() {
    if ((localStorage.getItem('previusPage').includes('create'))
      && localStorage.getItem('nextStep') === 'true') {
      this.newEnjoy();
    }
  }

  newEnjoy() {
    //event, text, next, showArrow, hideArrow, shape, timeout, scroll, nextButton
    this.config.enjoyhintSteps = [
      this.config.completeStep('next .hidden-wizard', 'Se muestra en pantalla la prueba de evaluación tal y como la has configurado, visualizándose tanto la cabecera como las actividades añadidas.', true, true, '', 1000),
      this.config.completeStep('next .btn-pdf', 'Puedes descargar la prueba de evaluación en dos tipos de formato, PDF o Word, y guardarla en tu ordenador. Así podrás imprimirla para tu alumnado.', true, false, '', 0, {class: '.btn-pdf', type: false}),
      this.config.completeStep('next .btn-knowledge', 'Si lo deseas, podrás visualizar los Saberes y criterios asociados a la misma. ', true, false, '', 0),
      this.config.completeStep('next .hidden-wizard', 'Así podrás comprobar si estás cubriendo todos los elementos curriculares que necesitas.', true, true, '', 0, {}),
      this.config.completeStep('click .btn-back', 'Cuando hayas finalizado, puedes volver al listado de tus pruebas de evaluación.', false, false, '', 1000, {}, '', true),
      this.config.completeStep('next .step-exam1', 'Verás entonces la nueva prueba de evaluación creada.', true, false, '', 600),
      this.config.completeStep('next .step-exam1 .btn_pink', 'En caso de que desees visualizar la prueba de evaluación, pulsa en «ver».', true, false, '', 0),
      this.config.completeStep('next .step-exam1 .btn_orange', 'Para editar una prueba, o para modificar las actividades añadidas a la misma, debes pulsar en «editar».', true, false, '', 0),
      this.config.completeStep('next .step-exam1 .btn_purple', 'Y, si por cualquier motivo quieres eliminarla, pulsa en «borrar». ', true, false, '', 0, {}, 'goHome')
    ];
    this.config.newEnjoy();
  }

  getExamId(id) {
    if (document.querySelector("body .enjoyhint") && localStorage.getItem("exam")) {
      this.getExamWizard();
      return;
    }
    this.loading = true;
    this.examService.getExam(id).subscribe(
      data => {
        this.exam = data;
        this.exam.examQuestions = this.exam.examQuestions.sort( (a, b) => {
          if (a.questionOrder > b.questionOrder) {
            return 1;
          }
          if (a.questionOrder < b.questionOrder) {
            return -1;
          }
          return 0;
        });
        this.imgPerfil = this.exam.logo ? this.exam.logo : null;
        this.obtainQuestions(this.exam.examQuestions);
        this.loading = false;
      },
      (err) => {
        this.router.navigate(['/exam']);
        console.error(err);
        this.notif.setNotification(this.translate.instant('EXAMS.PREVIEW.EXAM_NOT_FOUND'), 'error', 6000);
      }
    );
  }

  getExamWizard() {
    this.exam = JSON.parse(localStorage.getItem("exam"));
    this.imgPerfil = null;
    this.questionsAdded = [];
    this.gradeId = 2;
    for (const q of this.exam.examQuestions) {
      this.questionsAdded.push(q);
    }
  }
  
  getGradeByExam(id) { 
    if (document.querySelector("body .enjoyhint") && localStorage.getItem("exam")) {
      return;
    }
    this.examService.getGradeByExam( id )
      .subscribe( (data) => {
        this.gradeId = data.id;
      });
  }

  repeatResponseLines( times ) {
    const arr = [];
    for (let i = 0; i < times; i++) {
      arr.push(i);
    }
    return arr;
  }

  openModalDownload() {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.downloadModal = true;
  }

  downloadFile() {
    if(this.downloadType === "pdf") {
      this.getPDFfile();
    } else if (this.downloadType === "word") {
      this.getWordFile();
    }
  }

  getPDFfile() {
    this.loading = true;
    this.examService.getExamPDFFile(this.exam.id)
      .subscribe( (data) => {
        this.notif.setNotification(this.translate.instant('EXAMS.PREVIEW.EXAM_DOWNLOAD_PDF'), 'success', 6000);
        this.downloadModal = false;
        this.downloadType = null;
        this.loading = false;
      },
      (error) => {
        this.notif.setNotification(this.translate.instant('EXAMS.PREVIEW.ERROR_DOWNLOAD'), 'error', 6000);
        this.loading = false;
      });
  }

  getWordFile() {
    this.loading = true;
    this.examService.getExamWordFile(this.exam.id)
      .subscribe( (data) => {
        this.notif.setNotification(this.translate.instant('EXAMS.PREVIEW.EXAM_DOWNLOAD_WORD'), 'success', 6000);
        this.downloadModal = false;
        this.downloadType = null;
        this.loading = false;
      },
    (error) => {
      this.notif.setNotification(this.translate.instant('EXAMS.PREVIEW.ERROR_DOWNLOAD'), 'error', 6000);
      this.loading = false;
    });
  }

  obtainQuestions(questions) {
    this.questionsAdded = [];
    for (const q of questions) {
      this.questionsAdded.push(q.question);
    }
  }

  obtainCurriculum() {
    for (const question of this.questionsAdded) {
      for (const curriculum of question.curriculums) {
        if (!(this.selectedCurriculums.find(c => {
          return c.id === curriculum.id;
        }))) {
          this.selectedCurriculums.push (curriculum);
        }
      }
    }
    this.curriculumModal = true;
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.imgPerfil);  
  }

  showAccordion(accordion, id) {
    const content = document.querySelector(accordion + " #collapse" + id);
    const header = document.querySelector(accordion + " #heading-" + id + " .btn-link");
    if (content && header) {
      content.classList.toggle("show");
      header.classList.toggle("collapsed");
    }
  }
}
