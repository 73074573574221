// Component Dependencies
import { Component, AfterViewChecked, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { QuestionService } from '../data/services/question.service';
import { UnitService } from '../data/services/unit.service';
import { CurriculumService } from '../data/services/curriculum.service';
import { ConfigService } from '../data/services/config.service';
import { NotificationService } from '../data/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

// App Config data
import { AppSettings } from '../app.config';

// Models
import { Question } from '../data/models/question.model';
import { Unit } from '../data/models/unit.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit, AfterViewChecked, OnDestroy {
  // Routes
  routes = [
    { label: "Inicio", url: "/home"},
    { label: "Listado de actividades de la prueba", url: "/question"}
  ];

  // Main Data
  questions: Question[] = [];
  total = 0;
  subjectMetadata;
  selectedGroup;
  selectedGrade;
  selectedSubject;
  selectedBook;
  selectedRegion;

  // View Variables
  searchQuestion: string = '';
  loading: boolean = false;
  loadingSoft: boolean = false;
  questionType: string = 'auto';

  // Filters Data
  units: Unit[] = [];
  selectedUnit: Unit = null;
  unitDropdown: boolean;
  unitConfig: any = {};

  // Curriculum
  competences: any = [];
  selectedCompetence: any = null;
  competenceDropdown: boolean;
  competenceConfig: any = {
    param: 'competence',
    search: true,
    pagination: false,
    total: 0
  };
  competenceOffset = 0;

  criterias: any = [];
  selectedCriteria: any = null;
  criteriaDropdown: boolean;
  criteriaConfig: any = {
    param: 'competence',
    search: true,
    pagination: false,
    total: 0
  };
  criteriaOffset = 0;

  knowledges: any = [];
  selectedKnowledge: any = null;
  knowledgeDropdown: boolean;
  knowledgeConfig: any = {    
    param: 'knowledge',
    field: 'description',
    search: true,
    pagination: false,
    total: 0
  };
  knowledgeOffset = 0;

  // Modal Preview
  questionPreview: Question = null;
  previewShown = false;

  // Confirm Remove
  confirmRemove: boolean = false;
  questionSelected: Question;

  // QUERY Question Params
  queryArray: any[] = [];
  limit = 7;
  offset = 0;
  sort = 'id';
  dirSort = 'asc';
  page = 1;

  apiUrl: string;

  configSubscribe;
  unitsSubscribe;

  constructor (
    private questionService: QuestionService,
    private unitService: UnitService,
    private curriculumService: CurriculumService,
    public config: ConfigService,
    public notification: NotificationService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
    this.subjectMetadata = this.config.getSubjectMetadata();
    this.selectedGroup = this.config.selectedGroup;
    this.selectedRegion = this.config.selectedRegion;
    this.selectedSubject = this.config.selectedSubject;
    this.selectedBook = {id: this.config.selectedGroup.book};
    this.selectedGrade = this.config.selectedGroup.grade;

    this.configSubscribe = this.config.subjectMetadataUpdated.subscribe((data) => {
      this.subjectMetadata = data;
      this.selectedGroup = this.config.selectedGroup;
      this.selectedRegion = this.config.selectedRegion;
      this.selectedSubject = this.config.selectedSubject;
      this.selectedBook = {id: this.config.selectedGroup.book};
      this.selectedGrade = this.config.selectedGroup.grade;
      this.getUnits();
      this.addFilter();
    });
    this.getUnits();
    this.getQuestions();
  }

  ngOnInit() {
    if (!localStorage.getItem('previusPage').includes('question/create')) {
      localStorage.removeItem('question');
    }
  }

  ngAfterViewChecked() {
    if ((localStorage.getItem('previusPage').includes('home')) && (localStorage.getItem('nextStep') === 'true')) {
      this.newEnjoy();
    }
  }

  newEnjoy() {
    localStorage.removeItem('question');
    //event, text, next, hideArrow, shape, timeout, scroll, nextButton
    this.config.enjoyhintSteps = [
      this.config.completeStep('next .hidden-wizard', 'Estas actividades están asociadas a una unidad determinada del libro y a las competencias específicas, criterios de evaluación y saberes básicos de la Lomloe. En estos desplegables podrás filtrar cada uno de los elementos curriculares con los que te interese trabajar, y se irán acotando las búsquedas.', true, true, '', 1000),
      this.config.completeStep('next .step-question1', 'Es posible visualizar las actividades de Santillana para revisarlas y valorar la opción de duplicarlas y personalizarlas o modificarlas, según consideres, convirtiéndose en una «actividad propia» que podrás dirigir al repositorio «Mis actividades».', true, false, '', 0),
      this.config.completeStep('click .unitDrop', 'También puedes aplicar filtros para acotar el listado, por ejemplo, seleccionando una unidad. El listado mostrará solo las actividades relacionadas con los filtros que selecciones.', false, false, '', 0),
      this.config.completeStep('click .unitDrop .dropdown_menu', 'Selecciona una unidad.', false, false, '', 0),
      this.config.completeStep('click .step-question3', 'Si lo que deseas es trabajar con actividades propias, deberás seleccionar la opción «Mis actividades».', false, false, '', 1000),
      this.config.completeStep('next .step-question1', 'Pero, si en el momento de la consulta no has creado todavía actividades, el listado aparecerá vacío.', true, false, '', 1000),
      this.config.completeStep('next .hidden-wizard', 'Asimismo, si tienes ya un listado de actividades creadas por ti, podrás consultarlas y seleccionar en los desplegables los elementos curriculares y la unidad a la que están asociadas.', true, true, '', 0),
      this.config.completeStep('click .btn_new', 'Para crear una nueva actividad debes seleccionar «Crear nueva actividad».', false, false, '', 0)
    ];
    this.config.newEnjoy();
  }

  addFilter() {
    this.offset = 0;
    if (this.page == 1) {
      this.getQuestions();
    } else {
      this.page = 1;
    }
  }

  getQuestions() {
    this.loading = true;
    const filters = [];
    if (!(this.selectedBook && this.selectedBook.id &&
      this.selectedRegion && this.selectedRegion.id &&
      this.selectedSubject && this.selectedSubject.id &&
      this.selectedGrade
    )) {
      this.loading = false;
      return;
    }
    filters.push({ param: 'own', value: this.questionType === 'self' ? 1 : 0 });
    localStorage.setItem("questionTypeFilter", this.questionType);
    filters.push({ param: 'book', value: this.selectedBook.id });
    filters.push({ param: 'region', value: this.selectedRegion.id });
    filters.push({ param: 'subject', value: this.config.selectedSubject.id });
    filters.push({ param: 'grade', value: this.config.selectedGrade.id });
    if (this.selectedUnit && this.selectedUnit.id) {
      filters.push({param: "unit", value: [this.selectedUnit.id]});
      localStorage.setItem("questionUnitFilter", this.selectedUnit.id);
    } else {
      localStorage.removeItem("questionUnitFilter");
    }
    if (this.selectedKnowledge !== null) {
      filters.push({ param: 'basicKnowledge', value: this.selectedKnowledge.id})
    } else if (this.selectedCriteria !== null) {
      filters.push({ param: 'evaluationCriteria', value: this.selectedCriteria.id})
    } else if (this.selectedCompetence !== null) {
      filters.push({ param: 'specificCompetence', value: this.selectedCompetence.id})
    }

    this.questionService.getGesQuestions(this.queryArray, filters, this.sort, this.dirSort, this.limit, this.offset)
    .subscribe(data => {
      this.questions = data.results;
      if (localStorage.getItem('question') != null && document.querySelector('body .enjoyhint') != null) {
        const wizardQuestion = JSON.parse(localStorage.getItem('question'));
        if (wizardQuestion != null) {
          this.questions.push(wizardQuestion);
        }
      }
      this.total = data.metadata.totalResults;
      setTimeout(() => {
        this.loading = false;
      }, 500 );
    });
  }

  getQuestionById(id) {
    this.loadingSoft = true;
    this.questionService.getQuestion(id)
    .subscribe(data => {
      this.questionPreview = data;
      this.loadingSoft = false;
    }, () => {
      this.loadingSoft = false;
    });
  }

  getUnits() {
    const filters = [];
    if (this.subjectMetadata) {
      filters.push({param: 'subjectMetadata', value: this.subjectMetadata});
    }
    if (this.selectedBook && this.selectedBook.id) {
      filters.push({ param: 'book', value: this.selectedBook.id });
    }
    this.unitConfig.field = 'number';
    this.unitConfig.param = 'unit';
    this.unitConfig.loading = true;
    this.unitConfig.search = false;
    this.unitConfig.pagination = false;
    this.unitConfig.prelabel = 'Unidad ';
    this.unitsSubscribe = this.unitService.getUnits([], filters, this.sort, this.dirSort, 99, 0)
    .subscribe(data => {
      this.units = data.results;
      this.unitConfig.total = data.metadata.totalResults;
      this.unitConfig.loading = false;
    },
    ()=> {this.unitConfig.loading = false;});
  }

  getCompetences(text = null) {
    let filters = [];
    if (this.subjectMetadata) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.competenceConfig.loading = true;
    this.curriculumService.getCompetences(filters)
    .subscribe(data => {
      this.competences = data.results;
      this.competenceConfig.total = data.metadata.totalResults;
      this.competenceConfig.loading = false;
    },
    ()=> {this.competenceConfig.loading = false;});
  }

  getCriterias(text = null) {
    let filters = [];
    if (this.subjectMetadata) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedCompetence != null) {filters.push({param: 'specificCompetence', value: this.selectedCompetence.id});} 
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.criteriaConfig.loading = true;
    this.curriculumService.getCriterias(filters)
    .subscribe(data => {
      this.criterias = data.results;
      this.criteriaConfig.total = data.metadata.totalResults;
      this.criteriaConfig.loading = false;
    },
    ()=> {this.criteriaConfig.loading = false;});
  }

  getKnowledges(text = null) {
    let filters = [];
    if (this.subjectMetadata) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedCriteria != null) {filters.push({param: 'evaluationCriteria', value: this.selectedCriteria.id});}
    else if (this.selectedCompetence != null) {filters.push({param: 'specificCompetence', value: this.selectedCompetence.id});}
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.knowledgeConfig.loading = true;
    this.curriculumService.getKnowledges(filters)
    .subscribe(data => {
      this.knowledges = data.results;
      this.knowledgeConfig.total = data.metadata.totalResults;
      this.knowledgeConfig.loading = false;
    },
    ()=> {this.knowledgeConfig.loading = false;});
  }

  searchQuestions() {
    this.queryArray = [{param: 'title', value: this.searchQuestion}];
    this.addFilter();
  }

  searchCriterias(event) {
    this.getCriterias(event.text);
  }

  searchCompetences(event) {
    this.getCompetences(event.text);
  }

  searchKnowledges(event) {
    this.getKnowledges(event.text);
  }

  pageChanged(event: any): void {
    this.offset = (event.page * this.limit) - this.limit;
    this.getQuestions();
  }

  previewQuestion(question: Question) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.getQuestionById(question.id);
    this.previewShown = true;
  }

  getIndexFilter(arr, param) {
    let index = -1;
    for (let i = 0; i < arr.length; i ++) {
      if (arr[i].param === param) {
        index =  i;
      }
    }
    return index;
  }

  openModalDelete(question) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.questionSelected = question;
    this.confirmRemove = true;
  }

  deleteQuestion( id: any ) {
    this.questionService.deleteQuestion(id)
      .subscribe(() => {
        this.notification.setNotification(this.translate.instant('QUESTIONS.QUESTION_DELETE_CORRECT'), 'success', 6000);
        this.addFilter();
      }, (err) => {
        if (err.status === 403) {
          this.notification.setNotification(this.translate.instant('QUESTIONS.ERROR_DELETE_QUESTION_TO_EXAM'), 'error', 6000);
        } else {
          this.notification.setNotification(this.translate.instant('QUESTIONS.ERROR_DELETE_QUESTION'), 'error', 6000);
        }
      });
  }

  cloneQuestion(question) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.questionService.getQuestion(question.id)
      .subscribe((data: any) => {
        let curriculums = [];
        if (data.curriculums && data.curriculums.length > 0) {
          curriculums = data.curriculums.map(item => {
            return {id: item.id};
          });
        }
        const newQuestion = {
          'title': data.title ? data.title : null,
          'content': data.content ? data.content : null,
          'answer': data.answer ? data.answer : null,
          'unit': data.unit && data.unit.id ? {'id': data.unit.id} : null,
          'numActivity': data.numActivity ? data.numActivity : 1,
          'parent': data.parent && data.parent.id ? {'id': data.parent.id } : null,
          'curriculums': curriculums
        };
        this.questionService.addGesQuestion(newQuestion)
          .subscribe((res: any) => {
            this.notification.setNotification(this.translate.instant('QUESTIONS.DUPLICATE_QUESTION_CORRECT'), 'success', 6000);
            this.router.navigate(['/question', res.id]);
          }, () => {
            this.notification.setNotification(this.translate.instant('QUESTIONS.ERROR_DUPLICATE_QUESTION_SELECTED'), 'error', 6000);
          });

      });
  }

  updateQuestion(question) {
    if (document.querySelector("body.on-enjoy") && !document.querySelector(".enjoyhint_next_btn.enjoyhint_hide")) {
      return;
    }
    this.router.navigate(['/question', question.id]);
  }

  unitSelect(unit) {
    this.selectedUnit = unit;

    // Para vaciar si están filtros activos.
    if (this.selectedCriteria && this.selectedCriteria.id) {
      this.selectedCriteria = null;
    }
    if (this.selectedCompetence && this.selectedCompetence.id) {
      this.selectedCompetence = null;
    }
    if (this.selectedKnowledge && this.selectedKnowledge.id) {
      this.selectedKnowledge = null;
    }
  }

  competenceSelect(competence) {
    this.selectedCompetence = competence;
    this.selectedCriteria = null;
    this.selectedKnowledge = null;
  }

  criteriaSelect(criteria) {
    if (this.selectedCompetence == null) {
      // select competence by criteria
      const competence = this.competences.find(item => {
        return item.id === criteria.specificCompetence.id;
      });
      this.selectedCompetence = competence;
    }
    this.selectedCriteria = criteria;
    this.selectedKnowledge = null;
  }

  knowledgeSelect(knowledge) {
    this.selectedKnowledge = knowledge;
  }

  getPDFFile() {
    const subject = JSON.parse(localStorage.getItem('subjectSelected'));
    const filename = this.config.selectedGroup.title + '-actividades.pdf';
    const region = this.selectedRegion && this.selectedRegion.id ? this.selectedRegion.id : this.selectedRegion;

    this.questionService.getExportPDFFile(this.config.selectedGrade.id, subject.id, this.selectedBook.id, region, filename)
      .subscribe(() => {
        this.notification.setNotification(this.translate.instant('QUESTIONS.SUCCESS_FILE_EXPORT'), 'success', 6000);
      }, () => {
        this.notification.setNotification(this.translate.instant('QUESTIONS.ERROR_GET_FILE'), 'error', 6000);
      });
  }

  setObjetiveColor(i) {
    let color = '#f3075f';
    let colorArr = ['#f3075f', '#91217f', '#ff653b'];
    let count = 0;
    for (let j = 0; j < i ; j ++) {
      count = count + 1;
      if (count >= colorArr.length) { count = 0; }
      color = colorArr[count];
    }
    return color;
  }

  getIdOfArrays(array) {
    const arr = [];
    for (const item of array) {
      const i = { 'id': item.id };
      arr.push(i);
    }
    return arr;
  }

  queryStringify(query) {
    let str = '{';
    for (const item of query) {
      if (item.param) {
        str = str.concat('"').concat(item.param).concat('": ');
        if (typeof item.value === 'string') {
          str = str.concat('"').concat(item.value).concat('"');
        } else {
          str = str.concat(item.value);
        }
      }
      if (query.indexOf(item) < query.length - 1 && query.length > 1) {
        str = str.concat(', ');
      }
    }
    str = str.concat('}');
    return str;
  }

  showAccordion(accordion, id) {
    const content = document.querySelector(accordion + " #collapse" + id);
    const header = document.querySelector(accordion + " #heading-" + id + " .btn-link");
    if (content && header) {
      content.classList.toggle("show");
      header.classList.toggle("collapsed");
    }
  }

  ngOnDestroy(): void {
    if(this.configSubscribe) {this.configSubscribe.unsubscribe()}
    if(this.unitsSubscribe) {this.unitsSubscribe.unsubscribe()}
  }
}
