// Component Dependencies
import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { ResourceService } from '../../../data/services/resource.service';
import { GroupService } from '../../../data/services/group.service';
import { SubjectService } from '../../../data/services/subject.service';
import { ConfigService } from '../../../data/services/config.service';
import { CommonsService } from '../../../data/services/commons.service';
import { NotificationService } from '../../../data/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../data/services/auth.service';

// Models
import { Language } from '../../../data/models/language.model';
import { Region } from '../../../data/models/region.model';
import { Subject } from '../../../data/models/subject.model';
import { Group } from '../../../data/models/group.model';

import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent {

  // Main data
  subjects: Subject[] = [];
  regions: Region[] = [];
  languages: Language[] = [];
  groupsSubject: Group[];

  // View Variables
  sizeNavbar: boolean;
  loading: boolean = false;
  showNavbar: boolean = true;
  subjectDropdown: boolean = false;
  groupDropdown: boolean = false;
  openSubjectMetadataSubmenu: boolean = false;
  windowSize: number = 0;

  // Detect language changed
  languageChanged: any;

  // Subscriptions
  private configSubscription: Subscription;
  private teacherSubjectSubscription: Subscription;
  private regionSubscription: Subscription;
  private subjectSubscription: Subscription;

  // Listen to window Resize Event
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getWidthScreen();
  }

  // Constructor
  constructor(
    public translate: TranslateService,
    public groupService: GroupService,
    public subjectService: SubjectService,
    public config: ConfigService,
    public notification: NotificationService,
    public common: CommonsService,
    public auth: AuthService,
    public router: Router
  ) {
    this.getUser();
    this.config.changeLanguage
    .subscribe(() => {
      this.languageChanged = localStorage.getItem('language');
      this.config.getSubjectMetadata();
      this.getUser();
    });

    this.configSubscription = this.config.subjectUpdated
      .subscribe(() => {
        if (this.config.selectedSubject && this.config.selectedSubject.id) {
          this.getClassGroups();
        }
      });
    this.config.getSubjectMetadata();
    this.getWidthScreen();
  }

  updateSelections() {
    if (localStorage.getItem('subjectSelected') && JSON.parse(localStorage.getItem('subjectSelected'))) {
      this.config.selectedSubject = JSON.parse(localStorage.getItem('subjectSelected'));
      const subject = this.subjects.filter(value => value.id === this.config.selectedSubject.id);
      if (subject.length > 0) {
        this.config.selectedSubject = subject[0];
        localStorage.setItem('subjectSelected', JSON.stringify(subject[0] ? subject[0] : null));
      }
      this.config.getSubjectMetadata();
    }
  }

  // A. GET INITIAL DATA: USER, SUBJECTS AND GRADES
  initialSubjectData() {
    const subject = localStorage.getItem('subjectSelected') !== 'undefined' ? JSON.parse(localStorage.getItem('subjectSelected')) : null;
    if (!subject) {
      localStorage.setItem('subjectSelected', JSON.stringify(this.subjects[0] ? this.subjects[0] : null));
      this.config.selectedSubject = this.subjects[0];
    } else {
      this.config.selectedSubject = subject;
      if (localStorage.getItem('groupSelected') && localStorage.getItem('groupSelected') !== 'undefined') {
        this.config.selectedGroup = JSON.parse(localStorage.getItem('groupSelected')) || null;
      }
    }

    this.config.getSelectedTeacher(this.config.selectedSubject, this.config.selectedGroup);
    if (this.config.selectedSubject && this.config.selectedSubject.id) {
      this.getClassGroups(false, 'subject');
    }
    this.config.getSubjectMetadata();
  }

  getUser() {
    if (JSON.parse(localStorage.getItem('user'))) {
      this.config.user = Object.assign({}, JSON.parse(localStorage.getItem('user')));
      this.config.rol = JSON.parse(localStorage.getItem('user')).rol[0];
      this.getTeacherSubjects().then(() => { this.updateSelections(); });
      if (this.config.selectedSubject && this.config.selectedSubject.id) {
        this.getClassGroups();
      }
      this.config.getSubjectMetadata();
    }
  }

  // B. GET ITEMS FOR DROPDOWNS: TEACHER SUBJECTS, SUBJECTS, GROUPS, Regions, Grades
  getTeacherSubjects(): Promise<any> {
    this.loading = true;
    const promises_array: Array<any> = [];
    const self = this;
    try {
      promises_array.push(new Promise(function(resolve) {
      self.teacherSubjectSubscription = self.subjectService.getTeacherSubjects([], [], 'id', 'asc', 99, 0)
        .subscribe(data => {
          self.subjects = data.results;
          if (data.results.length === 0) {
            self.notification.setNotification(self.translate.instant('LOGIN.NOTIF.ERROR'), 'error', 6000);
            self.logout();
            self.config.exitWizard();
            self.loading = false;
            return;
          }
          setTimeout(() => {
            self.initialSubjectData();
            self.loading = false;
          }, 200);
          resolve('done');
        }, () => {
          self.notification.setNotification(self.translate.instant('MESSAGES.ERROR_LOAD_DATA'), 'error', 6000);
          self.loading = false;
          resolve('done');
        });
      }));
    } catch (error) {
      console.log('error');
    }

    return Promise.all(promises_array);
  }

  getClassGroups(subjectChange = false, selector = 'group') {
    this.loading = true;
    const filter = [{ 'param': 'subject', 'value': this.config.selectedSubject ? this.config.selectedSubject.id : null }];
    this.teacherSubjectSubscription = this.groupService.getGroups(filter, [], 'id', 'asc', 99, 0)
      .subscribe((data) => {
        this.groupsSubject = data.results;
        this.groupsSubject = this.common.sortArray(this.groupsSubject, 'title');
        this.config.userGroups = data.results;
        if (selector === 'subject' && (!localStorage.getItem('groupSelected') || localStorage.getItem('groupSelected') === 'undefined' || subjectChange)) {
          localStorage.setItem('groupSelected', JSON.stringify(data.results[0]));
          this.config.selectedGroup = data.results[0];
        }
        this.loading = false;
        if (subjectChange && this.config.selectedGroup) {
          this.config.getSelectedTeacher(this.config.selectedSubject, this.config.selectedGroup);
        }
      }, () => {
        this.notification.setNotification(this.translate.instant('MESSAGES.ERROR_LOAD_DATA'), 'error', 6000);
        this.loading = false;
      });
  }

  getSubjects(): Promise<any> {
    this.loading = true;
    const promises_array: Array<any> = [];
    const self = this;
    try {
      promises_array.push(new Promise(function(resolve) {
        self.subjectSubscription = self.subjectService.getSubjects([], [], 'id', 'asc', 99, 0)
        .subscribe(data => {
          self.subjects = data.results;
          self.initialSubjectData();
          if (JSON.parse(localStorage.getItem('subjectSelected'))) {
            self.config.selectedSubject = JSON.parse(localStorage.getItem('subjectSelected'));
          }
          self.loading = false;
          resolve('done');
        }, () => {
          self.notification.setNotification(self.translate.instant('MESSAGES.ERROR_LOAD_DATA'), 'error', 6000);
          self.loading = false;
          resolve('done');
        });
      }));
    } catch (error) {
      console.log('error');
    }

    return Promise.all(promises_array);
  }


  // C. SELECT ON DROPDOWN: SUBJECT, GRADE OR REGION
  selectSubject(select) {
    if (select === 'subject') {
      localStorage.setItem('subjectPrevious', localStorage.getItem('subjectSelected'));
      localStorage.setItem('subjectSelected', JSON.stringify(this.config.selectedSubject));
      localStorage.setItem('groupSelected', JSON.stringify(this.config.selectedGroup));
    } else if (select === 'group') {
      localStorage.setItem('groupSelected', JSON.stringify(this.config.selectedGroup));
    }
    if (this.config.selectedSubject && this.config.selectedSubject.id) {
      this.getClassGroups(true, select);
    }
  }

  checkUrl(url) {
    const arrayUrl = url.split("/");
    if ((arrayUrl[1] && arrayUrl[2] && arrayUrl[1] === "question" && arrayUrl[2] !== "create") ||
        (arrayUrl[1] && arrayUrl[2] && arrayUrl[1] === "exam" && arrayUrl[2] !== "generator")) {
      return false;
    }
    return true;
  }

  // Get Width Screen
  getWidthScreen() {
    this.openSubjectMetadataSubmenu = false;
    const wwt = window.innerWidth;
    this.windowSize = window.innerWidth;
    if (wwt >= 1120) {
      this.showNavbar = true;
      this.sizeNavbar = true;
    } else if (wwt < 1120 && wwt >= 992) {
      this.showNavbar = true;
      this.sizeNavbar = false;
    } else if (wwt < 992) {
      this.showNavbar = false;
    }
  }

  logout() {
    this.auth.logout();
  }
  // OnDestroy
  ngOnDestroy() {
    if (this.configSubscription) { this.configSubscription.unsubscribe(); }
    if (this.teacherSubjectSubscription) { this.teacherSubjectSubscription.unsubscribe(); }
    if (this.regionSubscription) { this.regionSubscription.unsubscribe(); }
    if (this.subjectSubscription) { this.subjectSubscription.unsubscribe(); }
  }

}
