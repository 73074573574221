<div class="main-content">
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="card pointer pink" (click)="goRoute('/question')">
                <img class="background" [src]="'./assets/images/home/activities.png'" [alt]="'Questions image'"/>
                <div class="step-home3 info_container">
                    <span>{{ 'HOME.ACTIVITIES' | translate }}</span>
                    <img class="icon-right" src="./../../assets/icons/caret_right.png" width="12px" />
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div class="card pointer purple" (click)="goRoute('/exam')">
                <img class="background" [src]="'./assets/images/home/exams.png'" [alt]="'Exam image'"/>
                <div class="step-home4 info_container">
                    <span>{{ 'HOME.EXAMS' | translate }}</span>
                    <img class="icon-right" src="./../../assets/icons/caret_right.png" width="12px" />
                </div>
            </div>
        </div>
    </div>
    <div class="ball_wizard pointer" (click)="newEnjoy()" tooltip="{{ 'TOOLTIPS.WIZARD' | translate }}">
        <img style="height: auto;width: 100%;" src="./assets/icons/home/wizard.png" [alt]="'Bars'" />
    </div>
    <div class="ball_manual pointer" (click)="downloadUserManual()">
        <img style="height: auto;width: 100%;" src="./assets/icons/home/manuales.png" [alt]="'Bars'" />
    </div>
    <div id="hide" class="hidden" (click)="saveHideWizard()"></div>
</div>