<div class="login_page animated fadeIn fast">
  <div class="login_top">
    <div class="container-fluid">
      <div class="login_form">
        <div class="loader animated fadeIn" *ngIf="loading">
          <img src="./assets/icons/spinner-solid.svg" />
        </div>
        <div class="d-block" [hidden]="loading">
          <div class="row">
            <div class="col-12 text-center">
              <img class="logo" src="./assets/images/login/logo-login.png" [alt]="'GES Logo'" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <form novalidate (ngSubmit)="login()" [formGroup]="loginForm">
                <div class="input_container">
                  <input type="text" id="username" placeholder="{{ 'LOGIN.USERNAME' | translate }}" formControlName="username" />
                </div>
                <div class="input_container">
                  <input type="password" id="password" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" formControlName="password" />
                </div>
                <br>
                <button type="submit" class="btn_pink pointer">{{ 'LOGIN.ACCESS' | translate }}</button>
                <div id="loginPdf" (mouseenter)="pdfIcon = 'pdf-hover.png'" (mouseleave)="pdfIcon = 'pdf.png'" (click)="openFile()">
                  <img class="pdf-icon" [src]="'./assets/images/login/'+ pdfIcon" alt="PDF" />
                  <div class="pdf-text">{{ "LOGIN.PDF_EVOCACION" | translate }}</div>
                </div>
                <label class="privacy"><span [innerHTML]="'LOGIN.ACCEPT' | translate : {url_use: 'PRIVACY.url_use' | translate, url_policy: 'PRIVACY.url_policy' | translate}"></span></label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>