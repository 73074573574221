// Component Dependencies
import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

// Services
import { QuestionService } from '../../data/services/question.service';
import { UnitService } from '../../data/services/unit.service';
import { ResourceService } from '../../data/services/resource.service';
import { ConfigService } from '../../data/services/config.service';
import { CurriculumService } from '../../data/services/curriculum.service';
import { NotificationService } from '../../data/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../../data/services/commons.service';

// App Config data
import { AppSettings } from '../../app.config';

// Models
import { Question } from '../../data/models/question.model';
import { Unit } from '../../data/models/unit.model';

@Component({
  selector: 'question-item',
  templateUrl: './question-item.component.html',
  styleUrls: ['./question-item.component.css']
})
export class QuestionItemComponent implements OnInit, AfterViewChecked, OnDestroy {
  // Routes
  routes = [
    { label: "Inicio", url: "/home"},
    { label: "Listado de actividades de la prueba", url: "/question"}
  ];

  // Main Data
  selectedRegion: any;
  question: Question;

  // Auxiliar Data
  units: Unit[] = [];ç
  questions: Question[] = [];
  files = [];
  filterArray: any[] = [];
  selectedGroup: any;

  // Competences
  competences: any = [];
  selectedCompetence: any = null;
  competenceDropdown: boolean;
  competenceConfig: any = {
    loading: false,
    search: true,
    param: 'competence'
  };
  competenceOffset = 0;

  // Criterias
  criterias: any = [];
  selectedCriteria: any = null;
  criteriaDropdown: boolean;
  criteriaConfig: any = {
    loading: false,
    search: true,
    param: 'criteria'
  };
  criteriaOffset = 0;

  // Knowledges
  knowledges: any = [];
  selectedKnowledge: any = null;
  knowledgeDropdown: boolean;
  knowledgeConfig: any = {
    loading: false,
    search: true,
    param: 'knowledge',
    field: 'description'
  };
  knowledgeOffset = 0;

  // Curriculum
  questionCurriculumsLoad: boolean = false;
  curriculums: any = [];
  searchCurriculums:any = [];
  selectedCurriculums: any = [];
  addedCurriculums:any = [];
  curriculumModal:boolean =  false;


  // Metadata Selected Data
  configSubscribe;
  subjectMetadata;
  selectedUnit: Unit = null;
  unitDropdown: boolean;
  unitConfig: any = {    
    loading: false,
    prelabel: 'Unidad ',
    field: 'number',
    param: 'unit',
    search: false,
    pagination: false,
    total: 0
  };

  selectedQuestion: Question = null;
  questionDropdown: boolean;
  questionConfig: any = {
    loading: false,
    param: 'question',
    search: true,
    pagination: true,
    total: 0
  };
  questionOffset: number = 0;

  // Preview Modal Variables
  previewShown = false;

  // View Variables
  loading = false;
  loadingModal = false;
  formValidate = null;
  @Input() newQuestion: string;
  @Output() newQuestionAdded: EventEmitter<any> = new EventEmitter();

  apiUrl: string = AppSettings.API_PROD_ENDPOINT;
  imgUrl: string = AppSettings.API_PROD_IMG;

  // TinyMCE Configuration
  mceConfig: any;
  queryArray: any[] = [];
  limit = 7;
  offset = 0;
  sort = 'id';
  dirSort = 'asc';
  page = 1;
  apiKey;

  unitsSubscribe;

  enjoyhint = false;

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifService: NotificationService,
    private questionService: QuestionService,
    private unitService: UnitService,
    private resourceService: ResourceService,
    private curriculumService: CurriculumService,
    private http: HttpClient,
    public translate: TranslateService,
    private config: ConfigService,
    public commons: CommonsService
  ) {
    this.selectedRegion = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).region : null;
    this.apiKey = AppSettings.API_KEY;

    const self = this;
    this.mceConfig = {
      base_url: '/tinymce', // Root for resources
      suffix: '.min',        // Suffix to use when loading resources
      license_key: this.apiKey,
      resize: false,
      inline: false,
      language: 'es',
      plugins: ['image', 'table', 'lists'],
      external_plugins: {
        'tiny_mce_wiris' : `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
      },
      theme_advanced_buttons3_add : 'pastetext, pasteword, selectall',
      toolbar: 'undo redo | image | table | numlist bullist | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
      language_url: '../../../assets/lang/es.js',
      location: this.imgUrl + '/img',
      theme_advanced_resizing: true,
      theme_advanced_resizing_use_cookie : false,
      automatic_uploads: true,
      images_upload_url:  'mcs/file',
      images_upload_base_path: this.apiUrl,
      images_upload_credentials: true,
      images_upload_handler: (blobInfo) => new Promise((resolve, reject) => {
        let file = blobInfo.blob();
        if(file && file.name) {
          const requesttUrl = self.apiUrl + 'mcs/file';
          const body: FormData = new FormData();
          body.append('file', file);
          self.http.post( requesttUrl, body )
          .subscribe(
            (res: any) => {
              resolve(self.imgUrl + res.location);
            },
            (err) => {
              self.notifService.setNotification(this.translate.instant('QUESTIONS.ERROR_IMAGE_IMPORT'), 'error', 6000);
              reject(err);
            }
          );
        } else {
          setTimeout(() => {
            resolve("data:image/svg+xml;base64," + blobInfo.base64());
          }, 1000);
        }

      })
    };
  }

  ngOnInit() {
    this.subjectMetadata = this.config.subjectMetadata;
    this.selectedGroup = this.config.selectedGroup;
    this.configSubscribe = this.config.subjectMetadataUpdated.subscribe((data) => {
      this.subjectMetadata = data;
      this.selectedGroup = this.config.getGroup();
      this.loadData();
    });

    this.loadData();
    this.activatedRoute.params.subscribe( params => {
      if (params.id) {
        if (params.id === 'create' || this.newQuestion === 'modal' ) {
          this.routes.push({
            label: "Creación de nueva actividad",
            url: "/question/create"
          });
          this.question = new Question;
        } else {
          this.routes.push({
            label: "Edición de actividad",
            url: "/question/"+ params.id
          });
          this.getQuestionId(params.id);
        }
      }
    });
  }

  ngAfterViewChecked() {
    if (localStorage.getItem('previusPage').includes('question') && localStorage.getItem('nextStep') === 'true' && !this.enjoyhint) {
      this.enjoyhint = true;
      this.newEnjoy();
    }
  }

  newEnjoy() {
    //event, text, next, hideArrow, shape, timeout, scroll, nextButton
    this.config.enjoyhintSteps = [
      this.config.completeStep('click .step-question4', 'Lo primero que tienes que hacer es elegir la unidad a la que quieres asociar la actividad que vas a crear.', false, false, '', 0),
      this.config.completeStep('click .step-question4 .dropdown_menu', 'Selecciona una unidad.', false, false, '', 0),
      this.config.completeStep('click .knowledge_btn', 'A continuación, añade los Saberes Básicos y el resto de los elementos curriculares con los que deseas que se relacione la nueva actividad.', false, false, '', 0),
      this.config.completeStep('next .hidden-wizard', 'En este listado de saberes básicos, selecciona aquellos con los que vas a evaluar a tu alumnado cuando resuelva la actividad que estás creando.', true, true, '', 0),
      this.config.completeStep('click #accordionSearch .index_counter', 'Haz click aquí para seleccionarlos.', false, false, '', 0),
      this.config.completeStep('click .view_btn', 'Pulsa «Añadir Saberes Básicos» para asociarlos a la actividad.', false, false, '', 0),
      this.config.completeStep('next .step-question5', 'Para el desarrollo de la actividad, inserta el título de esta y, si lo deseas, un texto descriptivo.', true, false, '', 0),
      this.config.completeStep('next .step-question6', 'Dispones de unas cajas de texto, sencillas para poder desarrollar el contenido de la actividad y su solución, teniendo en cuenta que el alumnado no podrá verla.', true, false, '', 0),
      this.config.completeStep('click .step-question6 .tox-toolbar__group:last-of-type .tox-tbtn', 'En el caso de que el contenido que estás trabajando requiera maquetación de formulación, tienes a tu disposición dos editores de formulación, química y matemática.', false, false, '', 0),
      this.config.completeStep('next .wrs_editor.wrs_flexEditor', 'Dibuja o escribe la ecuación o la fórmula.', true, false, '', 1000),
      this.config.completeStep('click .wrs_modal_button_accept', 'Pulsa “Insertar” para guardar la ecuación o la fórmula.', false, false, '', 500),
      this.config.completeStep('next .step-question7', 'Tienes la posibilidad de vincular una actividad con otra anteriormente creada. Esto es muy útil si una actividad por sí sola no tuviera sentido. Por ejemplo, imagínate que en una prueba de evaluación añades esta actividad: «¿Cuál de ellos está más cerca de la Tierra?». Es una actividad que individualmente no tiene sentido, pero, si la vinculas con la actividad que has creado antes: «Escribe el nombre de tres planetas», se mostrarán unidas en la prueba de evaluación. <ul><li>1. Escribe el nombre de tres planetas.</li><li>2. ¿Cuál de ellos está más cerca de la Tierra?.</li></ul>', true, false, '', 0, {class: '.step-question7'}),
      this.config.completeStep('next .step-question8', 'Una vez creada la actividad, puedes previsualizarla para comprobar que se ha creado correctamente.', true, false, '', 0),
      this.config.completeStep('next .step-question9', 'Y, si es correcta, guárdala.', true, false, '', 0),
      this.config.completeStep('next .hidden-wizard', 'Para guardar la actividad es indispensable que los campos de título, unidad y saberes básicos y el resto de los elementos curriculares estén completos.', true, true, '', 0, {class: '.hidden-wizard'}, 'goHome')
    ];
    this.config.newEnjoy();
  }

  changeContent() {
    const iframe: any = document.querySelector('#contentContent iframe');
    const content = iframe.contentWindow.document.getElementById('tinymce').innerHTML;
    this.question.content = content;
  }

  changeAnswer() {
    const iframe: any = document.querySelector('#contentAnswer iframe');
    const content = iframe.contentWindow.document.getElementById('tinymce').innerHTML;
    this.question.answer = content;
  }


  loadData() {
    this.getUnits();
    this.getCurriculums();
    this.getCompetences();
    this.getCriterias();
    this.getKnowledges();
    this.getQuestions();
  }

  getQuestionId(id) {
    this.loading = true;
    this.questionService.getQuestion(id)
    .subscribe( data => {
      this.question = data;
      this.selectedUnit = data.unit;
      this.selectedQuestion = data.parent;
      this.questionCurriculumsLoad = true;
      this.loadData();

      this.loading = false;
    },
    () => {
      this.router.navigate( ['/question'] );
      this.notifService.setNotification(this.translate.instant('QUESTIONS.ACTIVITY_NOT_FOUND'), 'error', 6000);
    }
   );
  }

  getQuestions(queryArray = [], limit = 14) {
    const filters = [];
    filters.push({ param: 'own', value: true });
    if (this.config.selectedGroup.book) {
      filters.push({ param: 'book', value: this.config.selectedGroup.book });
    }
    if (this.selectedRegion) {
      filters.push({ param: 'region', value: this.selectedRegion.id ? this.selectedRegion.id : this.selectedRegion });
    }
    if (this.config.selectedSubject && this.config.selectedSubject.id) {
      filters.push({ param: 'subject', value: this.config.selectedSubject.id });
    }
    if (this.config.selectedGrade && this.config.selectedGrade.id ) {
      filters.push({ param: 'grade', value: this.config.selectedGrade.id });
    }
    if (this.selectedUnit && this.selectedUnit.id) {
      filters.push({param: "unit", value: [this.selectedUnit.id]});
    }
    this.questionConfig.loading = true;
    this.questionService.getGesQuestions(queryArray, filters, this.sort, this.dirSort, limit, this.questionOffset)
    .subscribe(data => {
      this.questions = data.results;
      if(this.question && this.question.id) {
        const index = this.questions.indexOf(this.questions.find(item => {
          return item.id === this.question.id;
        }));
        if(index >= 0) { this.questions.splice(index, 1); }
      }
      this.questionConfig.total = data.metadata.totalResults - 1;
      this.questionConfig.loading = false;
    },
    ()=> {this.questionConfig.loading = false;});
  }

  searchQuestions(event) {
    this.questionOffset = event.page && event.limit ? (event.page * event.limit) - event.limit : 0;
    this.getQuestions(event.query, event.limit);
  }

  getUnits(queryArray = [], filterArray = [], limit = 99, offset = 0 ) {
    if (this.subjectMetadata) {filterArray.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.config.selectedGroup && this.config.selectedGroup.book && this.config.selectedGroup.book > 0) {
      filterArray.push({ param: 'book', value: this.config.selectedGroup.book });
    }
    this.unitConfig.loading = true;
    this.unitsSubscribe = this.unitService.getUnits(queryArray, filterArray, this.sort, this.dirSort, limit, offset)
    .subscribe(data => {
      this.units = data.results;
      this.unitConfig.total = data.metadata.totalResults;
      this.unitConfig.loading = false;
    },
    ()=> {this.unitConfig.loading = false;});
  }

  addQuestion(question: any) {
    this.loading = true;
    let curriculums = [];
    if (this.addedCurriculums.length > 0) {
      curriculums = this.addedCurriculums.map(item => {
        return {id: item.id};
      });
    }
    const newQuestion = {
      'title': question.title ? question.title : null,
      'content': question.content ? question.content : null,
      'answer': question.answer ? question.answer : null,
      'unit': this.selectedUnit && this.selectedUnit.id ? {'id': this.selectedUnit.id} : null,
      'numActivity': question.numActivity ? question.numActivity : 1,
      'parent': this.selectedQuestion && this.selectedQuestion.id ? {'id': this.selectedQuestion.id } : null,
      'curriculums': curriculums
    };
    this.questionService.addGesQuestion(newQuestion)
    .subscribe( data => {
      if (this.newQuestion === 'modal') {
        this.newQuestionAdded.emit(data);
      } else {
        this.router.navigate(['/question']);
      }
      this.loading = false;
    },
    ()=> {this.loading = false;});
  }

  updateQuestion(question: any) {
    this.loading = true;
    let curriculums = [];
    if (this.addedCurriculums.length > 0) {
      curriculums = this.addedCurriculums.map(item => {
        return {id: item.id};
      });
    }
    const updateQuestion = {
      'id': question.id ? question.id : null,
      'title': question.title ? question.title : null,
      'content': question.content ? question.content : null,
      'answer': question.answer ? question.answer : null,
      'unit': this.selectedUnit && this.selectedUnit.id ? {'id': this.selectedUnit.id} : null,
      'numActivity': question.numActivity ? question.numActivity : 1,
      'parent': this.selectedQuestion && this.selectedQuestion.id ? {'id': this.selectedQuestion.id } : null,
      'curriculums': curriculums
    };
    this.questionService.updateGesQuestion(updateQuestion, question.id)
    .subscribe( (data) => {
      if (this.newQuestion === 'modal') {
        this.newQuestionAdded.emit(data);
      } else {
        this.router.navigate( ['/question']);
      }
      this.loading = false;
    },
    ()=> {this.loading = false;});
  }

  /* CURRICULUMS */
  getCurriculums() {
    let filterArray: any = [];
    if (this.subjectMetadata != null) {filterArray.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedKnowledge != null) {filterArray.push({param: 'basicKnowledge', value: this.selectedKnowledge.id}); }
    if (this.selectedCriteria != null) {filterArray.push({param: 'evaluationCriteria', value: this.selectedCriteria.id}); }
    if (this.selectedCompetence != null) {filterArray.push({param: 'specificCompetence', value: this.selectedCompetence.id}); }
    this.curriculumService.getCurriculums(filterArray)
    .subscribe(data => {
      this.curriculums = data.results;
      if(this.question && this.question.id && this.questionCurriculumsLoad) {
        this.selectQuestionCurriculums();
      }
      this.cleanCurriculums();
    });
  }
  selectQuestionCurriculums() {    
    this.addedCurriculums = this.question.curriculums.map(item => {
      const aux = this.curriculums.find(curr => {
        return curr.id == item.id;
      });
      return aux;
    });
    this.questionCurriculumsLoad = false;
  }
  cleanCurriculums() {
    this.loadingModal = true;
    this.searchCurriculums = [];
    let aux: any;
    this.searchCurriculums = this.curriculums.filter(item => {
      aux = this.selectedCurriculums.find(selected => {
        return selected.id === item.id;
      });
      return (aux == null || aux == undefined);
    });

    setTimeout(() => {
      this.loadingModal = false;
    }, 500);
  }
  multiSelectCurriculum(curriculum, add) {
    if (add && this.commons.isInArray(this.searchCurriculums, curriculum, 'id')) {
      this.selectedCurriculums.push(curriculum);
      this.cleanCurriculums();
    } else if (!add && this.commons.isInArray(this.selectedCurriculums, curriculum, 'id')) {
      this.selectedCurriculums.splice(this.commons.getIndexOnArray(this.selectedCurriculums, curriculum, 'id'), 1);
      this.cleanCurriculums();
    }
  }
  saveSelectedCurriculums(save = false) {
    if(save) {
      this.loadingModal = true;
      this.addedCurriculums = JSON.parse(JSON.stringify(this.selectedCurriculums));
    }
    setTimeout(() => {
      this.curriculumModal = false;
      this.selectedCurriculums = [];
      this.selectedCompetence = null;
      this.selectedCriteria = null;
      this.selectedKnowledge = null;
      this.loadingModal = false;
    }, 500);
  }
  openModalCurriculums() {
    this.selectedCurriculums = JSON.parse(JSON.stringify(this.addedCurriculums));
    this.curriculumModal = true;
    this.loadingModal = true;
    this.getCurriculums();
  }

  /* COMPETENCES */
  getCompetences(text = null) {
    this.competenceConfig.loading = true;
    let filters: any = [];
    if (this.subjectMetadata != null) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (text != null && text != "") {filters.push({param: 'text', value: text});} 
    this.curriculumService.getCompetences(filters)
    .subscribe(data => {
      this.competences = data.results;
      this.competenceConfig.loading = false;
    },
    ()=> {this.competenceConfig.loading = false;});
  }
  competenceSelect(competence) {
    this.selectedCompetence = competence;
    this.selectedCriteria = null;
    this.selectedKnowledge = null;
    this.getCriterias();
    this.getKnowledges();
    this.getCurriculums();
  }
  removeSelectedCompetence() {
    this.selectedCompetence = null;
    this.selectedCriteria = null;
    this.selectedKnowledge = null;
    this.getCriterias();
    this.getKnowledges();
    this.getCurriculums();
  }
  searchCompetences(event) {
    this.getCompetences(event.text);
  }

  /* CRITERIAS */
  getCriterias(text = null) {
    this.criteriaConfig.loading = true;
    let filters: any = [];
    if (this.subjectMetadata != null) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedCompetence != null) {filters.push({param: 'specificCompetence', value: this.selectedCompetence.id});} 
    if (text != null && text != "") {filters.push({param: 'text', value: text});} 
    this.curriculumService.getCriterias(filters)
    .subscribe(data => {
      this.criterias = data.results;
      this.criteriaConfig.loading = false;
    },
    ()=> {this.criteriaConfig.loading = false;});
  }
  criteriaSelect(criteria) {
    if (this.selectedCompetence == null) {
      // select competence by criteria
      const competence = this.competences.find(item => {
        return item.id === criteria.specificCompetence.id;
      });
      this.selectedCompetence = competence;
    }
    this.selectedCriteria = criteria;
    this.selectedKnowledge = null;
    this.getKnowledges();
    this.getCurriculums();
  }
  removeSelectedCriteria() {
    this.selectedCriteria = null;
    this.selectedKnowledge = null;
    this.getKnowledges();
    this.getCurriculums();
  }
  searchCriterias(event) {
    this.getCriterias(event.text);
  }
  
  /* KNOWLEDGES */
  getKnowledges(text = null) {
    this.knowledgeConfig.loading = true;
    let filters: any = [];
    if (this.subjectMetadata != null) {filters.push({param: 'subjectMetadata', value: this.subjectMetadata}); }
    if (this.selectedCriteria != null) {filters.push({param: 'evaluationCriteria', value: this.selectedCriteria.id});}
    else if (this.selectedCompetence != null) {filters.push({param: 'specificCompetence', value: this.selectedCompetence.id});}
    if(text != null && text != "") {filters.push({param: 'text', value: text});}
    this.curriculumService.getKnowledges(filters)
    .subscribe(data => {
      this.knowledges = data.results;
      this.knowledgeConfig.loading = false;
    },
    ()=> {this.knowledgeConfig.loading = false;});
  }
  knowledgeSelect(knowledge) {
    const curriculum = this.curriculums.find(item => {
      return item.basicKnowledge.id == knowledge.id;
    });
    if (this.selectedCriteria == null && curriculum) {
      // select criteria by curriculum
      const criteria = this.criterias.find(item =>{
        return item.id == curriculum.evaluationCriteria.id;
      });
      this.selectedCriteria = criteria;
    }
    if (this.selectedCompetence == null && this.selectedCriteria && curriculum) {
      // select competence by curriculum
      const competence = this.competences.find(item => {
        return item.id === curriculum.evaluationCriteria.specificCompetence.id;
      });
      this.selectedCompetence = competence;  
    }
    this.selectedKnowledge = knowledge;
    this.getCurriculums();
  }
  removeSelectedKnowledge() {
    this.selectedKnowledge = null;
    this.getCurriculums();
  }
  searchKnowledges(event) {
    this.getKnowledges(event.text);
  }

  showAccordion(accordion, id) {
    const content = document.querySelector(accordion + " #collapse" + id);
    const header = document.querySelector(accordion + " #heading-" + id + " .btn-link");
    if (content && header) {
      content.classList.toggle("show");
      header.classList.toggle("collapsed");
    }
  }

  validateForm(action, question) {
    if (document.querySelector('body .enjoyhint') != null) {
      return;
    }

    this.formValidate = false;
    if (
      question.title && question.title.length > 0 &&
      this.selectedUnit && this.selectedUnit.id
    ) {
      this.formValidate = true;
    }

    if (action === 'add' && this.formValidate) {
      this.addQuestion(question);
    } else if (action === 'update' && this.formValidate) {
      this.updateQuestion(question);
    } else {
      this.notifService.setNotification(this.translate.instant('MESSAGES.VALIDATE_NOTIF'), 'warning', 6000);
    }
  }

  checkIfIsInArray(array, item, param) {
    if (array) {
      for (const i of array) {
        if (i[param] === item[param]) {
          return true;
        }
      }
      return false;
    }
  }

  // METHOD THAT RETURNS THE INDEX OF AN ITEM IN THE ARRAY
  getIndexOf(array, item, param) {
    let index = 0;
    for (let i = 0; i < array.length; i ++) {
      if (array[i][param] === item[param]) {
        index = i;
      }
    }
    return index;
  }

  ngOnDestroy(): void {
    if(this.configSubscribe) {this.configSubscribe.unsubscribe()}
    if(this.unitsSubscribe) {this.unitsSubscribe.unsubscribe()}
  }
}
