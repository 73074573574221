// Service Dependencies
import { Injectable } from '@angular/core';
// App Config data
import { AppSettings } from '../../app.config';

// Rxjs
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CommonsService {

  apiUrl: string;

  constructor() {
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
  }

  isInArray(array, item, param) {
    for (const i of array) {
      if (i[param] === item[param]) {
        return true;
      }
    }
    return false;
  }

  getIndexOnArray(array, item, param) {
    let index = 0;
    for (let i = 0; i < array.length; i ++) {
      if (array[i][param] === item[param]) {
        index = i;
      }
    }
    return index;
  }

  sortArray(arr, key) {
    return arr.sort(function (a, b) {
      const keyA = a[key];
      const keyB = b[key];
      if (keyA < keyB) { return -1; } else if (keyA > keyB) { return 1; }
      return 0;
    });
  }

  queryStringify(query) {
    let str = '{';
    for (const item of query) {
      if (item.param) {
        str = str.concat('"').concat(item.param).concat('": ');
        if (typeof item.value === 'object') {
          if (item.value.indexOf(' ') >= 0) {
            const val = item.value.split(' ');
            str = str.concat('[').concat(item.value).concat(']');
          } else {
            str = str.concat('[').concat(item.value).concat(']');
            if (item.param2) {
                str = str.concat(',');
                str = str.concat('"').concat(item.param2).concat('": ');
                str = str.concat('[').concat(item.value2).concat(']');
            }
            if (query.indexOf(item) < query.length - 1 && query.length > 1) {
              str = str.concat(', ');
            }
          }
        } else {
          if (typeof item.value === 'string') {
            str = str.concat('"').concat(item.value).concat('"');
          } else {
            str = str.concat(item.value);
          }
          if (query.indexOf(item) < query.length - 1 && query.length > 1) {
            str = str.concat(', ');
          }
        }
      }

    }
    str = str.concat('}');
    return str;
  }

  queryString(query) {
    let str = '{';
    for (const item of query) {
      if (item.param) {
        str = str.concat('"').concat(item.param).concat('": ');
        if (typeof item.value === 'string') {
          str = str.concat('"').concat(item.value).concat('"');
        } else {
          str = str.concat(item.value);
        }
      }
      if (query.indexOf(item) < query.length - 1 && query.length > 1) {
        str = str.concat(', ');
      }
    }
    str = str.concat('}');
    return str;
  }

  ifIsInBookSpecialSubject(item) {
    const array = [69,127,144,157,158,161,162,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,
                  184,185,186,188,190,192,194,196,198,200,202,204,205,207,264,275,276,295,299,384,392,409,417,429,441,452,479,
                  493,507,514,515,518,520,521,532,539,542,546,556,557,561,572,573,577,578,637,641];

    if (item) {
      for (let i = 0; i < array.length; i++) {
        if (item === array[i]) {
          return true;
        }
      }
    }
    return false;
  }

  setObjetiveColor(i) {
    let color = '#f3075f';
    let colorArr = ['#f3075f', '#91217f', '#ff653b', '#F6095F'];
    let count = 0;
    for (let j = 0; j < i ; j ++) {
      count = count + 1;
      if (count >= colorArr.length) { count = 0; }
      color = colorArr[count];
    }
    return color;
  }

  /* Manage Data */
  public extractData(res) {
    const body = res;
    return body || {};
  }

  public handleError(error: HttpErrorResponse) {
    const errMsg = error;
    return throwError(errMsg);
  }

}
