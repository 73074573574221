import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private inj: Injector,
    private translate: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthService);
    if (!request.url.includes('login')) {
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': `Bearer ${auth.getToken()}`,
          'Accept-Language': `${ this.translate.currentLang }`
        }
      });
    }
    return next.handle(request);
  }

}
