import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './data/services/auth-guard.service';
import { RoleGuard } from './data/services/role-guard.service';

// Components
import { HomeComponent } from './home/home.component';
import { ExamComponent } from './exam/exam.component';
import { ExamItemComponent } from './exam/exam-item/exam-item.component';
import { ExamGeneratorComponent } from './exam/exam-generator/exam-generator.component';
import { ExamPreviewComponent } from './exam/exam-preview/exam-preview.component';
import { QuestionComponent } from './question/question.component';
import { QuestionItemComponent } from './question/question-item/question-item.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', redirectTo: 'login',  pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'exam', component: ExamComponent, canActivate: [AuthGuard, RoleGuard],
    data: { permission: { only: ['ROLE_TEACHER'], redirectTo: 'home'}}
  },
  { path: 'exam/generator', component: ExamGeneratorComponent, canActivate: [AuthGuard, RoleGuard],
    data: { permission: { only: ['ROLE_TEACHER'], redirectTo: 'home'}}
  },
  { path: 'exam/:id', component: ExamItemComponent, canActivate: [AuthGuard, RoleGuard],
    data: { permission: { only: ['ROLE_TEACHER'], redirectTo: 'home'}}
  },
  { path: 'exam/:id/preview', component: ExamPreviewComponent, canActivate: [AuthGuard, RoleGuard],
    data: { permission: { only: ['ROLE_TEACHER'], redirectTo: 'home'}}
  },
  { path: 'question', component: QuestionComponent, canActivate: [AuthGuard, RoleGuard],
  data: {permission: { only: ['ROLE_ADMIN', 'ROLE_TEACHER'], redirectTo: 'home'}}},
  { path: 'question/:id', component: QuestionItemComponent, canActivate: [AuthGuard, RoleGuard],
  data: {permission: { only: ['ROLE_ADMIN', 'ROLE_TEACHER'], redirectTo: 'home'}}},
  { path: 'login', component: LoginComponent},
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
