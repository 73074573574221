// Service Dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as FileSaver from 'file-saver';

// Services
import { CommonsService } from './commons.service';

// App Config data
import { AppSettings } from '../../app.config';

// Rxjs
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class GroupService {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) {this.apiUrl = AppSettings.API_PROD_ENDPOINT; }


  getGroups(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/GES/classGroup` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );

  }

  getGroup(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/classGroup` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addGroup( item: any ): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/classGroup`;

    return this.http.post( requesttUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateGroup( item: any, id ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/classGroup` + paramsUrl;

    return this.http.put( requesttUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  deleteGroup(id: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/classGroup/${id}`;
    return this.http.delete(requestUrl, { headers: headers })
      .pipe(
        map(this.common.extractData),
        catchError(this.common.handleError)
      );
  }

}
