<div class="main-content">
  <div class="loader animated fadeIn" *ngIf="loadingAll">
    <img src="./assets/icons/spinner-solid.svg" />
  </div>
  <div *ngIf="!loadingAll && exam" class="exam-container">
    <shared-breadcrumb *ngIf="!loading" [config]="routes"></shared-breadcrumb>
    <!-- TITULO -->
    <div class="title-container">
      <h2 *ngIf="isNewExam">{{ 'EXAMS.ITEM.TITLE_CREATE' | translate }}</h2>
      <h2 *ngIf="!isNewExam">{{ 'EXAMS.ITEM.TITLE_UPDATE' | translate }}</h2>
    </div>
    <!-- CABECERO -->
    <div class="row">
      <div class="col-12">
        <div class="title_section">
          <h3>{{ 'EXAMS.ITEM.HEAD' | translate }}</h3>
        </div>
        <div class="step-exam7 pattern-card">
          <div class="step-exam8 pattern_item box" [ngStyle]="{'padding': !imgPerfil ? '' : 0}">
            <img *ngIf="imgPerfil" class="img-perfil" [src]="getSafeUrl()"/>
            <label *ngIf="!imgPerfil" class="title-box">{{ 'EXAMS.ITEM.LOGO' | translate }}</label>
            <input type="file" name="file" id="file" class="d-none" (change)="onFileChange($event, exam);" >
            <label
              for="file"
              *ngIf="!imgPerfil && exam"
              class="label-upload pointer"
              [ngClass]="{
                'round_btn edit_btn': exam.logo && exam.logo !== null,
                'btn_red': exam.logo === 'error'
              }">
                <span class="btn_pink small" *ngIf="exam.logo === null || !exam.logo">Subir imagen</span>
                <i class="fas fa-spinner fa-spin" *ngIf="exam.logo === 'loadStart'" style="font-size:22px;"></i>
                <i class="fas fa-exclamation" *ngIf="exam.logo === 'error'" style="font-size:22px;"></i>
            </label>
            <a class="btn_delete pointer" tooltip="{{ 'TOOLTIPS.DELETE' | translate }}" *ngIf="imgPerfil" (click)="removeFile(exam)">
              <img class="icon" [src]="'./assets/icons/remove_icon.png'" [alt]="'Remove icon'" />
            </a>
          </div>
          <div class="letters ml-3 mr-3">
            <div class="pattern_item title bg_grey">
              <input class="step-exam9 no_style" type="textarea" placeholder="{{ 'EXAMS.ITEM.NAME_SCHOOL' | translate }}" [maxlength]="50" [(ngModel)]="exam.schoolName">
            </div>
            <div class="title">
              <span>{{ 'EXAMS.ITEM.LASTNAME' | translate }}</span><span class="name-line"></span>
            </div>
            <div class="title">
              <span>{{ 'EXAMS.ITEM.NAME' | translate }}</span><span class="name-line"></span>
            </div>
            <div class="subject-date-container pattern_item no-gutters title bg_grey">
              <div class="subject-column">
                <div *ngIf="exam && exam.area && exam.classGroup; else examArea" class="step-exam10">{{ exam.area + " - " + exam.classGroup.split("-")[0] }}</div>
                <ng-template *ngIf="config.selectedSubject && config.selectedSubject.title && config.selectedGroup && config.selectedGroup.title" #examArea>{{ config.selectedSubject.title + " - " + config.selectedGroup.title.split("-")[0] }}</ng-template>
              </div>
              <div class="date-column">
                {{ 'EXAMS.ITEM.DATE' | translate }}:
                <div class="input_container datepicker">
                  <input type="text" class="pointer" placeholder="--/--/----" bsDatepicker placement="bottom" container="body" [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue', showWeekNumbers: false }" [bsValue]="examDate" (bsValueChange)="dateChange($event)">
                </div>
              </div>
            </div>
          </div>
          <div class="pattern_item box">
            <!-- <label class="title-box">{{ 'EXAMS.ITEM.CALIFICATION' | translate }}</label> -->
          </div>
        </div>
      </div>
    </div>
    <br /><br />
    <!-- INFORMACIÓN GENERAL -->
    <div class="row">
      <div class="col-12" *ngIf="!isNewExam && exam && exam.id">
        <div class="title_section">
          <h3>{{ 'EXAMS.ITEM.EXAM_INFO' | translate }}</h3>
        </div>
        <div class="row justify-content-between">
          <div class="col-auto examtype-selector">
            <div class="index_counter" [ngStyle]="{ 'background': setObjetiveColor(2)}">
            </div>
            <div class="input_container"><input class="exam-title-input" type="text" [(ngModel)]="exam.title" placeholder="{{ 'EXAMS.GENERATOR.EXAM_TITLE' | translate }}" /></div>
          </div>
          <div class="col-auto examtype-selector">
            <div class="index_counter" [ngStyle]="{ 'background': setObjetiveColor(1)}">
            </div>
            <div class="d-flex align-items-center justify-content-start">
              <span>{{ "Unidades: " }}</span>
              <span *ngFor="let unit of units; let i = index;" class="unit" [ngClass]="{'separate': i+1 < units.length, 'active': checkIfIsInArray(exam.units, unit, 'id')}" (click)="changeUnit(unit)">{{ unit.number }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row step-exam11">
      <div class="col-12 d-flex align-items-center justify-content-start">
        <h5 class="mb-0">¿Desea adjuntar la parte del currículo que cubre la prueba?</h5>
        <div class="examtype-selector">
          <div class="pattern_item pointer" (click)="exam.isEvaluable = true">
            <shared-radiobutton
              [check]="exam.isEvaluable"
              [config]="setObjetiveColor(0)">
            </shared-radiobutton>
            <span>Sí</span>
          </div>
          <div class="pattern_item pointer" (click)="exam.isEvaluable = false;">
            <shared-radiobutton
              [check]="!exam.isEvaluable"
              [config]="setObjetiveColor(0)">
            </shared-radiobutton>
            <span>No</span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- 2 COLUMNAS -->
    <div class="row">
      <!-- LISTADO ACTIVIDADES -->
      <div class="step-exam13 col-md-6 card-list-act">
        <div class="title_section mt-3">
          <h3>{{ 'EXAMS.ITEM.ACTIVITIES' | translate }}</h3>
        </div>
        <div class="filters-container step-exam14">
          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="pattern_item d-flex align-items-center pointer" (click)="questionType = 'auto'; getQuestions();">
                <shared-radiobutton
                  [check]="questionType === 'auto'"
                  config="#f3075f">
                </shared-radiobutton>
                <span class="ml-2">{{ 'QUESTIONS.AUTO_ACTIVITIES' | translate }}</span>
              </div>
            </div>
            <div class="col-md-6 mb-2">        
              <div class="pattern_item d-flex align-items-center pointer" (click)="questionType = 'self'; getQuestions();">
                <shared-radiobutton
                  [check]="questionType === 'self'"
                  config="#f3075f">
                </shared-radiobutton>
                <span class="ml-2">{{ 'QUESTIONS.SELF_ACTIVITIES' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-2">
              <div class="input_container search_box">
                <input type="text" [(ngModel)]="searchQuestion" (keyup.enter)="getQuestions()" placeholder="{{ 'EXAMS.ITEM.LOOK_FOR' | translate }}" />
                <div class="input_icon" (click)="getQuestions()">
                  <img class="icon" style="width: 24px;" [src]="'./assets/icons/search_icon.svg'" [alt]="'Search icon'" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="dropdown step-exam14-b" (clickOutside)="unitDropdown = false;">
                <button class="dropdown_btn ellipsis" (click)="unitDropdown = config.checkClickWizard(unitDropdown)">
                  <span *ngIf="!selectedUnit">{{ 'QUESTIONS.SELECT_UNIT' | translate }}</span>
                  <span *ngIf="selectedUnit && !selectedUnit.title">{{ 'QUESTIONS.UNIT' | translate }}: {{ selectedUnit.number }}</span>
                </button>
                <div class="btn_icon" (click)="unitDropdown = !unitDropdown; $event.stopPropagation();">
                  <img class="icon" *ngIf="!selectedUnit && !unitDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                  <img class="icon" *ngIf="!selectedUnit && unitDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                  <img class="icon" *ngIf="selectedUnit" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedUnit = null; unitDropdown= false; getQuestions(); $event.stopPropagation();"/>
                </div>
                <shared-dropdown
                [data]="filterUnits"
                [config]="unitConfig"
                [open]="unitDropdown"
                [itemSelected]="selectedUnit"
                (clickOnItem)="unitSelect($event);"
                (addFilter)="getQuestions()"
                (searchItems)="searchUnits($event)">
                </shared-dropdown>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="dropdown" (clickOutside)="competenceDropdown = false;">
                <button class="dropdown_btn ellipsis" (click)="competenceDropdown = config.checkClickWizard(competenceDropdown); getCompetences()">
                  <span *ngIf="!selectedCompetence">{{ 'QUESTIONS.SELECT_COMPETENCE' | translate }}</span>
                  <span *ngIf="selectedCompetence">{{ selectedCompetence.code + selectedCompetence.title }}</span>
                </button>
                <div class="btn_icon" (click)="competenceDropdown = !competenceDropdown; $event.stopPropagation(); getCompetences()">
                  <img class="icon" *ngIf="!selectedCompetence && !competenceDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                  <img class="icon" *ngIf="!selectedCompetence && competenceDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                  <img class="icon" *ngIf="selectedCompetence" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedCompetence = null; competenceDropdown = false; getQuestions(); $event.stopPropagation();"/>
                </div>
                <shared-dropdown
                [data]="competences"
                [config]="competenceConfig"
                [open]="competenceDropdown"
                (click)="$event.stopPropagation();"
                [itemSelected]="selectedCompetence"
                (clickOnItem)="competenceSelect($event);"
                (addFilter)="getQuestions()"
                (searchItems)="searchCompetences($event)">
                </shared-dropdown>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="dropdown" (clickOutside)="criteriaDropdown = false;">
                <button class="dropdown_btn ellipsis" (click)="criteriaDropdown = config.checkClickWizard(criteriaDropdown); getCriterias()">
                  <span *ngIf="!selectedCriteria">{{ 'QUESTIONS.SELECT_CRITERIA' | translate }}</span>
                  <span *ngIf="selectedCriteria">{{ selectedCriteria.code + selectedCriteria.title }}</span>
                </button>
                <div class="btn_icon" (click)="criteriaDropdown = !criteriaDropdown; $event.stopPropagation(); getCriterias()">
                  <img class="icon" *ngIf="!selectedCriteria && !criteriaDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                  <img class="icon" *ngIf="!selectedCriteria && criteriaDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                  <img class="icon" *ngIf="selectedCriteria" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedCriteria = null; criteriaDropdown = false; getQuestions(); $event.stopPropagation();"/>
                </div>
                <shared-dropdown
                [data]="criterias"
                [config]="criteriaConfig"
                [open]="criteriaDropdown"
                (click)="$event.stopPropagation();"
                [itemSelected]="selectedCriteria"
                (clickOnItem)="criteriaSelect($event);"
                (addFilter)="getQuestions()"
                (searchItems)="searchCriterias($event)">
                </shared-dropdown>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="dropdown" (clickOutside)="knowledgeDropdown = false;">
                <button class="dropdown_btn ellipsis" (click)="knowledgeDropdown = config.checkClickWizard(knowledgeDropdown); getKnowledges()">
                  <span *ngIf="!selectedKnowledge">{{ 'QUESTIONS.SELECT_KNOWLEDGE' | translate }}</span>
                  <span *ngIf="selectedKnowledge">{{ selectedKnowledge.code + ' ' + selectedKnowledge.description }}</span>
                </button>
                <div class="btn_icon" (click)="knowledgeDropdown = !knowledgeDropdown; $event.stopPropagation(); getKnowledges()">
                  <img class="icon" *ngIf="!selectedKnowledge && !knowledgeDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                  <img class="icon" *ngIf="!selectedKnowledge && knowledgeDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                  <img class="icon" *ngIf="selectedKnowledge" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'"(click)="selectedKnowledge = null; knowledgeDropdown = false; getQuestions(); $event.stopPropagation();"/>
                </div>
                <shared-dropdown
                [data]="knowledges"
                [config]="knowledgeConfig"
                [open]="knowledgeDropdown"
                (click)="$event.stopPropagation();"
                [itemSelected]="selectedKnowledge"
                (clickOnItem)="knowledgeSelect($event);"
                (addFilter)="getQuestions()"
                (searchItems)="searchKnowledges($event)">
                </shared-dropdown>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-0" />
        <div class="row" *ngIf="total > 0">
          <div class="col-12 text-left">
            <div class="quantity-block">
              <div class="quantity">
                <span class="count">{{ total }} {{ 'EXAMS.ITEM.ACTIVITIES' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="questions">
              <div class="loader animated fadeIn" *ngIf="loading">
                <img src="./assets/icons/spinner-solid.svg" />
              </div>
              <ul class="table_list" *ngIf="!loading && questions.length > 0">
                <li class="list_item" *ngFor="let question of questions; let i = index;" [ngClass]="{'inter': i % 2}">
                  <div class="table-row">
                    <div class="col-title">
                      {{ question.title }}
                    </div>
                    <div class="col-buttons">
                      <button class="btn_pink small" (click)="previewQuestion(question);">
                        ver
                      </button>
                      <button class="btn_orange small" [disabled]="checkQuestion(question)" (click)="addQuestion(question)">
                        añadir
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <span *ngIf="questions && questions.length <= 0" class="text-center" style="padding: 10px; display: block; margin: 30px;">{{ 'OPTIONS.NON_RESULTS' | translate }}</span>
            </div>
          </div>
        </div>
        <hr class="mb-0" *ngIf="total > 0 && total > questions.length" />
        <div class="row" *ngIf="total > 0 && total > questions.length">
          <div class="col-12 text-right">
            <div class="pagination text-right">
              <pagination
              [totalItems]="total"
              [(ngModel)]="page"
              (pageChanged)="pageChanged($event)"
              [boundaryLinks]="true"
              [itemsPerPage]="limit"
              [maxSize]="5"
              previousText="&lsaquo;"
              nextText="&rsaquo;"
              firstText="&laquo;"
              lastText="&raquo;">
              </pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- ACTIVIDADES SELECCIONADAS -->
      <div class="step-exam12 col-md-6">
        <div class="ml-0 ml-md-3 card-selected-act">
          <div class="title_section">
            <h3>{{ 'EXAMS.ITEM.EXAM' | translate }}</h3>
          </div>
          <div class="exam_content">
            <ul class="table_list" cdkDropList (cdkDropListDropped)="drop($event)">
              <li class="list_item added_item" *ngFor="let question of questionsAdded; let i = index;" cdkDrag>
                <div class="row added-body">
                  <div class="col-12 title-card">
                    <p class="title">
                      {{ question.title }}
                    </p>
                  </div>
                </div>
                <div class="row added-footer">
                  <div class="col-12 d-flex align-items-center">
                    <img class="move-icon" style="width: 25px;" src="./assets/icons/move_icon.svg" />
                    <span class="d-inline-block unit-info">
                      {{ 'EXAMS.UNIT' | translate }} {{ question.unitNumber }}
                    </span>
                    <button class="btn_pink btn_view small ml-auto" (click)="previewQuestion(question);">
                      ver
                    </button>
                    <button class="btn_purple small ml-2" (click)="removeQuestion(question);">
                      borrar
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <br />
          <div class="row no-gutters">
            <div class="col-md-6 px-2">
              <div class="color-white ml-3 pointer" (click)="obtainCurriculum()">{{ "Saberes cubiertos >" }}</div>
            </div>
            <div class="col-md-3 pr-2">
              <button class="btn_grey" (click)="cancelExam();">{{ 'EXAMS.ITEM.CANCEL' | translate }}</button>
            </div>
            <div class="col-md-3">
              <button class="btn_pink btn-create mr-2 view_btn"
                [disabled]="!(questionsAdded && questionsAdded.length > 0 && exam.units && exam.units.length > 0 && exam.title && exam.title != '')"
                (click)="isNewExam ? createExam(exam) : updateExam(exam)">
                {{ 'EXAMS.ITEM.SAVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #questionPreviewContent>
  <div class="question_preview">
    <div class="question_parent" *ngIf="questionPreview.parent">
      <h3>1. {{ questionPreview.parent.title }}</h3>
      <div [innerHtml]="questionPreview.parent.content | safeHtml"></div>
    </div>
    <div class="question_content">
      <h3>{{ (questionPreview.parent ? '2. ' : '1. ') + questionPreview.title }}</h3>
      <div [innerHtml]="questionPreview.content | safeHtml"></div>
    </div>
    <div class="answer_content" *ngIf="questionPreview.answer">
      <h3>{{ 'EXAMS.ITEM.ANSWER' | translate }}: </h3>
      <div [innerHtml]="questionPreview.answer | safeHtml"></div>
    </div>
    <div class="question_criterias">
      <br />
      <h3> {{ 'EXAMS.ITEM.ASSOCIATE_KNOWLEDGES' | translate }}:</h3>
      <div *ngIf="questionPreview.curriculums && questionPreview.curriculums.length > 0" class="accordion curriculums_list" id="accordionSelected">
        <div class="card card-accordion"  *ngFor="let item of questionPreview.curriculums">
          <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
            <div *ngIf="item.basicKnowledge && item.basicKnowledge.code" class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
              <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
              {{ item.basicKnowledge.description }}
            </div>
          </div>                            
          <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
            <div class="card-body">
              <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.specificCompetence"><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
              <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.code"><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="questionPreview.curriculums && questionPreview.curriculums.length == 0">
        {{ 'OPTIONS.NON_RESULTS' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<shared-modal
  *ngIf="questionPreview && previewShown == true"
  (close)="previewShown = false; questionPreview = null;"
  [optClose]="false"
  [scrollable]="true"
  [title]="'EXAMS.ITEM.PREVIEW_QUESTION' | translate"
  [contentTemp]="questionPreviewContent">
</shared-modal>

<ng-template #curriculumModalContent>
  <div class="row">
    <div class="col-12">
      <!-- acordion start -->
      <div *ngIf="selectedCurriculums && selectedCurriculums.length > 0" class="accordion curriculums_list" id="accordionSelected">
          <div class="card"  *ngFor="let item of selectedCurriculums">
            <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
              <div *ngIf="item.basicKnowledge && item.basicKnowledge.code" class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
                <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                {{ item.basicKnowledge.description }}
              </div>
            </div>                            
            <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
              <div class="card-body">
                <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.specificCompetence"><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.code"><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
              </div>
            </div>
          </div>
      </div>
      <!-- accordion end --> 
      <div *ngIf="selectedCurriculums && selectedCurriculums.length == 0" class="text-center">
        {{ 'OPTIONS.NON_RESULTS' | translate }}
      </div>
    </div>
  </div>
<!--   <br />
  <div class="row">
    <div class="col-12">
      <div class="title_section"><h3> {{ 'EXAMS.ITEM.UNCOVERED_KNOWLEDGES' | translate }}:</h3></div>
      <div *ngIf="uncoveredCurriculums && uncoveredCurriculums.length > 0" class="accordion curriculums_list" id="accordionSelected">
          <div class="card"  *ngFor="let item of uncoveredCurriculums">
            <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
              <div *ngIf="item.basicKnowledge && item.basicKnowledge.code" class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
                <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                {{ item.basicKnowledge.description }}
              </div>
            </div>                            
            <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
              <div class="card-body">
                <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.specificCompetence"><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.code"><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
              </div>
            </div>
          </div>
      </div>
      <div *ngIf="uncoveredCurriculums && uncoveredCurriculums.length == 0" class="text-center">
        {{ 'OPTIONS.NON_RESULTS' | translate }}
      </div>
    </div>
  </div> -->
</ng-template>
<!-- <ng-template #curriculumModalFooter>
  <div class="row">
    <div class="col-md-12 text-right">
        <button class="btn_orange view_btn step-exam14" [disabled]="uncoveredCurriculums.length <= 0" (click)="coverCurriculum()">
          {{ 'EXAMS.ITEM.ADD_KNOWLEDGES_BUTTON' | translate }}
        </button>
    </div>
  </div>
</ng-template> -->
  
<shared-modal
*ngIf="curriculumModal == true"
(close)="curriculumModal = false"
[optClose]="false"
[scrollable]="true"
[title]="'EXAMS.PREVIEW.ASSOCIATE_KNOWLEDGES' | translate"
[contentTemp]="curriculumModalContent">
<!-- [contentFooter]="uncoveredCurriculums.length > 0 ? curriculumModalFooter : null"> -->
</shared-modal>

<div class="confirm-overlay animated fadeIn fast" *ngIf="adviceUnit">
  <div class="confirm-box text-center">
    <br />
    <h5 [innerHTML]='"EXAMS.ITEM.UNIT_DELETE_ADVICE" | translate'></h5>
    <br />
    <div class="row">
      <div class="col-md-6">
        <button (click)="deleteUnit = null; adviceUnit = false" class="btn_grey">{{ 'EXAMS.ITEM.CANCEL' | translate }}</button>
      </div>
      <div class="col-md-6">
        <button (click)="removeUnit()"
          class="btn_pink">{{ 'EXAMS.ITEM.CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="confirm-overlay animated fadeIn fast" *ngIf="adviceCantDelete">
  <div class="confirm-box text-center">
    <br />
    <h5 [innerHTML]='"EXAMS.ITEM.UNIT_CANT_DELETE" | translate'></h5>
    <br />
    <div class="row">
      <div class="col-md-6 mx-auto">
        <button class="btn_pink" (click)="adviceCantDelete = false;">{{ 'EXAMS.ITEM.CONFIRM' | translate }}</button>
      </div>
    </div>
  </div>
</div>