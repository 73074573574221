// Service Dependencies
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private notifService: NotificationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.loggedIn()) {
        return true;
      } else {
        setTimeout(() => {
          this.notifService.setNotification(this.translate.instant('ALLOW.LOGIN_REQUIRED'), 'error', 6000);
        }, 100);
        this.router.navigate(['/login']);
        return false;
      }
    }
}
