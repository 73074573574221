<!-- NAVBAR COMPONENT -->
<app-navbar class="navbar" *ngIf="!isFullWidth && showTemplate"></app-navbar>

<!-- NOTIFICATION COMPONENT -->
<app-notification *ngIf="notification.staticNotificationClosed" [notification]="notification.notification" [notificationType]="notification.notificationType" (notificationHide)="notification.staticNotificationClosed = false">
</app-notification>

<!-- MAIN CONTENT -->
<div class="main-body">
    <div class="hidden-wizard"></div>
    <router-outlet />
</div>

<!-- FOOTER -->
<div *ngIf="!isFullWidth && showTemplate" class="footer-container">
  <div class="footer-col-1">
    <a href="{{'PRIVACY.url_policy' | translate}}" target="_blank">{{ "PRIVACY.policy" | translate }}</a>
    <span class="footer-separator mx-2">|</span>
    <a href="{{'PRIVACY.url_use' | translate}}" target="_blank">{{ "PRIVACY.use" | translate }}</a>
    <span class="footer-separator mx-2">|</span>
    <a href="{{'PRIVACY.url_disclosure' | translate}}" target="_blank">{{ "PRIVACY.disclosure" | translate }}</a>
    <span class="footer-separator mx-2">|</span>
    <div class="link-footer" (click)="modalCredits = true">{{ "PRIVACY.credits" | translate }}</div>
  </div>
  <div class="footer-col-2">
    <img class="pointer m-2" style="height: 20px;max-width: 100%;" [routerLink]="['/home']" [src]="'./assets/images/home/logo-color.png'" [alt]="'GES Logo Medium'"/>
    <span>© {{ currentYear }} Sanoma Educación, S.L.U.</span>
  </div>
</div>

<shared-modal
  *ngIf="modalCredits"
  (close)="modalCredits = false"
  [optClose]="false"
  [scrollable]="true"
  [title]="'PRIVACY.credits' | translate"
  [contentTemp]="modalCreditsContent">
</shared-modal>

<ng-template #modalCreditsContent>
  <div [innerHTML]="'PRIVACY.credits_content' | translate"></div>
</ng-template>