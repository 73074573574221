// Component Dependencies
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../../../data/services/commons.service';

// Models
import { DropdownConfig } from '../../../data/models/dropdownConfig.model';

@Component({
  selector: 'shared-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})

export class DropdownComponent implements OnInit, OnChanges {

  // Data Inputs
  @Input() data: any = {};
  @Input() config: DropdownConfig = new DropdownConfig();
  @Input() open: boolean = false;

  // Data Global Variables
  @Input() itemSelected: any;
  filtersSelected: any[] = [];

  // Data Variables
  search: string = '';
  queryArray: any [] = [];
  page: number = 1;
  limit: number = 14;
  loading: boolean = false;
  prelabel: string = '';
  secondary: string = null;

  // Events Outputs
  @Output() clickOnItem: EventEmitter<any> = new EventEmitter();
  @Output() addFilter: EventEmitter<any> = new EventEmitter();
  @Output() searchItems: EventEmitter<any> = new EventEmitter();

  // Constructor
  constructor(
    private translate: TranslateService,
    private common: CommonsService
  ) {}

  // OnInit
  ngOnInit() {
    this.config.pagination = this.config.pagination === false ? this.config.pagination : true;
    this.config.field = this.config.field !== undefined ? this.config.field : 'title';
    this.prelabel = this.config.prelabel && this.config.prelabel.length > 0 ? this.config.prelabel : '';
    this.secondary = this.config.secondary && this.config.secondary.length > 0 ? this.config.secondary : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemSelected) {
      this.filtersSelected = Array.isArray(this.itemSelected) ? this.itemSelected : [];
    }
    if (changes.open && this.page !== 1) {
      this.page = 1;
      this.searchData();
    }
  }

  // METHOD TO SET ITEM SELECTED
  itemSelect(item) {
    if (this.config.multi) {
      this.addToArrayFilter(item);
      this.clickOnItem.emit(this.filtersSelected);
      this.addFilter.emit(this.filtersSelected);
    } else {
      if (this.itemSelected && item.id === this.itemSelected.id && this.config.quit) {
        this.itemSelected = null;
        this.open = false;
      } else {
        this.itemSelected = item;
        this.open = false;
      }
      const filter = {
        value: item,
        param: this.config.param
      };
      this.clickOnItem.emit(this.itemSelected);
      this.addFilter.emit(filter);
    }
  }

  // METHOD TO ADD OR REMOVE ITEM FROM THE ARRAY
  addToArrayFilter(item) {
    if (!this.common.isInArray(this.filtersSelected, item, 'id')) {
      this.filtersSelected.push(item);
    } else {
      this.filtersSelected.splice(this.common.getIndexOnArray(this.filtersSelected, item, 'id'), 1);
    }
  }

  // SEARCH METHOD
  searchData() {
    let obj:any = {};
    if (this.config.param === 'knowledge' || this.config.param === 'criteria' || this.config.param === 'competence') {
      obj = {text: this.search};
    } else {
      const query = [{param: this.config.field, value: this.search}];
      const filter = [];
      obj = {
        query: query,
        filter: filter,
        page: this.page,
        limit: this.limit
      };
    }
    this.searchItems.emit(obj);
  }

  // METHOD TO GO TO NEXT PAGE
  nextPage() {
    this.page = this.page + 1;
    this.searchData();
  }

  // METHOD TO GO TO PREVIOUS PAGE
  prevPage() {
    this.page = this.page - 1;
    this.searchData();
  }
}
