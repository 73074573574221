import { DropdownConfigInterface } from '../interfaces/dropdownConfig.interface';

export class DropdownConfig implements DropdownConfigInterface {
    public search = false;
    public pagination = true;
    public quit = true;
    public total = 0;
    public multi = false;
    public loading = false;
    public innerHtml = false;
    public param = 'param';
    public field = 'title';
    public secondary = null;
    public prelabel = null;
    public extraField = '';
    constructor() {}
}
