import { QuestionInterface } from '../interfaces/question.interface';
import { Language } from './language.model';

export class Question implements QuestionInterface {
  public id: string;
  public title: string;
  public content: string;
  public language: Language;
  public unit: any;
  public master?: string;
  public questionOrder?: any;
  public answer: string;
  public curriculums: any;
  constructor() {}
}
