<div class="main-content">
  <div class="loader animated fadeIn" *ngIf="loading">
    <img src="./assets/icons/spinner-solid.svg" />
  </div>
  <shared-breadcrumb *ngIf="!loading" [config]="routes"></shared-breadcrumb>
  <div class="title-container">
    <h2>{{ 'EXAMS.GENERATOR.TITLE' | translate }}</h2>
  </div>
  <div class="row title-block">
    <div class="col-12">
      <h4>{{ 'EXAMS.GENERATOR.EXAM_TITLE' | translate }}</h4>
    </div>
    <div class="col-md-6">
      <div class="question-selector">
        <div class="step-exam4 input_container">
          <input type="text" [(ngModel)]="exam.title" placeholder="{{ 'EXAMS.GENERATOR.EXAM_TITLE' | translate }}" [ngClass]="{'invalid': formValidate !== null && formValidate === false && (!exam || !exam.title || exam.title.length <= 0)}" />
          <span class="errorValidation" *ngIf="formValidate !== null && formValidate === false && (!exam || !exam.title || exam.title.length <= 0)">* {{'MESSAGES.REQUIRED_FIELD' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="h-100 d-flex align-items-center step-exam3">
        <label class="label-switch">{{ (selectedType ? 'EXAMS.GENERATOR.CREATE_AUTO' : 'EXAMS.GENERATOR.CREATE_ZERO') | translate }}</label>
        <div class="switch pointer" (click)="selectedType ? selectedType = false : selectedType = true;" >
          <span class="slider" [ngClass]="{'active': selectedType}"></span>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
  <div class="row title-block step-exam5">
    <div class="col-md-6">
      <h4>{{ 'EXAMS.GENERATOR.UNIT_BLOCK' | translate }}</h4>
      <div class="unit-selector">
        <div>
          <label class="d-inline-block" style="margin-right: 20px; font-size: 18px;font-weight: 300;color: #6D6D6D;">{{ 'EXAMS.GENERATOR.SELECT_UNIT' | translate }}</label>
          <br />
          <div class="unitSlider" [ngClass]="{'invalid': formValidate !== null && formValidate === false && (unitsSelected.length === 0)}" *ngIf="selectedGroup">
            <ul>
              <li class="unit-item pointer unitSlider-prev" [ngClass]="{'disabled': unitPage <= 1 }" (click)="removeUnitPage();">
                <img class="unit-link" src="./assets/icons/caret_left.png"/>
              </li>
              <ng-container *ngFor="let unit of units | slice:(((unitPage * unitOffset) - unitOffset)):((unitOffset * unitPage)); let i = index;">
                <li
                class="unit-item pointer"
                (click)="addUnit(unit)"
                [ngClass]="{'active': checkIfIsInArray(unitsSelected, unit, 'id')}"
                *ngIf="i <= unitOffset">
                  <span class="unit-link">{{ unit.number }}</span>
                </li>
              </ng-container>
              <li class="unit-item pointer unitSlider-next" (click)="addUnitPage()">
                <img class="unit-link" src="./assets/icons/caret_right.png"/>
              </li>
            </ul>
          </div>
          <span class="errorValidation d-block" *ngIf="formValidate !== null && formValidate === false && (unitsSelected.length === 0)">* {{'MESSAGES.REQUIRED_FIELD' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="selectedType">
      <h4>{{ 'EXAMS.GENERATOR.ACTIVITIES' | translate }}</h4>
      <div class="question-selector">
        <div class="input_container">
          <label class="d-inline-block" style="margin-right: 20px; font-size: 18px;font-weight: 300;color: #6D6D6D;">{{ 'EXAMS.GENERATOR.ACTIVITY_NUMBER' | translate }}</label>
          <input type="number" :max="50" (blur)="changeQuantity()" class="d-inline-block" [(ngModel)]="questionQuantity" [ngClass]="{'invalid': formValidate !== null && formValidate === false && questionQuantity > 50 && (questionQuantity <= 0 || selectedType !== 1)}" />
          <span class="errorValidation d-block" *ngIf="formValidate !== null && formValidate === false && (questionQuantity <= 0 || selectedType !== 1)">* {{'MESSAGES.REQUIRED_FIELD' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <hr class="bold" />
  <div class="row justify-content-end">
    <div class="col-sm-6 col-lg-3">
      <button class="btn_grey mb-2" [routerLink]="['/exam']">{{ 'EXAMS.GENERATOR.CANCEL' | translate }}</button>
    </div>
    <div class="col-sm-6 col-lg-3">
      <button class="step-exam6 btn_pink mb-2" (click)="validateForm(exam)">{{ 'EXAMS.GENERATOR.CONFIRM' | translate }}</button>
    </div>
  </div>
</div>