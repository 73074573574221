// Service Dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as FileSaver from 'file-saver';

// Models
import { Exam } from '../models/exam.model';
import { Question } from '../models/question.model';

// App Config data
import { AppSettings } from '../../app.config';

// Services
import { CommonsService } from './commons.service';

// Rxjs
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ExamService {

  apiUrl: string;

  /* Precharge Questions and Exam */
  automaticQuestions: any;
  questionsPrecharged: any[];
  examGenerated: Exam;


  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) {
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
  }

  getExams(query: any[] = [],
    filter: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0,
    teacher: number,
    classGroup: number
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);

    const paramsUrl = `?teacher=${teacher}&classGroup=${classGroup}` +
      `&query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}` +
      `&limit=${limit}&offset=${offset}`;

    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getExam(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getExamsByPeriod(id, classgroup): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl + '/mark/' + classgroup;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getMarksByPeriod(classgroup, periodType): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/classGroup/` + classgroup + '/periodType/' + periodType + '/marks';

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addExam( item: any ): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/exam`;

    return this.http.post( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateExam( item: any, id ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl;

    return this.http.put( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addExamQuestion(item, idExam) {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/exam/${ idExam }/question`;
    return this.http.post(requesttUrl, body, { headers: headers })
      .pipe(
        map(this.common.extractData),
        catchError(this.common.handleError)
      );
  }

  removeExamQuestion( id ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/examQuestion/${id}`;
    return this.http.delete(requesttUrl, { headers: headers })
      .pipe(
        map(this.common.extractData),
        catchError(this.common.handleError)
      );
  }

  updateMarkStudent( item: any, idMark ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${idMark}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/studentPeriodTypeMark` + paramsUrl;

    return this.http.put( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  newMarkStudent( item: any): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/studentPeriodTypeMark`;

    return this.http.post( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  deleteMarkStudent(idMark ) {
    const paramsUrl = `/${idMark}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/studentPeriodTypeMark` + paramsUrl;
    return this.http.delete(requestUrl, { headers: headers })
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getGradeByExam(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/grade` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers })
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getExamPDFFile(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/GES/examPDF` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers, responseType: 'blob' })
    .pipe(
      map(( res ) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        FileSaver.saveAs(blob, 'examen_' + id + '.pdf');
      }),
      catchError(this.common.handleError)
    );
  }

  getExamWordFile(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/GES/examWord` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers, responseType: 'blob' })
    .pipe(
      map(( res ) => {
        const blob = new Blob([res], { type: 'application/docx' });
        FileSaver.saveAs(blob, 'examen_' + id + '.docx');
      }),
      catchError(this.common.handleError)
    );
  }

  deleteExam(id: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/exam/${id}`;
    return this.http.delete(requestUrl, { headers: headers })
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getExamCriteria(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl + `/criteria`;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getExportFile( filename, classGroup?, exam?, student?): Observable<any> {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = new HttpHeaders().set('X-Auth-Token', `Bearer ${token}`).set('Content-Type', 'application/json');

    let url = this.apiUrl + `mcs/uploadExamStudent?classGroup=${classGroup}`;

    if (exam) {
      url = url.concat(`&exam=${exam}`);
    }

    if (student) {
      url = url.concat(`&student=${student}`);
    }

    return this.http.get(url, { headers: headers, responseType: 'blob' })
    .pipe(
      map(response => {
        const blob = new Blob([response], { type: 'application/zip' });
        FileSaver.saveAs(blob, filename);
      }),
      catchError(this.common.handleError)
    );
  }

  importExamStudent(item: any): Observable<any> {
    const form = new FormData();
    Object.keys(item).forEach(key => form.set(key, item[key]));
    const requestUrl = this.apiUrl + 'mcs/uploadExamStudent';

    return this.http.post(requestUrl, form)
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  postFile(file: any): Observable<any> {
    const requesttUrl = this.apiUrl + `mcs/logo`;
    return this.http.post( requesttUrl, file)
    .pipe(
      map( (res) => {
        return res;
      }),
      catchError(this.common.handleError)
    );
  }

  newExamWeight( item: any): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/criteriaWeight`;

    return this.http.post( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateExamWeight( item: any, id ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/criteriaWeight` + paramsUrl;

    return this.http.put( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getClassGroupCriteriaWeight(classGroup: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const paramsUrl = `/${classGroup}`;
    const requesttUrl = this.apiUrl + `mcs/classgroup` + paramsUrl + `/criteriaWeight`;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getStudentExamQuestionMark(examId: number, studentId: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const paramsUrl = `/${examId}`;
    const paramsUrl2 = `/${studentId}`;
    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl + `/student` + paramsUrl2 + `/examQuestionMark`;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getStudentExamQuestionMarkByQuestion(examId: number, classGroupId: number,questionId: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const paramsUrl = `/${examId}`;
    const paramsUrl2 = `/${classGroupId}`;
    const paramsUrl3 = `/${questionId}`;
    const requesttUrl = this.apiUrl + `mcs/exam` + paramsUrl + `/classGroup` + paramsUrl2 + `/question` + paramsUrl3 + `/examQuestionMark`;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateExamQuestionMark( item: any, id ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/examQuestionMark` + paramsUrl;

    return this.http.put( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  newExamQuestionMark( item: any): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/examQuestionMark`;

    return this.http.post( requesttUrl, body, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }
}
