// Service Dependencies
import { Injectable, EventEmitter } from '@angular/core';

// Services
import { SubjectService } from './subject.service';
import { UnitService } from '../../data/services/unit.service';
import { CommonsService } from './commons.service';

// App Config data
import { AppSettings } from '../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

// jQuery
import $ from 'jquery';

// Enjoyhint
import EnjoyHint from '../../../enjoyHint';

@Injectable()
export class ConfigService {

  apiUrl: string;

  // User Login
  public user: any = {};
  public rol: any;
  public userGroups: any[] = [];

  // Metadata Selected Data
  public selectedSubject: any = {};
  public selectedGroup: any = {};
  public selectedLang: any = null;
  public selectedRegion: any = {};
  public selectedGrade: any = {};

  public subjectMetadata: any;

  public subjectMetadataUpdated: EventEmitter<any> = new EventEmitter();
  public subjectUpdated: EventEmitter<any> = new EventEmitter();
  public groupUpdated: EventEmitter<any> = new EventEmitter();
  public objectUpdated: EventEmitter<any> = new EventEmitter();
  public changeLanguage: EventEmitter<any> = new EventEmitter();

  // Enjoyhint Wizard
  public enjoyhint_instance: any;
  public enjoyhintSteps: any = [];

  constructor(
    private subjectService: SubjectService,
    private unitService: UnitService,
    private http: HttpClient,
    private common: CommonsService
  ) {
    this.getInitialData();
  }

  getInitialData() {
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.rol = this.user ? this.user.rol[0] : null;
  }

  getSelectedTeacher(subject, group) {
    this.subjectUpdated.emit(subject);
    this.groupUpdated.emit(group);
    this.getSubjectMetadata();
  }

  getSubjectMetadata() {
    this.getInitialData();
    this.selectedGrade.id = this.selectedGroup.grade;
    this.selectedRegion.id = this.user.region.id;
    setTimeout(() => {
      this.obtainSubjectMetadataById();
    }, 0);
  }

  obtainSubjectMetadataById() {
    if (this.selectedGroup && this.selectedGroup.subjectMetadata) {
      this.subjectService.getSubjectMetadataById(this.selectedGroup.subjectMetadata)
        .subscribe(data => {
          this.subjectMetadata = data.results.length > 0 ? data.results[0].id : null;
          this.selectedRegion = data.results.length > 0 ? data.results[0].region : null;
          localStorage.setItem('subjectMetadata', JSON.parse(this.subjectMetadata));
          this.subjectMetadataUpdated.emit(this.subjectMetadata);
          return this.subjectMetadata;
        });
    }
  }

  updateClassGroups() {
    this.subjectUpdated.emit(this.selectedSubject);
  }

  getGroupSubjectMetadata(region, grade, subject) {
    this.subjectService.getSubjectMetadata(region, grade, subject)
    .subscribe(data => {
      this.subjectMetadata = data.results.length > 0 ? data.results[0].id : null;
      this.selectedRegion = data.results.length > 0 ? data.results[0].region : null;
      localStorage.setItem('subjectMetadata', JSON.parse(this.subjectMetadata));
      this.subjectMetadataUpdated.emit(this.subjectMetadata);
    });
  }

  getSubject() {
    return this.selectedSubject;
  }

  getGroup() {
    return this.selectedGroup;
  }

  getGrade() {
    return this.selectedGrade;
  }

  getRegion() {
    return this.selectedRegion;
  }

  getLanguage(lang) {
    this.changeLanguage.emit(lang);
  }

  newEnjoy() {
    if (document.querySelector("body.on-enjoy")) {
      return;
    }
    this.enjoyhint_instance = new EnjoyHint({
      onStart: function() {
        if(localStorage.getItem("nextStep") != 'end') {
          localStorage.setItem('nextStep', 'false');
        }
      },
      onEnd: function() {
        if (localStorage.getItem('nextStep') === 'end') {
          localStorage.setItem('nextStep', 'false');
        } else {
          localStorage.setItem('nextStep', 'true');
        }
      },
      onSkip: function() {
        localStorage.setItem('nextStep', 'false');
        localStorage.removeItem("wizardQuestions");
        localStorage.removeItem("examGenerated");
        localStorage.removeItem("exam");
        location.hash = '#/home';
      }
    });
    this.enjoyhint_instance.set(this.enjoyhintSteps);
    this.enjoyhint_instance.run();
    this.enjoyhintSteps = [];
  }

  completeStep(event, text, next, hideArrow, shape, timeout, scroll:any = {}, nextButtonClass = '', closeModal = false) {
    const obj = {
      nextButton: { text: nextButtonClass === "Fin" ? "Fin" : 'Siguiente', className: nextButtonClass },
      skipButton : { text: '¡Salir!' },
      timeout: timeout,
      showNext: next,
      showSkip: false,
      shape: shape,
      margin: 0,
      keyCode: event.includes('key') ? 13 : null,
      onBeforeStart: function () {
        if (nextButtonClass === "goHome") {
          setTimeout(() => {
            $(".enjoyhint_next_btn.goHome")?.on("click", (e) => {
              location.hash = '#/home';
              $(this).off("click");
            });
          }, timeout + 600);
        }
        if (closeModal) {
          $(".close-icon")?.trigger("click");
        }
        if (scroll && scroll.class) {
          const element = document.querySelector(scroll.class);
          if (element) {
            element.scrollIntoView(scroll.type ? true : false);
          }
        }
        if (hideArrow) {
          setTimeout(function() {
            $('.enjoyhint_svg_wrapper').addClass('hidden');
          }, 250);
        } else if ($('.enjoyhint_svg_wrapper').hasClass('hidden')) {
          setTimeout(function() {
            $('.enjoyhint_svg_wrapper').removeClass('hidden');
          }, 250);
        }
      }
    };

    obj[event] = text;
    return obj;
  }
  
  saveHideWizard(item) {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/users/` + this.user.id;

    return this.http.put( requesttUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  checkClickWizard(obj) {
    if (document.querySelector('body .enjoyhint') === null) {
      return !obj;
    }

    return true;
  }

  exitWizard(){
    this.enjoyhint_instance.trigger("skip");
  }
}
