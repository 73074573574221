// Service Dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { CommonsService } from './commons.service';

import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// App Config data
import { AppSettings } from '../../app.config';

interface Credentials {
  username: string;
  password: string;
}

@Injectable()
export class AuthService {

  helper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private router: Router,
    private common: CommonsService
  ) {

   }

  public apiUrl: string =  AppSettings.API_PROD_ENDPOINT;

  getToken() {
    if (localStorage.getItem('session')) {
      return JSON.parse(localStorage.getItem('session')).token;
    }
  }

  login(credentials: Credentials) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const body = JSON.stringify(credentials);
    const loginUrl = this.apiUrl + 'mcs/login';

    return this.http.post( loginUrl, body, { headers: headers } )
    .pipe(
      map((res) => {
        localStorage.clear();
        localStorage.setItem('session', JSON.stringify(res));
        return res;
      }),
      catchError(this.handleError)
    );
  }

  ping() {
    return this.http.get(this.apiUrl)
    .pipe(
      map(res => {
        return res;
      }),
      catchError(this.common.handleError)
    );
  }

  private extractData(res) {
    const body = res;
    return body || {};
  }

  private handleError(error) {
    const errMsg = error;
    return throwError(errMsg);
  }
  public loggedIn() {
    const token2 = this.helper.tokenGetter();
    const token = localStorage.getItem('token');
    if (!token) { return false; }

    return this.helper.isTokenExpired(token) ? false : true;
  }

  public logout() {
    localStorage.clear();

    this.router.navigate( ['/login'] );
  }

}
