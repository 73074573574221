// Service Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


// Services
import { CommonsService } from './commons.service';

// App Config data
import { AppSettings } from '../../app.config';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class SubjectService {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) {this.apiUrl = AppSettings.API_PROD_ENDPOINT; }

  getSubjects(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filter=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/subject` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getSubjectById(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/subject` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getTeacherSubjects(
    filter: any[] = [],
    query: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/GES/subject/teacher` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getSubjectMetadata(region, grade, subject, lang?) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let paramsUrl = `?filters={"grade":${grade.id},"region":${region.id},"subject":${subject.id}}`;
    if (lang !== null) {
      paramsUrl = `?filters={"grade":${grade.id},"region":${region.id},"subject":${subject.id},"language":${lang}}`;
    }

    const requesttUrl = this.apiUrl + `mcs/subjectMetadata` + paramsUrl;
    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map( (res: any) => res),
      catchError(this.common.handleError)
    );
  }

  getSubjectMetadataById(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/subjectMetadata?filters={"id":${id}}`;

    return this.http.get( requesttUrl, {headers: headers} )
    .pipe(
      map( (res: any) => res),
      catchError(this.common.handleError)
    );
  }
}
