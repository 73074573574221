// Service Dependencies
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {

  public notification: string;
  public staticNotificationClosed = false;
  public notificationType: string;

  constructor() {}

  public setNotification(notification, notificationType, time = 15000) {
    this.staticNotificationClosed = true;
    this.notification = notification;
    this.notificationType = notificationType;
    setTimeout(() => this.staticNotificationClosed = false, time);
  }

  public checkNotification() {
    return this.staticNotificationClosed;
  }
}
