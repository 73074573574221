// Service Dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as FileSaver from 'file-saver';

// Models
import { Question } from '../models/question.model';
import { Exam } from '../models/exam.model';

// App Config data
import { AppSettings } from '../../app.config';

// Services
import { CommonsService } from './commons.service';

// Rxjs
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class QuestionService {

  apiUrl: string;
  apiExport: string;

  // Generated Random Questions
  examGerated: Exam;
  randomQuestions: Question[];

  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) {
      this.apiUrl = AppSettings.API_PROD_ENDPOINT;
      this.apiExport = AppSettings.API_IMPORT;
    }


  getQuestions(
    query: any[] = [],
    filter: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);

    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/question` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getQuestionsEso(
    query: any[] = [],
    filter: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0,
    classGroupId
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);

    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/eso/${classGroupId}/question` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getQuestion(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requestUrl = this.apiUrl + `mcs/question` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getGesRandomQuestions(classGroupId: number, units: string, limit: number ) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let paramsUrl = `?filters={`;
    paramsUrl = units.length > 0 ? paramsUrl.concat(`"units":[${units}]`) : paramsUrl.concat('');
    paramsUrl = paramsUrl.concat(`}&limit=${limit}`);
    const requestUrl = this.apiUrl + `mcs/GES/${classGroupId}/randomQuestion` + paramsUrl;
    return this.http.get( requestUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getAdjustmentQuestions(query: any[] = [],
    filter: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);

    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/question` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addQuestion( item: any ): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/question`;

    return this.http.post( requestUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addAdminQuestion(item: any, model: any, unit: any, book: any, grade: any, subject: any, regions: any[] ) {
    let regs = '';
    if (regions && regions.length > 0) {
      for (let i = 0; i < regions.length; i++) {
        regs = regs.concat(regions[i].id);
        if (i < (regions.length - 1)) {
          regs = regs.concat(',');
        }
      }
    }
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl +
      `mcs/adminQuestion?modelType=${model}&unitNumber=${unit}&subject=${subject}&grade=${grade}&book=${book}&regions=${regs}`;
    return this.http.post( requestUrl, body, {headers: headers} )
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  updateQuestion( item: any, id ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/question` + paramsUrl;

    return this.http.put( requestUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateAdminQuestion( item: any, id: any) {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/adminQuestion/${id}`;
    return this.http.put( requestUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  deleteAdminQuestion( id: any, books: any[] ) {
    let booksStr = '';
    if (books && books.length > 0) {
      for (let i = 0; i < books.length; i++) {
        booksStr = booksStr.concat(books[i].id);
        if (i < (books.length - 1)) {
          booksStr = booksStr.concat(',');
        }
      }
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/adminQuestion/${id}?book=${booksStr}`;
    return this.http.delete( requestUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  deleteQuestion(id: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/question/${id}`;
    return this.http.delete(requestUrl, { headers: headers })
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  getQuestionCriteria(id, classGroupId): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/eso/${classGroupId}/question` + paramsUrl + `/criteria`;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }


  // Clonado
  cloneQuestions(book: any, questions: any[], regions: any[] ) {
    let regs = '';
    let quest = '';
    if (regions && regions.length > 0) {
      for (let i = 0; i < regions.length; i++) {
        regs = regs.concat(regions[i]);
        if (i < (regions.length - 1)) {
          regs = regs.concat(',');
        }
      }
    }
    if (questions && questions.length > 0) {
      for (let i = 0; i < questions.length; i++) {
        quest = quest.concat(questions[i]);
        if (i < (questions.length - 1)) {
          quest = quest.concat(',');
        }
      }
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl +
      `mcs/question/clone?book=${book}&question=${quest}&region=${regs}`;
    return this.http.post( requestUrl, null, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getExportPDFFile( grade, subject, book, region, filename ): Observable<any> {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = new HttpHeaders().set('X-Auth-Token', `Bearer ${token}`).set('Content-Type', 'application/json');

    const url = this.apiUrl + `mcs/teacherQuestionExport?grade=${grade}&subject=${subject}&book=${book}&region=${region}`;
    return this.http.get(url, { headers: headers, responseType: 'blob' })
    .pipe(
      map(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, filename);
      }),
      catchError(this.common.handleError)
    );
  }

  /* GES Methods */  
  getGesQuestions(
    query: any[] = [],
    filter: any[] = [],
    order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);

    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/GES/question` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }
  
  getGesQuestion(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requestUrl = this.apiUrl + `mcs/GES/question` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addGesQuestion( item: any ): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/GES/question`;

    return this.http.post( requestUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateGesQuestion( item: any, id ): Observable<any> {
    const body = JSON.stringify(item);
    const paramsUrl = `/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/GES/question` + paramsUrl;

    return this.http.put( requestUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  /* Manage Data */
  private extractData(res) {
    const body = res;
    return body || {};
  }

  private handleError(error) {
    const errMsg = error;
    return throwError(errMsg);
  }
}
