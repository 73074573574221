// Service Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


// Services
import { CommonsService } from './commons.service';

// App Config data
import { AppSettings } from '../../app.config';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class GradeService {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) { this.apiUrl = AppSettings.API_PROD_ENDPOINT; }

  getGrades(filter: any[] = []): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?filters=${filterString}`;
    const requesttUrl = this.apiUrl + `mcs/grade` + paramsUrl;

    return this.http.get(requesttUrl, { headers: headers })
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

}
