// Service Dependencies
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private notifService: NotificationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let canActivate: boolean;
        const userRole: string = JSON.parse(localStorage.getItem('session')).user.rol[0];
        const permission = route.data['permission'];

        if (!permission) { this.notifService.setNotification(this.translate.instant('ALLOW.NO_PERMISSION'), 'error', 6000); }
        if (!permission.only.length) { this.notifService.setNotification(this.translate.instant('ALLOW.NO_PERMISSION'), 'error', 6000); }

        canActivate = permission.only.includes(userRole);

        if (!canActivate) {
          this.notifService.setNotification(this.translate.instant('ALLOW.NO_PERMISSION'), 'error', 6000);
          this.router.navigate([permission.redirectTo]);
        }

        return canActivate;
    }

}
