<div class="dropdown_menu" *ngIf="open">
  <div class="search_bar" *ngIf="config.search">
    <div class="input_container search_box">
      <input type="text" [(ngModel)]="search" (keyup.enter)="searchData()" placeholder="{{ 'DROPDOWN.SEARCH' | translate }}"/>
      <div class="input_icon" (click)="searchData()">
        <img class="icon input_icon_img"  [src]="'./assets/icons/search_icon.svg'" [alt]="'Search icon'" />
      </div>
    </div>
  </div>
  <div class="drop_own_style" *ngIf="config.loading">
    <div class="loader animated fadeIn">
      <img src="./assets/icons/spinner-solid.svg" />
    </div>
  </div>
  <ul *ngIf="!config.loading">
    <li
    class="filter-item pointer"
    *ngFor="let item of data; let i = index;"
    (click)="itemSelect(item);"
    [ngClass]="{'active': itemSelected && itemSelected.id === item.id || filtersSelected.length > 0 && common.isInArray(filtersSelected, item, 'id') }">
      <div class="index_counter" *ngIf="(item.number || item.id) && !config.innerHtml">
        <span *ngIf="item.number && (config.param !== 'report' && config.param !== 'unitOwn')">{{ item.number }}</span>
        <span *ngIf="item.number && (config.param === 'report' || config.param === 'unitOwn')">{{ i + 1 }}</span>
        <span *ngIf="!item.number && config.param !== 'student' && config.param !== 'competence' && config.param !== 'criteria' && config.param !== 'knowledge'">{{  ((i + 1) + ((limit * page) - limit)) }}</span>
        <span *ngIf="!item.number && config.param === 'student' && (i+1) < 10">0{{  ((i + 1) + ((limit * page) - limit)) }}.</span>
        <span *ngIf="!item.number && config.param === 'student' && (i+1) >= 10">{{  ((i + 1) + ((limit * page) - limit)) }}</span>
        <span *ngIf="!item.number && (config.param === 'competence' || config.param === 'criteria' || config.param === 'knowledge' )">{{ item.code }}</span>
      </div>
      <span *ngIf="config.innerHtml" [innerHTML]="item[config.field]"></span>
      <span *ngIf="!config.innerHtml">{{ config.param !== 'unitOwn' ? prelabel + ' ' : '' }}{{ item[config.field] }} {{ item[config.extraField] }}</span>
      <small class="index_counter_small">{{ item[config.secondary] }}</small>
    </li>
  </ul>
  <div class="drop_pager" *ngIf="config.total > limit && !config.loading && config.pagination">
    <div class="row no-gutters">
      <div class="col text-left">
        <button (click)="prevPage()" [disabled]="page <= 1">&lsaquo;</button>
      </div>
      <div class="col text-center">
        <span class="page">{{ page }}</span>
      </div>
      <div class="col text-right">
        <button (click)="nextPage()" [disabled]="config.total <= limit * page">&rsaquo;</button>
      </div>
    </div>
  </div>
  <div class="drop_pager_style" *ngIf="data && data.length <= 0">
    <strong class="text-center drop_pager_strong">
      {{ 'MESSAGES.NON_RESULTS'| translate }}
    </strong>
  </div>
</div>
