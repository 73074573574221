// Component Dependencies
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.css']
})

export class RadiobuttonComponent implements OnInit {

  // Data Inputs
  @Input() check: boolean = false;
  @Input() config: any = {};

  // Metadata Selected Data
  color: any = {};

  // Events Outputs
  @Output() clickOnItem: EventEmitter<any> = new EventEmitter();

  // Constructor
  constructor(
    private translate: TranslateService
  ) {}

  // OnInit
  ngOnInit() {
    this.color = this.config;
  }

  // METHOD THAT EMITS THE SELECTED ITEM
  itemSelect(ckeck) {
    this.clickOnItem.emit(ckeck);
  }

}
