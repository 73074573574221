import { environment } from '../environments/environment';

export class AppSettings {

  /*  API URL Constant */
  public static API_PROD_ENDPOINT = environment.baseUrl;
  public static API_IMPORT = environment.baseImportUrl;
  public static API_PROD_IMG = environment.imgUrl;
  public static API_KEY = environment.tinymceApiKey;

  /* Session locale */
  public static SESSION = JSON.parse(localStorage.getItem('session'));

}
