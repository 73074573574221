<div class="main-content">
  <div class="loader animated fadeIn" *ngIf="loading">
    <img src="./assets/icons/spinner-solid.svg" />
  </div>
  <shared-breadcrumb *ngIf="!loading" [config]="routes"></shared-breadcrumb>
  <div class="title-container">
    <h2 *ngIf="question && !question.id">{{ 'QUESTIONS.CREATION_OF_NEW_QUESTION' | translate }}</h2>
    <h2 *ngIf="question && question.id">{{ 'QUESTIONS.EDITION_OF_QUESTION' | translate }}</h2>
  </div>
  <div class="form_wrapper" *ngIf="!loading">
    <!-- Title - Header -->
    <div class="form-container">
      <div class="row">
        <div class="col-12">
          <div class="question_context" *ngIf="question">
            <div class="row">
              <div class="col-12">
                <div class="title-section">
                  <div class="index_counter" [ngStyle]="{ 'background': commons.setObjetiveColor(0)}"></div>
                  <h4>{{ 'QUESTIONS.DEFINE_CONTEXT_OF_THE_QUESTION' | translate }}</h4>
                </div>
              </div>
            </div>
            <br />
            <div class="filters-container">
              <div class="row">
                <div class="col-md-6">
                  <div class="step-question4 dropdown" (clickOutside)="unitDropdown = false;">
                    <button class="dropdown_btn ellipsis" (click)="unitDropdown = config.checkClickWizard(unitDropdown)" [ngClass]="{'invalid': formValidate !== null && formValidate === false && (!selectedUnit || !selectedUnit.id )}">
                      <span *ngIf="!selectedUnit">{{ 'QUESTIONS.SELECT_UNIT' | translate }}</span>
                      <span *ngIf="selectedUnit && !selectedUnit.title">{{ 'QUESTIONS.UNIT' | translate }}: {{ selectedUnit.number }}</span>
                      <span *ngIf="selectedUnit && selectedUnit.title">{{ 'QUESTIONS.CONTENT' | translate }}: {{ selectedUnit.title }}</span>
                    </button>
                    <span class="errorValidation" *ngIf="formValidate !== null && formValidate === false && (!selectedUnit || !selectedUnit.id )">* {{ 'MESSAGES.REQUIRED_FIELD' | translate }}</span>
                    <div class="btn_icon" (click)="unitDropdown = !unitDropdown; $event.stopPropagation();">
                      <img class="icon" *ngIf="!selectedUnit && !unitDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                      <img class="icon" *ngIf="!selectedUnit && unitDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                      <img class="icon" *ngIf="selectedUnit" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedUnit = null;selectedQuestion = null;$event.stopPropagation();"/>
                    </div>
                    <shared-dropdown
                    [data]="units"
                    [config]="unitConfig"
                    [open]="unitDropdown"
                    [itemSelected]="selectedUnit"
                    (clickOnItem)="selectedUnit = $event">
                    </shared-dropdown>
                  </div>
                </div>
                <div class="col-md-6">
                  <button class="btn_pink knowledge_btn" (click)="openModalCurriculums();" style="margin-bottom: 0px;">{{ addedCurriculums.length > 0 ? ('QUESTIONS.SEE_AND_UPDATE' | translate) : ('QUESTIONS.ADD_KNOWLEDGES_BUTTON' | translate) }}</button>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-12 text-right">
                  <p class="totalKnowledge"><strong>{{ addedCurriculums.length }}</strong> {{ 'QUESTIONS.KNOWLEDGES_ACTIVITY' | translate }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="question_content" *ngIf="question">
            <div class="row">
              <div class="col-12">
                <div class="title-section">
                  <div class="index_counter" [ngStyle]="{ 'background': commons.setObjetiveColor(1)}"></div>
                  <h4>{{ 'QUESTIONS.DEVELOPMENT_OF_THE_QUESTION' | translate }}</h4>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12">
                <div class="input_container">
                  <input type="text" class="step-question5 title" [(ngModel)]="question.title" placeholder="{{ 'QUESTIONS.WRITE_QUESTION_TITLE' | translate }}"  [ngClass]="{'invalid': formValidate !== null && formValidate === false && (!question.title || question.title.length <= 0)}" />
                  <span class="errorValidation" *ngIf="formValidate !== null && formValidate === false && (!question.title || question.title.length <= 0)">* {{ 'MESSAGES.REQUIRED_FIELD' | translate }}</span>
                </div>
              </div>
            </div>
            <br />
            <div class="row" *ngIf="subjectMetadata">
              <div class="col-12">
                <div class="editor_wrap" id="contentContent">
                  <editor
                    [apiKey]="apiKey"
                    [(ngModel)]="question.content" 
                    [init]="mceConfig"></editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="answer_content" *ngIf="question">
            <div class="row">
              <div class="col-12">
                <div class="title-section">
                  <div class="index_counter" [ngStyle]="{ 'background': commons.setObjetiveColor(2)}"></div>
                  <h4>{{ 'QUESTIONS.DEVELOPMENT_OF_THE_ANSWER' | translate }}</h4>
                </div>
              </div>
            </div>
            <br />
            <div class="row" *ngIf="subjectMetadata">
              <div class="col-12">
                <div class="step-question6 editor_wrap" style="margin-top: 66px;" id="contentAnswer">
                  <editor
                    [apiKey]="apiKey"
                    [(ngModel)]="question.answer"
                    [init]="mceConfig"></editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="question_parent" *ngIf="question">
            <div class="row">
              <div class="col-12">
                <div class="title-section">
                  <div class="index_counter" [ngStyle]="{ 'background': commons.setObjetiveColor(3)}"></div>
                  <h4>{{ 'QUESTIONS.ASSIGN_A_QUESTION' | translate }}</h4>
                </div>
              </div>
            </div>
            <br />
            <div class="step-question7 row">
              <div class="col-12">
                <div class="card">
                  <p>
                    {{ 'QUESTIONS.DESCRIPTION_OF_ASSIGN_A_QUESTION' | translate }}
                  </p>
                  <div class="dropdown" (clickOutside)="questionDropdown = false;" [ngClass]="{'disabled': !selectedUnit}">
                    <button class="dropdown_btn ellipsis" (click)="questionDropdown = config.checkClickWizard(questionDropdown)">
                      <span *ngIf="!selectedQuestion">{{ 'QUESTIONS.SELECT_QUESTION' | translate }}</span>
                      <span *ngIf="selectedQuestion">{{ selectedQuestion.title }}</span>
                    </button>
                    <div class="btn_icon" (click)="questionDropdown = !questionDropdown; $event.stopPropagation();">
                      <img class="icon" *ngIf="!selectedQuestion && !questionDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                      <img class="icon" *ngIf="!selectedQuestion && questionDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                      <img class="icon" *ngIf="selectedQuestion" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedQuestion = null;$event.stopPropagation();"/>
                    </div>
                    <shared-dropdown
                    [data]="questions"
                    [config]="questionConfig"
                    [open]="questionDropdown"
                    (click)="$event.stopPropagation()"
                    [itemSelected]="selectedQuestion"
                    (clickOnItem)="selectedQuestion = $event"
                    (searchItems)="searchQuestions($event)">
                    </shared-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="col-md-6">
          <div class="question_actions" *ngIf="question">
            <div class="row">
              <div class="col-12">
                <div class="title-section"><h4></h4></div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12">
                <button class="step-question8 btn_purple" style="margin: 17px 0px;" (click)="changeContent(); changeAnswer(); previewShown = true" [disabled]="!question.title || question.title.length <= 0">{{ 'QUESTIONS.PREVIEW_BUTTON' | translate }}</button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <button class="btn_grey" [routerLink]="['/question']">{{ 'QUESTIONS.DISCARD_BUTTON' | translate }}</button>
              </div>
              <div class="col-lg-6">
                <button *ngIf="question && !question.id" class="step-question9 btn_pink" (click)="changeContent(); changeAnswer(); validateForm('add', question)">{{ 'QUESTIONS.SAVE_QUESTION_BUTTON' | translate }}</button>
                <button *ngIf="question && question.id" class="step-question9 btn_pink" (click)="changeContent(); changeAnswer(); validateForm('update', question)">{{ 'QUESTIONS.SAVE_QUESTION_BUTTON' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #curriculumModalContent>  
  <div class="row">
    <div class="col-md-4">
      <div class="dropdown" (clickOutside)="competenceDropdown = false;">
        <button class="dropdown_btn ellipsis" (click)="competenceDropdown = !competenceDropdown;">
            <span *ngIf="!selectedCompetence">{{ 'QUESTIONS.SELECT_COMPETENCE' | translate }}</span>
            <span *ngIf="selectedCompetence">{{ selectedCompetence.code + ' ' + selectedCompetence.title }}</span>
        </button>
        <div class="btn_icon" (click)="competenceDropdown = !competenceDropdown; $event.stopPropagation();">
            <img class="icon" *ngIf="!selectedCompetence && !competenceDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
            <img class="icon" *ngIf="!selectedCompetence && competenceDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
            <img class="icon" *ngIf="selectedCompetence" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="removeSelectedCompetence()"/>
        </div>
        <shared-dropdown [data]="competences" [config]="competenceConfig" [open]="competenceDropdown" [itemSelected]="selectedCompetence" (clickOnItem)="competenceSelect($event);" (searchItems)="searchCompetences($event)">
        </shared-dropdown>
      </div>
    </div>
    <div class="col-md-4">
        <div class="dropdown" (clickOutside)="criteriaDropdown = false;">
            <button class="dropdown_btn ellipsis" (click)="criteriaDropdown = !criteriaDropdown;">
                <span *ngIf="!selectedCriteria">{{ 'QUESTIONS.SELECT_CRITERIA' | translate }}</span>
                <span *ngIf="selectedCriteria">{{ selectedCriteria.code + ' ' + selectedCriteria.title }}</span>
            </button>
            <div class="btn_icon" (click)="criteriaDropdown = !criteriaDropdown; $event.stopPropagation();">
                <img class="icon" *ngIf="!selectedCriteria && !criteriaDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                <img class="icon" *ngIf="!selectedCriteria && criteriaDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                <img class="icon" *ngIf="selectedCriteria" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="removeSelectedCriteria()"/>
            </div>
            <shared-dropdown [data]="criterias" [config]="criteriaConfig" [open]="criteriaDropdown" [itemSelected]="selectedCriteria" (clickOnItem)="criteriaSelect($event);" (searchItems)="searchCriterias($event)">
            </shared-dropdown>
        </div>
        
    </div>
    <div class="col-md-4">
        <div class="dropdown" (clickOutside)="knowledgeDropdown = false;">
            <button class="dropdown_btn ellipsis" (click)="knowledgeDropdown = !knowledgeDropdown;">
                <span *ngIf="!selectedKnowledge">{{ 'QUESTIONS.SELECT_KNOWLEDGE' | translate }}</span>
                <span *ngIf="selectedKnowledge">{{ selectedKnowledge.code + ' ' + selectedKnowledge.description }}</span>
            </button>
            <div class="btn_icon" (click)="knowledgeDropdown = !knowledgeDropdown; $event.stopPropagation();">
                <img class="icon" *ngIf="!selectedKnowledge && !knowledgeDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
                <img class="icon" *ngIf="!selectedKnowledge && knowledgeDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
                <img class="icon" *ngIf="selectedKnowledge" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="removeSelectedKnowledge()"/>
            </div>
            <shared-dropdown [data]="knowledges" [config]="knowledgeConfig" [open]="knowledgeDropdown" [itemSelected]="selectedKnowledge" (clickOnItem)="knowledgeSelect($event);" (searchItems)="searchKnowledges($event)">
            </shared-dropdown>
        </div>                    
    </div>
  </div>
  <div class="row mt-3">
      <div class="col-12">
          <div class="loader animated fadeIn" *ngIf="loadingModal">
            <img src="./assets/icons/spinner-solid.svg" />
          </div>
          <!-- acordion start -->
          <div class="accordion curriculums_list" id="accordionSearch">
              <div class="card card-accordion"  *ngFor="let item of searchCurriculums">
                  <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
                      <div class="index_counter add_curriculum pointer" (click)="multiSelectCurriculum(item, true)">+</div>
                      <div class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSearch', item.id)">
                          <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                          {{ item.basicKnowledge.description }}
                      </div>
                  </div>                            
                  <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSearch">
                      <div class="card-body">
                          <div><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                          <div><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- accordion end -->        
          <div *ngIf="!loadingModal && searchCurriculums && searchCurriculums.length <= 0" class="text-center" style="padding: 0px 10px 30px 10px;">{{ 'MESSAGES.NON_RESULTS' | translate }}</div>
      </div>
  </div>
  <div *ngIf="selectedCurriculums.length > 0" class="row">
      <div class="col-12">          
          <div class="loader animated fadeIn" *ngIf="loadingModal">
            <img src="./assets/icons/spinner-solid.svg" />
          </div>
          <h4>{{ 'QUESTIONS.KNOWLEDGES_ACTIVITY' | translate }}:</h4>
          <!-- acordion start -->
          <div class="accordion curriculums_list" id="accordionSelected">
              <div class="card card-accordion"  *ngFor="let item of selectedCurriculums">
                  <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
                      <div class="index_counter add_curriculum pointer" (click)="multiSelectCurriculum(item, false)">-</div>
                      <div class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
                          <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                          {{ item.basicKnowledge.description }}
                      </div>
                  </div>                            
                  <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
                      <div class="card-body">
                          <div><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                          <div><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- accordion end --> 
      </div>
  </div>
</ng-template>
<ng-template #curriculumModalFooter>
  <div class="row">
    <div class="col-md-12 text-right">
        <button class="btn_orange view_btn" [disabled]="selectedCurriculums == null || selectedCurriculums.length == 0" (click)="saveSelectedCurriculums(true)">{{ 'QUESTIONS.ADD_KNOWLEDGES_BUTTON' | translate }}</button>
    </div>
  </div>
</ng-template>
  
<shared-modal
*ngIf="curriculumModal == true"
(close)="saveSelectedCurriculums(false)"
[optClose]="false"
[scrollable]="true"
[title]="'QUESTIONS.ADD_KNOWLEDGES_BUTTON' | translate"
[contentTemp]="curriculumModalContent"
[contentFooter]="curriculumModalFooter">
</shared-modal>

<ng-template #questionPreviewContent>
  <div class="question_preview">
    <div class="question_parent" *ngIf="question.parent">
      <h3>1. {{ question.parent.title }}</h3>
      <br />
      <div [innerHtml]="question.parent.content | safeHtml"></div>
    </div>
    <div class="question_content">
      <h3>{{ question.parent ? '2. ' : '1. '}} {{ question.title }}</h3>
      <br />
      <div [innerHtml]="question.content | safeHtml"></div>
    </div>
    <div class="answer_content" *ngIf="question.answer">
      <h3>{{ 'QUESTIONS.ANSWER' | translate }}: </h3>
      <div [innerHtml]="question.answer | safeHtml"></div>
    </div>
    <div class="question_criterias">
      <br />
      <h3> {{ 'EXAMS.ITEM.ASSOCIATE_KNOWLEDGES' | translate }}:</h3>
      <div *ngIf="addedCurriculums && addedCurriculums.length > 0" class="accordion curriculums_list" id="accordionSelected">
        <div class="card card-accordion"  *ngFor="let item of addedCurriculums">
            <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
                <div class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
                    <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                    {{ item.basicKnowledge.description }}
                </div>
            </div>                            
            <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
                <div class="card-body">
                    <div><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                    <div><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
                </div>
            </div>
        </div>
      </div>
      <div *ngIf="!addedCurriculums || addedCurriculums.length == 0">
        {{ 'OPTIONS.NON_RESULTS' | translate }}        
      </div>
    </div>
  </div>
</ng-template>

<shared-modal
*ngIf="previewShown == true"
(close)="previewShown = false;"
[optClose]="false"
[scrollable]="true"
[title]="'QUESTIONS.PREVIEW_OF_QUESTION' | translate"
[contentTemp]="questionPreviewContent">
</shared-modal>
