// Component Dependencies
import { Component, AfterViewInit, AfterViewChecked } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../data/services/config.service';
import { NotificationService } from '../data/services/notification.service';
import { Router } from '@angular/router';

import 'jquery';

// Models
import { User } from '../data/models/user.model';
import { Question } from '../data/models/question.model';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements AfterViewInit, AfterViewChecked {

  // User Data
  user: User;
  rol: string;
  imgSrc: any = [{color: 'color'}, {color: 'color'}, {color: 'color'}, {color: 'color'},
  {color: 'color'}, {color: 'color'}, {color: 'color'}, {color: 'color'}, {color: 'color'}];

  // Modal Preview
  questionPreview: Question = null;
  previewShown = false;
  notShow: boolean = false;

  // Constructor
  constructor (
    public translate: TranslateService,
    private notifService: NotificationService,
    public config: ConfigService,
    private router: Router,
    private app: AppComponent
  ) {
    this.user = this.config.user;
    this.rol = this.config.rol;
  }

  ngAfterViewInit() {
    if (localStorage.getItem('previusPage').includes('question') && localStorage.getItem('nextStep') === 'true') {
      this.config.enjoyhintSteps = [
        this.config.completeStep('click .step-home4', 'Si en el menú inicial pulsas en «Pruebas de evaluación», podrás generar pruebas de evaluación para descargarlas e imprimirlas cuando desees. Puedes utilizar tanto las Actividades Santillana como tus propias actividades personalizadas en el bloque de «Mis actividades».', false, false, '', 600)
      ];
      this.config.newEnjoy();
    } else if (localStorage.getItem('previusPage').includes('exam') && localStorage.getItem('nextStep') === 'true') {
      localStorage.setItem('nextStep', 'end');
      this.config.enjoyhintSteps = [
        this.config.completeStep('next .hidden-wizard', 'Fin del Wizard. <br/><br/> Gracias por verlo. <br/>', true, true, '', 600, {}, "Fin")
      ];
      this.config.newEnjoy();
    }
  }

  ngAfterViewChecked() {
    if (document.querySelector('#show')) {
      document.querySelector('#show').addEventListener('click', this.changeShowInput);
    }
  }

  newEnjoy() {
    //event, text, next, hideArrow, shape, timeout, scroll, nextButton
    this.config.enjoyhintSteps = [
      this.config.completeStep('next .hidden-wizard', 'Bienvenid@ a GES. <br><br> Este tutorial muestra, paso a paso, el funcionamiento de las diferentes secciones de la plataforma GES.', true, true, '', 0),
      this.config.completeStep('next .hidden-wizard', 'Ten en cuenta que los datos que vayas cumplimentando durante los diferentes pasos de este tutorial no se almacenarán, son solo de prueba, y que serán eliminados a la finalización del mismo.', true, true, '', 0),
      this.config.completeStep('next .enjoyhint_close_btn', 'Si lo deseas, puedes saltar este tutorial aquí.', true, false, 'circle', 0),
      this.config.completeStep('next .ball_wizard', 'Podrás volver a él cuando consideres aquí.', true, false, 'circle', 0),
      this.config.completeStep('next .navbar', 'En la parte superior de la plataforma tendrás acceso a un menú que te permite seleccionar la materia con la que quieres trabajar, eligiendo entre los cursos y las materias que tengas asociados a tu cuenta de e-vocación. Una vez que se seleccionen la materia y el curso, la herramienta te mostrará la información relacionada. <br/> <br/> Por ejemplo, si seleccionas Física y Química para 3.º de ESO, todas las acciones que realices quedarán asociadas a dicha selección.', true, false, '', 0),
      this.config.completeStep('click .subjectDrop', 'En este desplegable podrás seleccionar la materia.', false, false, '', 0),
      this.config.completeStep('click .step-subjects', 'Selecciona una materia.', false, false, '', 0),
      this.config.completeStep('click .groupDrop', 'En este desplegable podrás seleccionar el curso.', false, false, '', 0),
      this.config.completeStep('click .step-groups', 'Selecciona el curso.', false, false, '', 500),
      this.config.completeStep('click .step-home3', 'En el menú inicial elige la opción «Actividades», podrás acceder al listado de actividades de la editorial que tendrás a tu disposición para generar las pruebas de evaluación; estas son las Actividades Santillana.', false, false, '', 0)
    ];
    this.config.newEnjoy();
  }

  goRoute(name) {
    if (this.config.selectedGroup && this.config.selectedGroup.id) {
      this.router.navigate([name]);
    } else {
      this.notifService.setNotification('Para acceder a esta sección deberá de tener un grupo seleccionado', 'warning', 6000);
    }
  }

  downloadUserManual() {
    window.open('https://prod.santillana.es/eval/manuales/20/manual_profesor_ges.pdf', '_blank');
  }

  changeShowInput(event) {
    if (event.target.id === "show") {
      return;
    }
    const result:any = document.getElementById("showInput");
    this.notShow = result.checked;
    $("#hide").trigger("click");
  }

  saveHideWizard() {
    this.config.saveHideWizard({hideWizard: this.notShow});
  }
}
