<div class="main-content">
  <shared-breadcrumb *ngIf="!loading" [config]="routes"></shared-breadcrumb>
  <!-- Title - Header -->
  <div class="title-container">
    <h2>{{ 'EXAMS.TITLE' | translate }}</h2>
  </div>
  <div class="filters-container">
    <div class="row">
      <div class="col-md-4">
        <button class="step-exam2 btn_pink btn_new" [routerLink]="['/exam', 'generator']">{{ 'EXAMS.CREATE_NEW' | translate }}</button>
      </div>
      <div class="col-md-4 ml-auto">
        <div class="input_container search_box">
          <input type="text" [(ngModel)]="searchExam" (keyup.enter)="searchExams()" placeholder="{{ 'EXAMS.LOOK_FOR' | translate }}" />
          <div class="input_icon" (click)="searchExams()">
            <img class="icon" style="width: 24px;" [src]="'./assets/icons/search_icon.svg'" [alt]="'Search icon'" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="bold" />
  <!-- Table -->
  <div class="step-exam1 table-container">
    <div class="row" *ngIf="total > 0">
      <div class="col-12 text-left">
        <div class="quantity-block">
          <div class="quantity">
            <span class="count">{{ total }} {{ 'EXAMS.EXAMS' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="exams">
          <div class="loader animated fadeIn" *ngIf="loading">
            <img src="./assets/icons/spinner-solid.svg" />
          </div>
          <ul class="table_list" *ngIf="!loading && exams.length > 0">
            <li class="list_item" *ngFor="let exam of exams; let i = index;" [ngClass]="{'inter': i % 2}">
              <div class="row">
                <div class="col-8 align-self-center">
                  <p class="title">
                    {{ exam.title }}
                  </p>
                </div>
                <div class="col-4 buttons-container">
                  <button class="btn_pink small" (click)="preview(exam)">
                    ver
                  </button>
                  <button class="btn_orange small" (click)="update(exam)">
                    editar
                  </button>
                  <button class="btn_purple small" (click)="openModalDelete(exam)">
                    borrar
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <span *ngIf="exams && exams.length <= 0" class="text-center" style="padding: 10px;width: 100%; display:block; margin: 30px;">{{ 'OPTIONS.NON_RESULTS' | translate }}</span>
        </div>
      </div>
    </div>
    <hr class="mb-0" *ngIf="total > 7" />
    <div class="row" *ngIf="total > 7">
      <div class="col-12 text-right">
        <div class="pagination text-right">
          <pagination
          [totalItems]="total"
          [(ngModel)]="page"
          (pageChanged)="pageChanged($event)"
          [boundaryLinks]="true"
          [itemsPerPage]="limit"
          [rotate]="rotate"
          [maxSize]="5"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="confirm-overlay animated fadeIn fast" *ngIf="confirmRemove">
  <div class="confirm-box text-center">
    <br />
    <h5>{{ 'QUESTIONS.DELETE_CONFIRM' | translate }}</h5>
    <br />
    <br />
    <div class="row">
      <div class="col-6">
        <button (click)="removeExam = null; confirmRemove = false" class="btn_grey">{{ 'QUESTIONS.CANCEL' | translate }}</button>
      </div>
      <div class="col-6">
        <button (click)="deleteExam(removeExam.id); confirmRemove = false"
          class="btn_pink">{{ 'QUESTIONS.CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>