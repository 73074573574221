// Service Dependencies
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// App Config data
import { AppSettings } from '../../app.config';

// Services
import { CommonsService } from './commons.service';

// Rxjs
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CurriculumService {

  apiUrl: string;

  constructor(private http: HttpClient, private common: CommonsService) {
    this.apiUrl = AppSettings.API_PROD_ENDPOINT;
  }

  getCurriculums(
    filter: any[] = [],
    limit: number = 999999,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?filters=${filterString}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/GES/curriculum` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getCompetences(
    filter: any[] = [],
    limit: number = 999999,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?filters=${filterString}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/GES/specific_competence` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }
  
  getCriterias(
    filter: any[] = [],
    limit: number = 999999,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?filters=${filterString}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/GES/evaluation_criteria` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }
  
  getKnowledges(
    filter: any[] = [],
    limit: number = 999999,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?filters=${filterString}&limit=${limit}&offset=${offset}`;
    const requestUrl = this.apiUrl + `mcs/GES/basic_knowledge` + paramsUrl;

    return this.http.get( requestUrl, {headers: headers} )
    .pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }
}