// Pipe dependencies
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchTerm'
})

export class SearchTermPipe implements PipeTransform {

  // SEARCH TERMS TREATMENTS
  transform(items: Array<any>, field: string, value: any): any[] {

    if (!items) { return []; }
    return items.filter(item => item[field].toLowerCase().replace(/^\s+|\s+$/g, '').normalize('NFKD').
      indexOf(value.toLowerCase().normalize('NFKD').replace(/^\s+|\s+$/g, '')) !== -1 );
  }

}
