// Component Dependencies
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'shared-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})

export class BreadcrumbComponent implements OnInit {

  // Data Inputs
  @Input() config: any = {};

  // Metadata Selected Data
  routes: any = [];

  // Constructor
  constructor() {}

  // OnInit
  ngOnInit() {
    this.routes = this.config;
  }
}
