import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ExamComponent } from './exam/exam.component';
import { ExamItemComponent } from './exam/exam-item/exam-item.component';
import { ExamGeneratorComponent } from './exam/exam-generator/exam-generator.component';
import { ExamPreviewComponent } from './exam/exam-preview/exam-preview.component';
import { QuestionComponent } from './question/question.component';
import { QuestionItemComponent } from './question/question-item/question-item.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { RadiobuttonComponent } from './shared/components/radiobutton/radiobutton.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { NotificationComponent } from './shared/components/notification/notification.component';

// Directives
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { AddClassHeightDirective } from './shared/directives/add-class-height.directive';

// Pipes
import { SearchTermPipe } from './shared/pipes/search-term.pipe';
import { SafeHtmlPipe } from './shared/pipes/safeHtml.pipe';

// Services
import { QuestionService } from './data/services/question.service';
import { UnitService } from './data/services/unit.service';
import { GroupService } from './data/services/group.service';
import { GradeService } from './data/services/grade.service';
import { ResourceService } from './data/services/resource.service';
import { SubjectService } from './data/services/subject.service';
import { AuthService } from './data/services/auth.service';
import { AuthGuard } from './data/services/auth-guard.service';
import { RoleGuard } from './data/services/role-guard.service';
import { TokenInterceptor } from './data/interceptor/token.interceptor';
import { NotificationService } from './data/services/notification.service';
import { ConfigService } from './data/services/config.service';
import { CommonsService } from './data/services/commons.service';
import { ExamService } from './data/services/exam.service';
import { CurriculumService } from './data/services/curriculum.service';

// Import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale, caLocale, glLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);
defineLocale('cat', caLocale);
defineLocale('eus', esLocale);
defineLocale('gal', glLocale);
defineLocale('val', caLocale);

// Required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '-0.1.json');
}

// Token getter
import { JwtModule } from '@auth0/angular-jwt';
export function tokenGetter() {
  return localStorage.getItem('token');
}

import "jquery";
import "../enjoyHint/jquery.enjoyhint";
import "../enjoyHint";
import 'hammerjs';

// Import the tinymce options
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

// Import synchronous mathtype-tinymce5 package
import '@wiris/mathtype-tinymce6';

// Import drag and drop module
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    ExamComponent,
    ExamItemComponent,
    ExamGeneratorComponent,
    ExamPreviewComponent,
    QuestionComponent,
    QuestionItemComponent,
    DropdownComponent,
    RadiobuttonComponent,
    BreadcrumbComponent,
    ModalComponent,
    NotificationComponent,
    LoginComponent,
    ClickOutsideDirective,
    AddClassHeightDirective,
    SearchTermPipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EditorModule,
    DragDropModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    QuestionService,
    UnitService,
    GroupService,
    GradeService,
    ResourceService,
    SubjectService,
    AuthService,
    AuthGuard,
    RoleGuard,
    ConfigService,
    CommonsService,
    ExamService,
    NotificationService,
    CurriculumService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'node_modules/tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }