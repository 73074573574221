// Service Dependencies
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


// Services
import { CommonsService } from './commons.service';

// App Config data
import { AppSettings } from '../../app.config';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UnitService {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private common: CommonsService
  ) {this.apiUrl = AppSettings.API_PROD_ENDPOINT; }


  getUnits(
    query: any[] = [],
    filter: any[] = [], order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0,
    opt?: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryString(query);
    const filterString = this.common.queryString(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    let requesttUrl = '';
    if (opt === 'own') {
      requesttUrl = this.apiUrl + `mcs/ownUnit` + paramsUrl;
    } else {
      requesttUrl = this.apiUrl + `mcs/unit` + paramsUrl;
    }
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getOwnUnits(
    query: any[] = [],
    filter: any[] = [], order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryString(query);
    const filterString = this.common.queryString(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/ownUnit` + paramsUrl;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getUnit(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `/${id}`;
    const requesttUrl = this.apiUrl + `mcs/unit` + paramsUrl;

    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getBlocks(
    query: any[] = [],
    filter: any[] = [], order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryString(query);
    const filterString = this.common.queryString(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/block` + paramsUrl;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getSubblocks(
    query: any[] = [],
    filter: any[] = [], order: string = 'id',
    dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryString(query);
    const filterString = this.common.queryString(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/subBlock` + paramsUrl;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getObjectives(idUnit: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit/` + idUnit + `/objective`;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getCalificationsTable(idUnit: number, idClassGroup: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit/` + idUnit + `/mark/table?classGroup=` + idClassGroup;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getUnitMark(
    query: any[] = [],
    filter: any[] = [], order: string = 'id', dir: string = 'asc',
    limit: number = 10,
    offset: number = 0
  ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const queryString = this.common.queryStringify(query);
    const filterString = this.common.queryStringify(filter);
    const paramsUrl = `?query=${queryString}&filters=${filterString}&order={"${order}":"${dir}"}&limit=${limit}&offset=${offset}`;
    const requesttUrl = this.apiUrl + `mcs/reportMark` + paramsUrl;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getCalificationsContent(idUnit: number, idStudent: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit/` + idUnit + `/mark?student=` + idStudent;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getUnitsCompetences(idUnit: number, idStudent: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit/` + idUnit + `/competence?student=` + idStudent;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateUnitMarkByCriteria(item: any, idUnit: number, idCriteria: number ): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit/` + idUnit + '/criteria/' + idCriteria ;

    return this.http.put( requesttUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  updateOwnUnit(item: any, idUnit: number ): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit/` + idUnit ;

    return this.http.put( requesttUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  addOwnUnit( item: any): Observable<any> {
    const body = JSON.stringify(item);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requesttUrl = this.apiUrl + `mcs/unit`;

    return this.http.post( requesttUrl, body, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }

  getHasSubblocks(subjectMetadata, book ): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const paramsUrl = `?subjectMetadata=${subjectMetadata}&book=${book}`;
    const requesttUrl = this.apiUrl + `mcs/book/hasSubblocks` + paramsUrl;
    return this.http.get( requesttUrl, {headers: headers} ).pipe(
      map(this.common.extractData),
      catchError(this.common.handleError)
    );
  }
  deleteUnit(id: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const requestUrl = this.apiUrl + `mcs/unit/${id}`;
    return this.http.delete(requestUrl, { headers: headers })
      .pipe(
        map(this.common.extractData),
        catchError(this.common.handleError)
      );
  }

}
