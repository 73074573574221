// Component Dependencies
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  // Data Inputs
  @Input() notification: string;
  @Input() notificationType: string;

  // Events Outputs
  @Output() notificationHide: EventEmitter<any> = new EventEmitter();

  // Data Variables
  private urlAssets: string = '../assets/icons/notifications/';

  // Constructor
  public constructor() {}

  // OnInit
  ngOnInit() {}

  // METHOD TO OBTAIN THE IMAGE OF THE ALERT
  getImageAlert() {
    if (this.notificationType === 'success') {
      return this.urlAssets + 'successAlert_icon.svg';
    } else if (this.notificationType === 'error') {
      return this.urlAssets + 'errorAlert_icon.svg';
    } else if (this.notificationType === 'warning') {
      return this.urlAssets + 'warningAlert_icon.svg';
    }
  }

  // METHOD TO HIDE THE NOTIFICATION
  setNotificationFalse() {
    this.notificationHide.emit();
  }
}
