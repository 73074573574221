<div class="main-content">
  <shared-breadcrumb *ngIf="!loading" [config]="routes"></shared-breadcrumb>
  <!-- Title - Header -->
  <div class="title-container">
    <h2>{{ 'QUESTIONS.TITLE' | translate }}</h2>
  </div>
  <!-- Filters -->
  <div class="filters-container">
    <div class="row">
      <div class="col-md-8 step-question2">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <div class="pattern_item" (click)="questionType = 'auto'; addFilter();">
              <shared-radiobutton
                [check]="questionType === 'auto'"
                [config]="setObjetiveColor(0)">
              </shared-radiobutton>
              <span>{{ 'QUESTIONS.AUTO_ACTIVITIES' | translate }}</span>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center">        
            <div class="pattern_item step-question3" (click)="questionType = 'self'; addFilter();">
              <shared-radiobutton
                [check]="questionType === 'self'"
                [config]="setObjetiveColor(0)">
              </shared-radiobutton>
              <span>{{ 'QUESTIONS.SELF_ACTIVITIES' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 ml-auto">
        <div class="input_container search_box">
          <input type="text" [(ngModel)]="searchQuestion" (keyup.enter)="searchQuestions()" placeholder="{{ 'QUESTIONS.LOOK_FOR_ACTIVITY' | translate }}" />
          <div class="input_icon" (click)="searchQuestions()">
            <img class="icon" style="width: 24px;" [src]="'./assets/icons/search_icon.svg'" [alt]="'Search icon'" />
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <!-- UNIT -->
      <div class="col-md-4">
        <div class="dropdown unitDrop" (clickOutside)="unitDropdown = false;">
          <button class="dropdown_btn ellipsis" (click)="unitDropdown = config.checkClickWizard(unitDropdown)" [disabled]="!subjectMetadata">
            <span *ngIf="!selectedUnit">{{ 'QUESTIONS.SELECT_UNIT' | translate }}</span>
            <span *ngIf="selectedUnit && !selectedUnit.title">{{ 'QUESTIONS.UNIT' | translate }}: {{ selectedUnit.number }}</span>
            <span *ngIf="selectedUnit && selectedUnit.title">{{ ('QUESTIONS.CONTENT' | translate) + " " + selectedUnit.title }}</span>
          </button>
          <div class="btn_icon" (click)="unitDropdown = !unitDropdown; $event.stopPropagation();" [ngClass]="{'disabled': !subjectMetadata }">
            <img class="icon" *ngIf="!selectedUnit && !unitDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
            <img class="icon" *ngIf="!selectedUnit && unitDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
            <img class="icon" *ngIf="selectedUnit" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedUnit = null; unitDropdown = false; addFilter(); $event.stopPropagation();"/>
          </div>
          <shared-dropdown
          [data]="units"
          [config]="unitConfig"
          [open]="unitDropdown"
          [itemSelected]="selectedUnit"
          (clickOnItem)="unitSelect($event);"
          (addFilter)="addFilter()">
          </shared-dropdown>
        </div>
      </div>
      <!-- COMPETENCIA -->
      <div class="col-md-4">
        <div class="dropdown" (clickOutside)="competenceDropdown = false;">
          <button class="dropdown_btn ellipsis" (click)="competenceDropdown = config.checkClickWizard(competenceDropdown); getCompetences()">
            <span *ngIf="!selectedCompetence">{{ 'QUESTIONS.SELECT_COMPETENCE' | translate }}</span>
            <span *ngIf="selectedCompetence">{{ selectedCompetence.code + selectedCompetence.title }}</span>
          </button>
          <div class="btn_icon" (click)="competenceDropdown = !competenceDropdown; $event.stopPropagation(); getCompetences()">
            <img class="icon" *ngIf="!selectedCompetence && !competenceDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
            <img class="icon" *ngIf="!selectedCompetence && competenceDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
            <img class="icon" *ngIf="selectedCompetence" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedCompetence = null; competenceDropdown = false; addFilter(); $event.stopPropagation();"/>
          </div>
          <shared-dropdown
          [data]="competences"
          [config]="competenceConfig"
          [open]="competenceDropdown"
          (click)="$event.stopPropagation();"
          [itemSelected]="selectedCompetence"
          (clickOnItem)="competenceSelect($event);"
          (addFilter)="addFilter()"
          (searchItems)="searchCompetences($event)">
          </shared-dropdown>
        </div>
      </div>
      <!-- BUTTON NEW -->
      <div class="col-md-4" *ngIf="!loading && questionType === 'self'">
        <button class="step-group4 btn_pink btn_new" [routerLink]="['/question', 'create']">{{ 'QUESTIONS.CREATE_NEW' | translate }}</button>
      </div>
    </div>
    <div class="row">
      <!-- CRITERIA -->
      <div class="col-md-4">
        <div class="dropdown" (clickOutside)="criteriaDropdown = false;">
          <button class="dropdown_btn ellipsis" (click)="criteriaDropdown = config.checkClickWizard(criteriaDropdown); getCriterias()">
            <span *ngIf="!selectedCriteria">{{ 'QUESTIONS.SELECT_CRITERIA' | translate }}</span>
            <span *ngIf="selectedCriteria">{{ selectedCriteria.code + selectedCriteria.title }}</span>
          </button>
          <div class="btn_icon" (click)="criteriaDropdown = !criteriaDropdown; $event.stopPropagation(); getCriterias()">
            <img class="icon" *ngIf="!selectedCriteria && !criteriaDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
            <img class="icon" *ngIf="!selectedCriteria && criteriaDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
            <img class="icon" *ngIf="selectedCriteria" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedCriteria = null; criteriaDropdown = false; addFilter(); $event.stopPropagation();"/>
          </div>
          <shared-dropdown
          [data]="criterias"
          [config]="criteriaConfig"
          [open]="criteriaDropdown"
          (click)="$event.stopPropagation();"
          [itemSelected]="selectedCriteria"
          (clickOnItem)="criteriaSelect($event);"
          (addFilter)="addFilter()"
          (searchItems)="searchCriterias($event)">
          </shared-dropdown>
        </div>
      </div>
      <!-- KNOWLEDGE -->
      <div class="col-md-4">
        <div class="dropdown" (clickOutside)="knowledgeDropdown = false;">
          <button class="dropdown_btn ellipsis" (click)="knowledgeDropdown = config.checkClickWizard(knowledgeDropdown); getKnowledges()">
            <span *ngIf="!selectedKnowledge">{{ 'QUESTIONS.SELECT_KNOWLEDGE' | translate }}</span>
            <span *ngIf="selectedKnowledge">{{ selectedKnowledge.code + ' ' + selectedKnowledge.description }}</span>
          </button>
          <div class="btn_icon" (click)="knowledgeDropdown = !knowledgeDropdown; $event.stopPropagation(); getKnowledges()">
            <img class="icon" *ngIf="!selectedKnowledge && !knowledgeDropdown" style="width: 14px;" [src]="'./assets/icons/caret_down.png'" [alt]="'Caret down icon'" />
            <img class="icon" *ngIf="!selectedKnowledge && knowledgeDropdown" style="width: 14px;" [src]="'./assets/icons/caret_up.png'" [alt]="'Caret up icon'" />
            <img class="icon" *ngIf="selectedKnowledge" style="width: 14px;" [src]="'./assets/icons/remove_icon.png'" [alt]="'Quit icon'" (click)="selectedKnowledge = null; knowledgeDropdown = false; addFilter(); $event.stopPropagation();"/>
          </div>
          <shared-dropdown
          [data]="knowledges"
          [config]="knowledgeConfig"
          [open]="knowledgeDropdown"
          (click)="$event.stopPropagation();"
          [itemSelected]="selectedKnowledge"
          (clickOnItem)="knowledgeSelect($event);"
          (addFilter)="addFilter()"
          (searchItems)="searchKnowledges($event)">
          </shared-dropdown>
        </div>
      </div>
      <!-- EXPORT -->
      <div class="col-md-4" *ngIf="!loading && questions.length > 0 && questionType === 'self'" [ngClass]="{'align-self-end': questionType !== 'self'}">
        <button class="btn-class btn_export btn_pink" *ngIf="questionType === 'self'" (click)="getPDFFile()" [disabled]="(!selectedBook || !selectedBook.id) || (!selectedRegion || selectedRegion.length <= 0)">
          <span style="color: #FFF;">{{ 'QUESTIONS.EXPORT_OWN' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <hr class="bold" />
  <!-- Table -->
  <div class="table-container">
    <div class="row" *ngIf="total > 0">
      <div class="col-12 text-left">
        <div class="quantity-block">
          <div class="quantity">
            <span class="count">{{ total }} {{ 'QUESTIONS.ACTIVITIES' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="step-question1 questions">
          <div class="loader animated fadeIn" *ngIf="loading">
            <img src="./assets/icons/spinner-solid.svg" />
          </div>
          <ul class="table_list" *ngIf="!loading && questions.length > 0">
            <li *ngFor="let question of questions; let i = index;" class="list_item" [ngClass]="{'inter': i % 2}">
              <div class="row">
                <div class="col-8">
                  <p class="title">
                    {{ question.title }}
                  </p>
                </div>
                <div class="col-4 buttons-container">
                  <button class="btn_pink small" tooltip="{{ 'TOOLTIPS.PREVIEW_VIEW' | translate }}" (click)="previewQuestion(question);">
                    ver
                  </button>
                  <button class="btn_purple small" tooltip="{{ 'TOOLTIPS.DUPLICATE' | translate }}" *ngIf="config.user.id !== question.userId" (click)="cloneQuestion(question);">
                    duplicar
                  </button>
                  <button class="btn_orange small" tooltip="{{ 'TOOLTIPS.EDIT' | translate }}" *ngIf="config.user.id === question.userId" (click)="updateQuestion(question)">
                    editar
                  </button>
                  <button class="btn_purple small" tooltip="{{ 'TOOLTIPS.DELETE' | translate }}" *ngIf="config.user.id === question.userId" (click)="openModalDelete(question)">
                    borrar
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <span *ngIf="!loading && questions && questions.length <= 0" class="text-center" style="padding: 10px;width: 100%; display: block; margin: 30px;">{{ 'OPTIONS.NON_RESULTS' | translate }}</span>
        </div>
      </div>
    </div>
    <hr class="mb-0" *ngIf="total > 7" />
    <div class="row" *ngIf="total > 7" >
      <div class="col-md-12 text-right">
        <div class="pagination text-right">
          <pagination
          [totalItems]="total"
          [(ngModel)]="page"
          (pageChanged)="pageChanged($event)"
          [boundaryLinks]="true"
          [itemsPerPage]="limit"
          [rotate]="rotate"
          [maxSize]="5"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="confirm-overlay animated fadeIn fast" *ngIf="confirmRemove">
  <div class="confirm-box text-center">
    <br />
    <h5>{{ 'QUESTIONS.DELETE_CONFIRM' | translate }}</h5>
    <br />
    <br />
    <div class="row">
      <div class="col-6">
        <button (click)="confirmRemove = false" class="btn_grey">{{ 'QUESTIONS.CANCEL' | translate }}</button>
      </div>
      <div class="col-6">
        <button (click)="deleteQuestion(questionSelected.id); questionSelected = null; confirmRemove = false"
          class="btn_pink">{{ 'QUESTIONS.CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #questionPreviewContent>
  <div class="question_preview">
    <div class="question_parent" *ngIf="questionPreview.parent">
      <h3>1. {{ questionPreview.parent.title }}</h3>
      <div [innerHtml]="questionPreview.parent.content | safeHtml"></div>
    </div>
    <div class="question_content">
      <h3>{{ questionPreview.parent ? '2. ' : '1. '}} {{ questionPreview.title }}</h3>
      <div [innerHtml]="questionPreview.content | safeHtml"></div>
    </div>
    <div class="answer_content" *ngIf="questionPreview.answer">
      <h3>{{ 'QUESTIONS.ANSWER' | translate }}: </h3>
      <div [innerHtml]="questionPreview.answer | safeHtml"></div>
    </div>
    <div class="question_criterias">
      <br />
      <h3> {{ 'EXAMS.ITEM.ASSOCIATE_KNOWLEDGES' | translate }}:</h3>
      <div *ngIf="questionPreview.curriculums && questionPreview.curriculums.length > 0" class="accordion curriculums_list" id="accordionSelected">
        <div class="card card-accordion" *ngFor="let item of questionPreview.curriculums">
            <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
                <div *ngIf="item.basicKnowledge && item.basicKnowledge.code" class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
                    <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                    {{ item.basicKnowledge.description }}
                </div>
            </div>                            
            <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
                <div class="card-body">
                    <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.specificCompetence"><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                    <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.code"><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
                </div>
            </div>
        </div>
      </div>
      <div *ngIf="questionPreview.curriculums && questionPreview.curriculums.length == 0">
        {{ 'OPTIONS.NON_RESULTS' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<shared-modal
*ngIf="questionPreview && previewShown == true"
(close)="previewShown = false; questionPreview = null;"
[optClose]="false"
[scrollable]="true"
[title]="'QUESTIONS.PREVIEW_OF_QUESTION' | translate"
[contentTemp]="questionPreviewContent">
</shared-modal>
