// Directive Dependencies
import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {

    // Events Outputs
    @Output() public clickOutside = new EventEmitter();

    // Constructor
    constructor( private _elementRef: ElementRef ) {}

    // Listen to document click
    @HostListener('document:click', ['$event.target'])

    // OnClick function to know if an element is clicked in or outside
    public onClick(targetElement) {
      const isClickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!isClickedInside && document.querySelector('body .enjoyhint') === null) {
          this.clickOutside.emit(null);
      }
    }

}
